// Import: Middleware Action & Custom Types
import {
    CLEAN_VERIFY_PIN_GIFT,
    CLEAN_VERIFY_PIN_GIFT_ERROR,
} from "_models/redux/types";

// Función de acción
export const cleanVerifyPinGift = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_VERIFY_PIN_GIFT,
            payload: undefined,
        });
    } catch {
        dispatch({
            type: CLEAN_VERIFY_PIN_GIFT_ERROR,
            payload: undefined,
        });
    }
};
