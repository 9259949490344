import React from "react";
import { Container, Grid2 as Grid } from "@mui/material";

const HistoryHeader = () => {
    return (
        <div>
            <Container className={"my-legals-container"}>
                <Grid container size={12} className={"my-legals-header"}>
                    <Grid size={12} className={"my-legals-title"}>
                        <h3>
                            Mis <span>datos</span> fiscales
                        </h3>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default HistoryHeader;
