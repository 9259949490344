import moment from "moment";
import "moment/locale/es";

export default function activityMethod(params) {
    const log = params.log || "";
    const type = params.type || "";
    const date = params.date || "";

    const dateArray = date.replace(",", "").split(" ");
    const month = dateArray[2] || "";
    const monthNumber = moment(month, "MMMM").format("MM");
    const dateConverted = moment
        .utc(
            dateArray[3] +
                "-" +
                monthNumber +
                "-" +
                dateArray[1] +
                " " +
                dateArray[4]
        )
        .local()
        .format();

    window.$api.activity = {
        server: dateConverted,
        local: Date.now(),
        type: type,
        log: log,
    };
}
