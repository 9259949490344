import { TICKETS, TICKETS_ERROR } from "_models/redux/types";

const initialState = {
    tickets: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TICKETS:
            return {
                ...state,
                tickets: action.payload,
                loading: true,
            };

        case TICKETS_ERROR:
            break;
        default:
            return state;
    }
}
