import { useContext, useState, useEffect } from "react";

import { Box, Grid2 as Grid, Typography } from "@mui/material";

import HelperFormInputComponent from "utils/helpers/forms/inputs/helper-form-input-component";

import { LeadContext } from "../data/contexts/leadContext";

import LoadingButton from "@mui/lab/LoadingButton";

//Components:
import CardNoPlansComponent from "utils/components/cards/card-no-plans/CardNoPlansComponent";
import CardPrepaidComponent from "utils/components/cards/card-prepaid/CardPrepaidComponent";

const LeadOffersComponent = () => {
    const leadContext = useContext(LeadContext);

    const {
        leadData,
        formValues,

        loading,
        formSubmmited,
        setFormSubmmited,

        packageOnChangeHandler,
        packageOnFocusHandler,
    } = leadContext || {};
    const {
        inputs,

        offersOptionSelected,
        offersOptions,
    } = leadData || {};
    const { register, control } = formValues || {};
    const { packageInputs } = inputs || {};

    const [cardSelected, setCardSelected] = useState({});
    const [dataCardNoPlans, setDataCardNoPlans] = useState({});
    const [dataCardPrepaid, setDataCardPrepaid] = useState({});

    useEffect(() => {
        offersOptions.forEach((item) => {
            if (item.id === offersOptionSelected) {
                setCardSelected(item);

                if (item.is_monthly === true) {
                    setDataCardNoPlans(item);
                } else {
                    setDataCardPrepaid(item);
                }
            }
        });
        return () => {};
    }, [offersOptionSelected, offersOptions]);

    return (
        <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
                backgroundColor: "white",
                color: "#2f2b31",
                borderRadius: 10,
            }}
        >
            <Box
                sx={{
                    py: { xs: 2, md: 4 },
                    px: { xs: 3, md: 6 },
                }}
            >
                <Typography sx={{ fontSize: 25, mb: 2 }}>
                    <span className="poppins-semibold-font">Paso 3 de 3</span>
                </Typography>

                <Typography>
                    <span className="poppins-regular-font">
                        Elige tu recarga ideal
                    </span>
                </Typography>

                {offersOptionSelected || offersOptionSelected === null
                    ? packageInputs?.map((item, index) => {
                          return (
                              <HelperFormInputComponent
                                  register={register}
                                  control={control}
                                  key={index}
                                  id={item.id}
                                  validations={item.validations}
                                  placeholder={item.placeholder}
                                  // cls={item.cls}
                                  onChangeHandler={packageOnChangeHandler}
                                  onFocusHandler={packageOnFocusHandler}
                                  type={item.type}
                                  error={item.error}
                                  label={item.label}
                                  options={offersOptions}
                                  containerCls={item.containerCls}
                                  value={offersOptionSelected}
                                  submmited={formSubmmited}
                                  input={"package -> " + item.id}
                                  valueProgrammatically={
                                      item.valueProgrammatically
                                  }
                              />
                          );
                      })
                    : null}

                <Grid
                    container
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    {Object.keys(cardSelected).length !== 0 ? (
                        cardSelected.is_monthly === true ? (
                            <CardNoPlansComponent
                                element={dataCardNoPlans}
                                bestSelling={
                                    dataCardNoPlans.name === "Dale 3M Clásico"
                                        ? true
                                        : false
                                }
                                styleCard={"Classic"} //Plus || Lite || Classic
                                oneElement={true}
                            />
                        ) : (
                            <CardPrepaidComponent
                                element={dataCardPrepaid}
                                bestSelling={
                                    dataCardPrepaid.name === "Dale 239 Plus"
                                        ? true
                                        : false
                                }
                                oneElement={true}
                            />
                        )
                    ) : null}
                </Grid>

                <Grid
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 3,
                    }}
                >
                    <LoadingButton
                        loading={loading}
                        loadingPosition="center"
                        variant="outlined"
                        className="compatibility-btn"
                        onClick={() => setFormSubmmited(true)}
                        type="submit"
                    >
                        <Typography fontSize={16}>
                            <span className="poppins-semibold-font">
                                Continuar
                            </span>
                        </Typography>
                    </LoadingButton>
                </Grid>
            </Box>
        </Grid>
    );
};

export default LeadOffersComponent;
