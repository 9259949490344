import React from "react";

// Material Ui:
import { Box, Grid2 as Grid } from "@mui/material";

//Images Titles:
import Slim from "assets/images/products/slim.png";

//Images Desktop & Mobile:
import imgNoPlansPromoSlim from "assets/images/utils/components/section-show-title/banner-desktop-slim.png";
import imgNoPlansPromoSlimMobile from "assets/images/utils/components/section-show-title/banner-movil-plus.png";

// Swiper:
import { SwiperSlide } from "swiper/react";

//Components:
import AProductCardsItem from "modules/purchase/pages/widgets/productCardsItem";
import BProductCardsItem from "modules/compras/pages/widgets/productCardsItem";
import SectionCarouselProductComponent from "utils/components/section-carousel-product/SectionCarouselProductComponent";
import ProductCardsItemPrepaid from "modules/portal/pages/products/widgets/ProductCardsItemPrepaid";

const ContentSlimNoPlans = ({ slimProducts, flow }) => {
    console.log("products:", {
        slimProducts,
        flow,
    });
    console.log("flow:", flow);
    const ProductCard =
        flow === "prepaid"
            ? ProductCardsItemPrepaid
            : flow === "purchase"
              ? AProductCardsItem
              : BProductCardsItem;

    const swiperStyle = {
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const boxImgPromoResponsive = {
        display: { xs: "flex", md: "none" },
        my: 1,
    };

    const boxImgPromoInterestFree = {
        pt: { xs: 2, md: 2 },
        display: { xs: "none", md: "flex" },
    };

    return (
        <>
            {/*** NO Planes - Slim ***/}
            {slimProducts?.length > 0 && (
                <>
                    <Box sx={{ mt: 10, textAlign: "center" }}>
                        <img
                            src={Slim}
                            alt="Titulo de los paquetes Plus"
                            className="img-title-no-plans"
                            style={{ marginTop: "50px" }}
                        />
                        <Box>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Box
                                    component="img"
                                    src={imgNoPlansPromoSlim}
                                    alt="Banner de promoción de planes Lite"
                                    sx={{
                                        maxWidth: "45%",
                                        height: "auto",
                                        display: "block",
                                        margin: "0 auto",
                                        "@media (max-width: 900px)": {
                                            height: "180px",
                                        },
                                        ...boxImgPromoInterestFree,
                                    }}
                                />
                                <Box sx={boxImgPromoResponsive}>
                                    <Box
                                        component="img"
                                        src={imgNoPlansPromoSlimMobile}
                                        alt="Banner de promoción de planes Lite en móvil"
                                        sx={{
                                            width: "fit-content",
                                            height: "110px",
                                            "@media (max-width: 900px)": {
                                                width: "100%",
                                                height: "180px",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    <SectionCarouselProductComponent showButton={false}>
                        {slimProducts.map(
                            (
                                {
                                    mobile_data,
                                    mobile_data_roaming,
                                    mobile_data_throttling,
                                    gigabyte_divisor,
                                    ...rest
                                },
                                i
                            ) => {
                                const gigaBytes =
                                    (mobile_data +
                                        mobile_data_roaming +
                                        mobile_data_throttling) /
                                    gigabyte_divisor;

                                return (
                                    <SwiperSlide
                                        key={rest.name}
                                        style={swiperStyle}
                                    >
                                        <ProductCard
                                            key={i}
                                            index={i}
                                            colorClass={2}
                                            element={{
                                                mobile_data,
                                                mobile_data_roaming,
                                                mobile_data_throttling,
                                                ...rest,
                                            }}
                                            gigaBytesParent={gigaBytes}
                                            recommendCls=""
                                        />
                                    </SwiperSlide>
                                );
                            }
                        )}
                    </SectionCarouselProductComponent>
                </>
            )}
        </>
    );
};
export default ContentSlimNoPlans;
