import {
    GETOFFERS,
    GETOFFERS_ERROR,
    SET_LOADING_UUID,
} from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const getOffersAction = (params) => async (dispatch) => {
    const { tokenView } = params || "";

    try {
        const response = await axiosAuthInstance.get(
            `/api/leads/v3/steps/get-offers`,
            {
                headers: {
                    Authorization: `Bearer ${tokenView}`,
                },
            }
        );

        dispatch({
            type: GETOFFERS,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: GETOFFERS_ERROR,
            payload: e || {},
        });
    }
};

export const setLoadingUUIDAction = (params) => async (dispatch) => {
    dispatch({
        type: SET_LOADING_UUID,
        payload: params,
    });
};
