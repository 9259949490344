import { Button, Grid2 as Grid } from "@mui/material";
import { useFormHook } from "../hooks/invoiceFormHook";
import HelperFormInputComponent from "utils/helpers/forms/inputs/helper-form-input-component";

import { useForm } from "react-hook-form";

const InvoiceFormComponent = () => {
    // const { formValues } = invoiceDataStep2 || {};
    // const { register, handleSubmit, errors } = formValues;

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const { inputs, onSubmit } = useFormHook({
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    });

    const cls = "qr-code-container";

    return (
        <Grid container size={12} className={cls}>
            <Grid size={12} className="qr-code">
                <Grid size={12} className="section invoice">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            size={12}
                            container
                            className="flex"
                            alignItems="center"
                        ></Grid>
                        <Grid size={12}>
                            {inputs?.map((item, index) => {
                                return (
                                    <HelperFormInputComponent
                                        register={register}
                                        key={index}
                                        id={item.id}
                                        validations={item.validations}
                                        placeholder={item.placeholder}
                                        // cls={item.cls}
                                        onChangeHandler={item.onChangeHandler}
                                        onFocusHandler={item.onFocusHandler}
                                        type={item.type}
                                        error={item.error}
                                        label={item.label}
                                        options={item.options}
                                        containerCls={item.containerCls}
                                        value={item.value}
                                        styleLabel={{ color: "white" }}
                                        styleInput={{ color: "black" }}
                                        typeComponent={"input"}
                                        errors={errors}
                                    />
                                );
                            })}
                        </Grid>
                        <Button
                            variant="contained"
                            type={"submit"}
                            className="invoice-btn"
                        >
                            {" "}
                            Generar factura{" "}
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InvoiceFormComponent;
