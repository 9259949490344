import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Grid2 as Grid } from "@mui/material";

import GiftActivationHeader from "../widgets/header";
import GiftLoader from "../widgets/loader";

import { getLandingGiftAction } from "_models/redux/get-landing-gift/action";
import { cleanGetLandingGiftAction } from "_models/redux/get-landing-gift/clean";

const GiftContent = (props) => {
    const navigate = useNavigate();

    const { getLandingGift, getLandingGiftAction } = props || {};

    const { loaded, payload } = getLandingGift || {};

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);

    const uuid = searchParams.get("uuid");
    const order = searchParams.get("order");

    const [activated, setActivated] = useState("none");

    useEffect(() => {
        if (uuid && order) {
            getLandingGiftAction({
                uuid,
                order,
            });
        }

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loaded) {
            setActivated(true);

            if (!payload?.order_number) {
                navigate("/pagina-no-encontrada");

                navigate(`/pagina-no-encontrada`, {
                    state: {
                        payload,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLandingGift]);

    return (
        <Grid container className="gift-activation-container">
            {payload?.order_number ? (
                <Grid size={12}>
                    {activated !== "none" ? (
                        <>
                            <GiftActivationHeader giftDetails={payload} />
                        </>
                    ) : (
                        <GiftLoader />
                    )}
                </Grid>
            ) : uuid && order ? (
                <GiftLoader />
            ) : (
                <Grid size={12} sx={{ textAlign: "center" }}>
                    <h1>No se encontró la página</h1>
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({ getLandingGift: state?.getLandingGift });

const mapDispatchToProps = {
    getLandingGiftAction,
    cleanGetLandingGiftAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftContent);
