import { useEffect } from "react";

import { Grid2 as Grid } from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const LeadNotFoundComponent = () => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <Grid container className="lead-not-found-container">
            <Grid size={12}>
                <h1 className="lead-not-found-legend">
                    <WarningAmberIcon /> No se encontró la página
                </h1>
            </Grid>
        </Grid>
    );
};

export default LeadNotFoundComponent;
