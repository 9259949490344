import {
    FormControl,
    Grid2 as Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";

const HelperFormInputComponent = (props) => {
    const {
        id,
        cls,
        type,
        value,
        label,
        register,
        placeholder,
        validations,
        onChangeHandler,
        onFocusHandler,
        options,
        containerCls,
        styleInput,
        errors,
        submmited,
        valueProgrammatically,
        typeComponent,
    } = props || {};

    const containerClasses = "input-container " + containerCls;

    const filtered = errors
        ? Object.entries(errors)?.filter((entry) => entry[0] === id)
        : {};

    const filteredError = filtered?.length > 0 ? filtered[0][1] : {};

    return (
        <Grid className={containerClasses}>
            {type === "select" && options ? (
                <Grid>
                    <FormControl
                        sx={{
                            width: "100%",
                            background: "#FFFFFF",
                            margin: "25px 0 20px",
                            border: "#ced4da",
                        }}
                    >
                        <InputLabel id={id + "-label"}>{label}</InputLabel>

                        {valueProgrammatically ? (
                            <Select
                                {...register(id, validations)}
                                labelId={id + "label"}
                                id={id}
                                value={value}
                                label={placeholder}
                                onChange={onChangeHandler}
                                onFocus={onFocusHandler}
                            >
                                {options?.map((item, index) => {
                                    const name = [
                                        "Dale 3M Clásico",
                                        "Dale 239 Plus",
                                    ].includes(item.name)
                                        ? item.name + " (más vendido)"
                                        : item.name;
                                    return (
                                        <MenuItem value={item.id} key={index}>
                                            {name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        ) : (
                            <Select
                                {...register(id, validations)}
                                labelId={id + "label"}
                                id={id}
                                label={placeholder}
                                onChange={onChangeHandler}
                                onFocus={onFocusHandler}
                            >
                                {options?.map((item, index) => {
                                    return (
                                        <MenuItem value={item.id} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    </FormControl>

                    <span>
                        {submmited &&
                            validations?.required &&
                            (value === "0" ||
                                value === 0 ||
                                value === null ||
                                value === false ||
                                value === undefined) && (
                                <p className="required-incidence">
                                    {""}
                                    {validations?.required}
                                </p>
                            )}
                    </span>
                </Grid>
            ) : type !== "select" ? (
                <Grid>
                    {typeComponent === "input" ? (
                        <input
                            {...register(id, validations)}
                            className={cls}
                            onFocus={onFocusHandler}
                            onChange={onChangeHandler}
                            placeholder={placeholder}
                            label={label}
                            value={value}
                            type={type}
                            style={styleInput}
                            onWheel={(e) => e.target.blur()}
                        />
                    ) : (
                        <TextField
                            {...register(id, validations)}
                            className={cls}
                            onFocus={onFocusHandler}
                            onChange={onChangeHandler}
                            placeholder={placeholder}
                            label={label}
                            value={value}
                            type={type}
                            fullWidth
                            style={styleInput}
                            onWheel={(e) => e.target.blur()}
                        />
                    )}

                    <span>
                        {filteredError?.message && (
                            <p className="required-incidence">
                                {" "}
                                {filteredError?.message}
                            </p>
                        )}
                    </span>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default HelperFormInputComponent;
