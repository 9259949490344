import axios from "axios";
import authAxios from "services/api/authentication";
import { setLoginDataHelper } from "_controllers/helpers/setLoginData";
import { getLoginDataHelper } from "_controllers/helpers/getLoginData";
import { closeLoggedSession } from "_controllers/helpers/closeLoggedSession";

let refreshPromise = null;

const axiosTokenInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const xApiKey = process.env.REACT_APP_X_API_KEY;

// Request interceptor for API calls
axiosTokenInstance.interceptors.request.use(
    async (config) => {
        const getLoginDataResponse = getLoginDataHelper();
        const userExpiresAt = getLoginDataResponse.expires_at || 0;
        const refreshToken = getLoginDataResponse.refresh_token || "";
        const currentTimestamp = new Date().getTime() / 1000;

        // Add 30 days in secconds (Force expired token)
        // currentTimestamp += 30 * 24 * 60 * 60;

        // Check if token is valid
        if (currentTimestamp > userExpiresAt && refreshToken !== "") {
            if (!refreshPromise) {
                refreshPromise = authAxios
                    .post(
                        "/api/clients/refresh-token",
                        {
                            refresh_token: refreshToken,
                        },
                        {
                            headers: { "x-api-key": xApiKey },
                        }
                    )
                    .then((response) => {
                        setLoginDataHelper(
                            response.data || {},
                            "Axios Instance"
                        );
                    })
                    .catch((error) => {
                        // TODO: Redirect to login
                        console.error(
                            "Ocurrió un error al intentar refrescar el token"
                        );
                        console.error(error);
                    })
                    .finally(() => {
                        refreshPromise = null;
                    });
            }
            await refreshPromise;
        }

        const userToken = localStorage.getItem("user_token") || "";

        if (userToken !== "") {
            config.headers["Authorization"] = `Bearer ${userToken}`;
            return config;
        } else {
            closeLoggedSession("NoToken");
        }
    },
    (error) => {
        Promise.reject(error);
    }
);

const closeLoggedSessionMethod = async (error = undefined) => {
    if (typeof error !== "undefined") {
        console.error(error);

        const { response } = error || {};
        const { data, status } = response || {};
        const { context } = data || {};

        if (status === 401 || context === "token_expired") {
            closeLoggedSession("TokenExpired");
        } else if (
            status === 409 &&
            context === "mobile_suscriptor_not_found_generic_error"
        ) {
            closeLoggedSession("Conflict", true);
        }
    }
};

// Response interceptor for API calls
axiosTokenInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        closeLoggedSessionMethod(error, "response");
        return Promise.reject(error);
    }
);

export default axiosTokenInstance;
