import { useEffect } from "react";

import moment from "moment";
import "moment/locale/es";

import HomeInactive from "../../inactive_offers/inactive";
import CardWidget from "../components/card";

import { Grid2 as Grid, CircularProgress } from "@mui/material";

export const useLoadHook = (props = {}) => {
    /*** --- *** --- *** --- ***/

    const { activeOffers, activeOffersAction, setCards } = props || {};

    /*** --- *** --- *** --- ***/

    //#region > useEffects
    // Default useEffects
    useEffect(() => {
        return () => {};
    }, []);

    useEffect(() => {
        const { activeOffersdObj, loading } = activeOffers || {};

        if (loading && typeof activeOffersdObj !== "undefined") {
            const { active_offers } = activeOffersdObj || {};

            if (typeof active_offers !== "undefined") {
                if (Array.isArray(active_offers)) {
                    if (active_offers.length > 0) {
                        active_offers.map((item, index) => {
                            item.leftDays = moment(
                                item.expireDate,
                                "YYYYMMDDhmmss"
                            ).fromNow();

                            item.leftDaysNumber = parseInt(
                                item.leftDays.split(" ")[1]
                            );

                            item.leftDaysPrefix = item.leftDays.indexOf("hace");

                            item.leftDaysLegend =
                                item.leftDaysPrefix < 0
                                    ? "Expira: " + item.leftDays
                                    : "Expiró: " + item.leftDays;

                            item.vigencia = moment(
                                item.expireDate,
                                "YYYYMMDDhmmss"
                            ).format("DD / MM / YYYY");

                            item.recargado = moment(
                                item.effectiveDate,
                                "YYYYMMDDhmmss"
                            ).format("DD / MM / YYYY");

                            if (typeof setCards === "function") {
                                if (index === 0) {
                                    setCards([
                                        <CardWidget
                                            key={"card-" + index}
                                            item={item}
                                        />,
                                    ]);
                                } else {
                                    setCards((current) => [
                                        ...current,
                                        <CardWidget
                                            key={"card-" + index}
                                            item={item}
                                        />,
                                    ]);
                                }
                            }
                        });
                    } else {
                        setInactiveCard();
                    }
                } else {
                    setInactiveCard();
                }
            } else {
                setInactiveCard();
            }
        }
        // else {
        //     setInactiveCard();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOffers]);
    //#endregion > useEffects

    /*** --- *** --- *** --- ***/

    const setInactiveCard = () => {
        setCards([<HomeInactive key={"card-0"} />]);
    };

    /*** --- *** --- *** --- ***/

    //#region > loadMethod
    /**
     * @description : Load Method
     * @param {*} params
     * @returns response
     */
    const loadMethod = () => {
        if (typeof setCards === "function") {
            // setCards([<HomeInactive key={"card-0"} />]);
            setCards([
                <Grid
                    className={"center_circular_progress"}
                    key="circular-progress"
                >
                    <CircularProgress />
                </Grid>,
            ]);
        }

        if (typeof activeOffersAction === "function") {
            activeOffersAction();
        }

        let response;
        return response;
    };
    //#endregion > loadMethod

    /*** --- *** --- *** --- ***/

    return { loadMethod };
};
