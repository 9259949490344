import React from "react";

import { Button, Grid2 as Grid } from "@mui/material";

import activationImage from "assets/images/activation/activation.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

const NewNumberSuccessActivationPage = () => {
    return (
        <Grid container className={"main-page"}>
            <Grid item className={"main-component-container"}>
                <Grid container className={"main-component"}>
                    <Grid item className={"main-form"}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            className={"main-image-container"}
                        >
                            <img
                                src={activationImage}
                                className={"main-image"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="activation-form">
                            <form>
                                <Grid item xs={12} className={"centered"}>
                                    <h1>¡SIM activada con éxito!</h1>
                                    <p>Ya puedes insertarla en tu equipo.</p>
                                    <h3>
                                        Tu número{" "}
                                        <span className="span">Dalefon</span>{" "}
                                        es:
                                    </h3>
                                    <h4>777 544 21 86</h4>
                                </Grid>

                                <Grid item xs={12} className="confirmation-box">
                                    <Grid container className="line">
                                        <Grid item xs={6}>
                                            <span>Paquete activo</span>
                                        </Grid>
                                        <Grid item xs={6} alignItems="end">
                                            <p>Dale 150</p>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="line">
                                        <Grid item xs={6}>
                                            <span>Monto pagado</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p>$ 150.00</p>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={"main-button"}
                                    >
                                        Crear cuenta
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NewNumberSuccessActivationPage;
