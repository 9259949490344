import { ACTION_DEFAULT, ACTION_DEFAULT_ERROR } from "_models/redux/types";

import initObject from "./methods/initObject";
import initConstructor from "./methods/initConstructor";
import responseContstructor from "./methods/responseContstructor";

class ServiceMiddleware {
    constructor(props) {
        this.props = props; //action, data, request, token, url, type

        this.apiInitialize = null; //auth, action, data, request, token, url, type
        this.auth = null; //null
        this.action = null; //null
        this.data = null; //added on this.apiResponse, this.response + message, response, status
        this.request = null; //null
        this.token = null; //null
        this.url = null; //null
        this.type = null; //res, error

        this.status = null; //null
        this.response = null; //this.data
        this.apiResponse = null; //this.data, type
        this.responseType = null; //null

        this.contentType = null; //null
        this.headers = null; //null
        this.log = null; //null

        this.refreshToken = null; //null
    }

    setTry() {
        this.type = this.apiInitialize.type.res || ACTION_DEFAULT;
    }
    setCatch() {
        this.type = this.apiInitialize.type.error || ACTION_DEFAULT_ERROR;
    }

    setApiInitialization() {
        const {
            action,
            data,
            request,
            token,
            url,
            type,
            contentType,
            responseType,
            emptyHeaders,
        } = this.props || {
            action: undefined,
            data: undefined,
            request: undefined,
            token: undefined,
            url: undefined,
            type: undefined,
            contentType: undefined,
            responseType: undefined,
            emptyHeaders: undefined,
        };

        this.apiInitialize =
            initConstructor({
                action: action || null,
                data: data || {},
                request: request || null,
                token: token || false,
                url: url || null,
                type: type || {},
                contentType: contentType || false,
                emptyHeaders: emptyHeaders || false,
                responseType: responseType || false,
            }) || initObject();
    }

    setApiResponse() {
        const initData = { data: {} };
        const { data } =
            typeof this.response.data !== "undefined"
                ? this.response || initData
                : initData;
        this.data = data;

        const { action, request } = this.apiInitialize || {
            action: undefined,
            request: undefined,
        };
        const { status, response, type } = this || {
            status: undefined,
            response: undefined,
            type: undefined,
        };
        const log =
            typeof action !== "undefined" && typeof status !== "undefined"
                ? action + " > " + status
                : undefined;
        this.apiResponse = responseContstructor({
            log: log || "",
            request: request || "",
            response: response || "",
            type: type || "",
            data: data || {},
        });
    }
}

export default ServiceMiddleware;
