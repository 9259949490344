import { getLoginDataHelper } from "_controllers/helpers/getLoginData";

export default function initConstructor(params = undefined) {
    const {
        url,
        request,
        action,
        type,
        data,
        token,
        contentType,
        responseType,
        emptyHeaders,
    } = params || {
        from: "Default",
        url: "",
        request: "",
        action: "",
        type: {},
        data: {},
        token: false,
        contentType: false,
        responseType: false,
        emptyHeaders: false,
    };

    let headers = null;

    const getLoginDataResponse = getLoginDataHelper();

    const userToken = getLoginDataResponse.token || "";

    if (token) {
        headers = contentType
            ? {
                  headers: {
                      authorization: userToken,
                      "content-type": contentType,
                  },
              }
            : { headers: { authorization: userToken } };
    } else if (emptyHeaders) {
        headers = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET",
                "Access-Control-Allow-Headers": "Content-Type",
            },
        };
    } else {
        headers = { headers: { "x-api-key": process.env.REACT_APP_X_API_KEY } };
    }

    let auth = {};
    if (responseType !== false) {
        auth = { responseType: responseType, headers: headers.headers || {} };
    } else {
        auth = headers;
    }

    return {
        url: url,
        request: request,
        action: action,
        type: { res: type.res || null, error: type.error || null },
        data: data,
        token: token,
        auth: auth,
    };
}
