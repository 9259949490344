import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { AppContext } from "_models/context/app";

import { purchaseRequestAction } from "_models/redux/purchase-request/action.js";
import { cleanPurchaseRequestAction } from "_models/redux/purchase-request/clean";

import CardPrepaidComponent from "utils/components/cards/card-prepaid/CardPrepaidComponent";
import CardNoPlansComponent from "utils/components/cards/card-no-plans/CardNoPlansComponent";
import { setLoadingUUIDAction } from "_models/redux/product-item/action";

const ProductCardsItemPrepaid = (props) => {
    const appContext = useContext(AppContext);
    const { setPaymentObject } = appContext || {};

    const webcheckoutUrl = process.env.REACT_APP_WEBCHECKOUT_URL;

    const { element, colorClass, purchaseRequest } = props;
    console.log(colorClass);
    const styleCard =
        colorClass === 0
            ? "lite"
            : colorClass === 1
              ? "classic"
              : colorClass === 2
                ? "slim"
                : colorClass === 3
                  ? "fit"
                  : "";

    const { purchaseRequestObj } = purchaseRequest || [];
    const { data } = purchaseRequestObj || [];
    const { uuid } = data || [];

    const navigate = useNavigate();

    const routeRecarga = () => {
        setPaymentObject(element);
        const prepaid = {
            app_source_id: 4,
            product_sku: props.element?.sku,
        };
        props.setLoadingUUIDAction(true);
        props.purchaseRequestAction({ prepaid });
    };

    useEffect(() => {
        const { response } = purchaseRequestObj || [];
        const { context } = response?.data || {};

        if (uuid !== undefined) {
            const purchaseUrl = webcheckoutUrl + `/paquete?uuid=` + uuid;
            window.location.replace(purchaseUrl);
        } else if (context) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseRequest]);

    useEffect(() => {
        return () => {
            props.cleanPurchaseRequestAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {element.is_monthly === false ? (
                <CardPrepaidComponent
                    element={element}
                    bestSelling={
                        element.name === "Dale 239 Plus" ? true : false
                    }
                    routeRecarga={routeRecarga}
                />
            ) : element.is_monthly === true ? (
                <CardNoPlansComponent
                    element={element}
                    bestSelling={
                        element.name === "Dale 3M Clásico" ? true : false
                    }
                    routeRecarga={routeRecarga}
                    styleCard={styleCard}
                />
            ) : null}
        </>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    purchaseRequest: state.purchaseRequest,
    productItem: state.productItem,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    purchaseRequestAction,
    cleanPurchaseRequestAction,
    setLoadingUUIDAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCardsItemPrepaid);
