import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { offerSelectionAction } from "_models/redux/offer-selection/action.js";
import { cleanOfferSelectionAction } from "_models/redux/offer-selection/clean";

import CardPrepaidComponent from "utils/components/cards/card-prepaid/CardPrepaidComponent";
import CardNoPlansComponent from "utils/components/cards/card-no-plans/CardNoPlansComponent";
import { usePixelsBuying } from "../hooks/usePixelsBuying";
import { setLoadingUUIDAction } from "_models/redux/get-offers/action";

const ProductCardsItem = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { pixelAddToCart } = usePixelsBuying({});
    // FIX: supportsDevices -> supportsESim
    const { tokenView, supportsDevices, supportsDevicesModels } = state;

    const { offerSelection, setLoadingUUIDAction } = props;
    const { offerSelectionObj } = offerSelection || "undefined";
    const { data } = offerSelectionObj || "undefined";
    const { message } = data || "undefined";

    // FIX: Remove colorClass, recommendCls from parent
    const { element, colorClass } = props;
    const { sku, name, price } = element;

    const styleCard =
        colorClass === 0 ? "Lite" : colorClass === 1 ? "Classic" : "Plus";

    useEffect(() => {
        if (tokenView === undefined) window.location.replace("/compras");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [elementSelect, setElementSelect] = useState();

    useEffect(() => {
        if (offerSelection?.offerSelectionObj?.response?.status === 401) {
            navigate(`/compras`);
        }
        goToNextStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerSelection]);

    useEffect(() => {
        if (typeof elementSelect !== "undefined") {
            props.offerSelectionAction({ tokenView, sku });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const routeRecarga = () => {
        setLoadingUUIDAction(true);
        setElementSelect(element);
    };

    const goToNextStep = () => {
        if (message === "success") {
            if (typeof elementSelect !== "undefined") {
                pixelAddToCart({
                    sku,
                    name,
                    amount: price,
                    currency: "MXN",
                    quantity: 1,
                });

                if (supportsDevices || supportsDevicesModels === true) {
                    navigate(`/compras/payment-method`, {
                        state: {
                            tokenView,
                            elementSelect,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                } else {
                    navigate(`/compras/user-address`, {
                        state: {
                            tokenView,
                            elementSelect,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                }
            }
        } else {
            // FIX: Not implemented
        }
    };

    useEffect(() => {
        return () => {
            props.cleanOfferSelectionAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return element.is_monthly === false ? (
        <CardPrepaidComponent
            element={element}
            bestSelling={element.name === "Dale 239 Plus" ? true : false}
            routeRecarga={routeRecarga}
        />
    ) : element.is_monthly === true ? (
        <CardNoPlansComponent
            element={element}
            bestSelling={element.name === "Dale 3M Clásico" ? true : false}
            routeRecarga={routeRecarga}
            styleCard={styleCard}
        />
    ) : null;
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    offerSelection: state.offerSelection,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    offerSelectionAction,
    cleanOfferSelectionAction,
    setLoadingUUIDAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(ProductCardsItem);
