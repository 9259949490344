import {
    PAYMENTCONFIRM_CLEAN,
    PAYMENTCONFIRM_CLEAN_ERROR,
} from "_models/redux/types";

export const cleanPaymentConfirmAction = () => async (dispatch) => {
    try {
        dispatch({
            type: PAYMENTCONFIRM_CLEAN,
            payload: [],
        });
    } catch {
        dispatch({
            type: PAYMENTCONFIRM_CLEAN_ERROR,
            payload: [],
        });
    }
};
