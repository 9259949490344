import React from "react";

import { Button, Grid2 as Grid, TextField } from "@mui/material";

import miniLogo from "assets/images/mini-logo.png";
import activationImage from "assets/images/activation/activation.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import SIMActivationImage from "assets/images/activation/SIM.png";

const ICCActivationPage = () => {
    return (
        <Grid container className={"main-page"}>
            <Grid item className={"main-component-container"}>
                <Grid container className={"main-component"}>
                    <Grid item className={"main-form"}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            className={"main-image-container"}
                        >
                            <img
                                src={activationImage}
                                className={"main-image"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="activation-form">
                            <form>
                                <Grid
                                    item
                                    xs={12}
                                    className={
                                        "activation-title-container flex"
                                    }
                                >
                                    <Grid item container xs={10}>
                                        <h2>¡Estamos cerca!</h2>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        className={"main-logo-container"}
                                    >
                                        <img
                                            title="Mini Logo"
                                            alt="Mini Logo"
                                            src={miniLogo}
                                            className={"main-logo"}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="activation-text">
                                    <p>
                                        Escribe el <span>código ICC</span> que
                                        aparece debajo del código de barras de
                                        tu tarjeta
                                    </p>

                                    <img
                                        src={SIMActivationImage}
                                        className={"main-image"}
                                    />

                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Código ICC"
                                        variant="outlined"
                                        className="m-tb-20"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={"main-button"}
                                    >
                                        Continuar
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ICCActivationPage;
