import { useEffect, useReducer } from "react";

import { useInvoiceReducer } from "./data/useInvoiceReducer";

import { useInvoiceStep1 } from "./hooks/invoiceStep1";
import { useInvoiceStep2 } from "./hooks/invoiceStep2";
import { useInvoiceStep3 } from "./hooks/invoiceStep3";

export const useInvoiceContainer = (props) => {
    const { invoiceStep1 } = useInvoiceStep1(props);
    const { invoiceStep2 } = useInvoiceStep2(props);
    const { invoiceStep3 } = useInvoiceStep3(props);

    const initialInvoiceData = {
        pageLoaded: false,

        stepCounter: 0,
    };

    const [invoiceData, dispatchInvoiceData] = useReducer(
        useInvoiceReducer,
        initialInvoiceData
    );

    useEffect(() => {
        dispatchInvoiceData({
            property: "pageLoaded",
            value: true,
        });
    }, []);

    const invoiceValues = {
        invoiceData,
        dispatchInvoiceData,

        invoiceStep1,
        invoiceStep2,
        invoiceStep3,
    };

    return {
        invoiceValues,
    };
};
