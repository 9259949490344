import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { inputCardStyle } from "modules/gift/components/gift-compatibility/card-zip-code/cardZipCodeStyleMUI";
import { CardGift } from "modules/gift/components/shared/CardGift";
import { connect } from "react-redux";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { useCardPortability } from "modules/gift/hooks/useCardPortability";
import { portabilityGiftAction } from "_models/redux/portability-gift/action";
import { cleanPortabilityGift } from "_models/redux/portability-gift/clean-action";
import CustomDialog from "utils/helpers/genericModal/modal";

const CardPortability = ({
    portabilityGiftAction,
    portabilityGift,
    cleanPortabilityGift,
}) => {
    const {
        loading,
        onSubmit,
        register,
        handleSubmit,
        getValues,
        errors,
        openModal,
        modalMessage,
        handleDialogClose,
    } = useCardPortability({
        portabilityGiftAction,
        portabilityGift,
        cleanPortabilityGift,
    });

    return (
        <>
            <CustomDialog
                title="Cobertura de Tarjeta"
                content={modalMessage}
                open={openModal}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="sm"
                backgroundColor="white"
                extraButtons={[
                    {
                        label: "Cerrar",
                        variant: "contained",
                        onClick: handleDialogClose,
                    },
                ]}
            />
            <CardGift>
                <Typography
                    variant="body1"
                    mb={1}
                    fontSize={15}
                    mt={{ xs: "0", md: "28px" }}
                >
                    Número telefónico
                </Typography>
                <TextField
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    placeholder="Ingresa tu número telefónico actual"
                    sx={inputCardStyle}
                    {...register("number_to_port", {
                        required: "El teléfono es requerido",
                    })}
                    error={Boolean(errors.number_to_port)}
                    helperText={errors.number_to_port?.message}
                    name="number_to_port"
                />
                <Typography variant="p" mb={1} fontSize={15} mt={"21px"}>
                    NIP
                </Typography>
                <TextField
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    placeholder="Ingresa el NIP"
                    type="number"
                    sx={inputCardStyle}
                    {...register("portability_pin", {
                        required: "Ingresa el NIP de tu teléfono",
                        pattern: {
                            value: /^\d{4}$/,
                            message: "El NIP debe tener 4 dígitos",
                        },
                    })}
                    error={Boolean(errors.portability_pin)}
                    helperText={errors.portability_pin?.message}
                    name="portability_pin"
                />

                <Typography variant="p" mb={1} fontSize={15} mt={"21px"}>
                    Confirmación NIP
                </Typography>
                <TextField
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    placeholder="Ingresa nuevamente tu NIP"
                    type="number"
                    sx={inputCardStyle}
                    {...register("confirmNIP", {
                        validate: (value) =>
                            value === getValues("portability_pin") ||
                            "Los NIPs no coinciden",
                    })}
                    error={Boolean(errors.confirmNIP)}
                    helperText={errors.confirmNIP?.message}
                    name="confirmNIP"
                />

                <Box mt={{ xs: "90px", md: "32px" }}>
                    <CustomButtonComponent
                        async={true}
                        bgColor={"primary-purple"}
                        text={"Continuar"}
                        height={55}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                    />
                </Box>
            </CardGift>
        </>
    );
};

const mapStateToProps = (state) => ({
    portabilityGift: state.portabilityGift,
});

const mapDispatchToProps = {
    portabilityGiftAction,
    cleanPortabilityGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPortability);
