import { useContext } from "react";
import { Grid2 as Grid } from "@mui/material";
import { InvoiceContext } from "../data/invoiceContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PDF from "../../../assets/images/landings/autofacturacion/pdf.png";
import XML from "../../../assets/images/landings/autofacturacion/xml.png";

const InvoiceSuccessResponse = () => {
    const invoiceContext = useContext(InvoiceContext);
    const { invoiceStep2 } = invoiceContext;
    const { postInvoiceSimPurchase } = invoiceStep2 || {};

    const EmailInfo = (props) => {
        return (
            <>
                {props.email !== "" ? (
                    <h3>
                        <p>Tu factura se envió con éxito al correo:</p>
                        <span>{props.email}</span>
                    </h3>
                ) : (
                    <h3>&quot;¡Factura generada con éxito!&quot;</h3>
                )}
            </>
        );
    };

    return (
        <Grid container className="invoice-response success">
            <Grid size={12}>
                {/* {sectionResponse || "---"} */}
                <CheckCircleIcon />
                <EmailInfo email={postInvoiceSimPurchase?.payload?.email} />
                <div className="download">
                    <h3>Descargar factura</h3>
                    <Grid container>
                        <Grid size={6}>
                            <a
                                href={
                                    postInvoiceSimPurchase?.payload?.invoice
                                        ?.pdf_url
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={PDF} className={"success-svg"} />
                            </a>
                        </Grid>
                        <Grid size={6}>
                            <a
                                href={
                                    postInvoiceSimPurchase?.payload?.invoice
                                        ?.xml_url
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={XML} className={"success-svg"} />
                            </a>
                        </Grid>
                    </Grid>
                </div>
                {postInvoiceSimPurchase?.payload?.credit_note?.uuid ===
                null ? null : (
                    <div className="download">
                        <h3>Descargar nota de crédito</h3>
                        <Grid container>
                            <Grid size={6}>
                                <a
                                    href={
                                        postInvoiceSimPurchase?.payload
                                            ?.credit_note?.pdf_url
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={PDF} className={"success-svg"} />
                                </a>
                            </Grid>
                            <Grid size={6}>
                                <a
                                    href={
                                        postInvoiceSimPurchase?.payload
                                            ?.credit_note?.xml_url
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={XML} className={"success-svg"} />
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default InvoiceSuccessResponse;
