import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Box, Typography, Button } from "@mui/material";

import { offerSelectionAction } from "_models/redux/offer-selection/action.js";
import { cleanOfferSelectionAction } from "_models/redux/offer-selection/clean";

import CardPrepaidComponent from "utils/components/cards/card-prepaid/CardPrepaidComponent";
import CardNoPlansComponent from "utils/components/cards/card-no-plans/CardNoPlansComponent";
import { usePixelsPurchase } from "modules/purchase/hook/usePixelsPurchase";
import { setLoadingUUIDAction } from "_models/redux/get-offers/action";

const ProductCardsItem = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { pixelAddToCart } = usePixelsPurchase({});
    // FIX: supportsDevices -> supportsESim
    const { tokenView, supportsDevices, supportsDevicesModels } = state;

    const { offerSelection, setLoadingUUIDAction } = props;
    const { offerSelectionObj } = offerSelection || "undefined";
    const { data } = offerSelectionObj || "undefined";
    const { message } = data || "undefined";

    // FIX: Remove colorClass, recommendCls from parent
    const { element, colorClass } = props;
    const { sku, name, price } = element;

    const styleCard =
        colorClass === 0
            ? "lite"
            : colorClass === 1
              ? "classic"
              : colorClass === 2
                ? "slim"
                : colorClass === 3
                  ? "fit"
                  : "";

    useEffect(() => {
        if (tokenView === undefined) window.location.replace("/purchase");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [elementSelect, setElementSelect] = useState();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        navigate(`/purchase`);
    };

    useEffect(() => {
        if (offerSelection?.offerSelectionObj?.response?.status > 400) {
            handleOpen();
        }
        goToNextStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerSelection, open]);

    useEffect(() => {
        if (typeof elementSelect !== "undefined") {
            props.offerSelectionAction({ tokenView, sku });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const routeRecarga = () => {
        setLoadingUUIDAction(true);
        setElementSelect(element);
    };

    const goToNextStep = () => {
        if (message === "success") {
            if (typeof elementSelect !== "undefined") {
                pixelAddToCart({
                    sku,
                    name,
                    amount: price,
                    currency: "MXN",
                    quantity: 1,
                });

                if (supportsDevices || supportsDevicesModels === true) {
                    navigate(`/purchase/payment-method`, {
                        state: {
                            tokenView,
                            elementSelect,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                } else {
                    navigate(`/purchase/user-address`, {
                        state: {
                            tokenView,
                            elementSelect,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                }
            }
        } else {
            // FIX: Not implemented
        }
    };

    useEffect(() => {
        return () => {
            props.cleanOfferSelectionAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {element.is_monthly === false ? (
                <CardPrepaidComponent
                    element={element}
                    bestSelling={
                        element.name === "Dale 239 Plus" ? true : false
                    }
                    routeRecarga={routeRecarga}
                />
            ) : element.is_monthly === true ? (
                <CardNoPlansComponent
                    element={element}
                    bestSelling={
                        element.name === "Dale 3M Clásico" ? true : false
                    }
                    routeRecarga={routeRecarga}
                    styleCard={styleCard}
                />
            ) : null}

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                BackdropProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Error
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {offerSelectionObj?.response?.data?.detail}
                    </Typography>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Aceptar
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    offerSelection: state.offerSelection,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    offerSelectionAction,
    cleanOfferSelectionAction,
    setLoadingUUIDAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(ProductCardsItem);
