import {
    INVOICE_AUTENTIFICATION,
    INVOICE_AUTENTIFICATION_ERROR,
} from "_models/redux/types";

import axiosTokenInstance from "services/api/service-client";

export const invoiceAutentificationAction = (params) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.post(
            `/api/transactions/v2/prepaids/invoices`,
            params
        );

        const { data, status } = response || {};

        dispatch({
            type: INVOICE_AUTENTIFICATION,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: INVOICE_AUTENTIFICATION_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
