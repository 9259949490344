import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const useForgotPasswordController = (props) => {
    const {
        cleanAccountAction,
        gettingType,
        contactPhone,
        data,
        forgotPasswordAction,
        textFieldValue123,
        forgotPassword,
        cleanForgotAction,
        controllerName,
        loginAccountsAction,
        loginAccount,
    } = props || {};
    const { message, pin } = forgotPassword?.forgotPassword || {};
    localStorage.setItem("controllerNameStorage", controllerName);

    const pinCodeAccount = loginAccount?.loginAccountObj?.data?.pin;
    const navigate = useNavigate();
    const [textFieldValue, setTextFieldValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [currentToken, setCurrentToken] = useState(null);
    const [type, setType] = useState(null);
    const [token, setToken] = useState(null);
    const [loadingButton, setLoadingButton] = useState(null);
    const reCaptchaAction =
        gettingType === "CreateAccount" ? "create_account" : "forgot_password";
    const [callActionFlag, setCallActionFlag] = useState(false);
    const [forgotPasswordResponse, setForgotPasswordResponse] = useState();
    const [forgotPasswordResponseCls, setForgotPasswordResponseCls] = useState({
        container: "",
        text: "",
    });

    const {
        direct_number,
        name,
        last_name,
        mothers_last_name,
        dateOfBirth,
        gender,
    } = data || {};

    const containerProps = useMemo(
        () => ({
            callActionFlag,
            setToken,
            reCaptchaAction,
        }),
        [callActionFlag, setToken, reCaptchaAction]
    );

    useEffect(() => {
        if (textFieldValue123) setTextFieldValue(textFieldValue123);
    }, [textFieldValue123]);

    // const valorLeido = localStorage.getItem("value");
    const fetchData = useCallback(async () => {
        if (gettingType === "ResetPassword") {
            try {
                const payload = {
                    notification_type: type,
                    platform: "web",
                    direct_number: contactPhone,
                    recaptcha_token: token,
                };
                if (typeof forgotPasswordAction === "function") {
                    if (token && token !== currentToken) {
                        await forgotPasswordAction(payload);
                        setCallActionFlag(false);
                        setCurrentToken(token);
                    }
                }
            } catch (error) {
                console.error("Error en forgotPasswordAction:", error);
            }
        } else if (gettingType === "CreateAccount") {
            const postData = {
                direct_number: direct_number,
                name: name,
                last_name: last_name,
                mothers_last_name: mothers_last_name,
                recaptcha_token: token,
                birthdate: dateOfBirth,
                gender: gender,
                notification_type: type,
            };

            if (data.email) {
                postData.email = data.email;
            }

            if (typeof loginAccountsAction === "function") {
                if (token && token !== currentToken) {
                    setCallActionFlag(false);
                    await loginAccountsAction(postData);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        forgotPasswordAction,
        type,
        textFieldValue,
        token,
        currentToken,
        loginAccountsAction,
    ]);

    const handleButtonClick = useCallback(
        (type) => {
            setIsLoading(true);
            setType(type);
            setCallActionFlag(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [textFieldValue]
    );

    const handleTextFieldChange = useCallback((event) => {
        const value = event.target.value;
        localStorage.setItem("value", value);
        setTextFieldValue(value);
    }, []);

    const handleContinueClick = useCallback(() => {
        if (textFieldValue.length === 10) {
            const ResetPassword = "/forgot-password/media-selection-pin";
            navigate(ResetPassword, {
                state: {
                    gettingType: "ResetPassword",
                    contactPhone: textFieldValue,
                },
            });
        } else {
            setErrorMessage("Se requieren exactamente 10 dígitos");
        }
    }, [textFieldValue, navigate]);

    useEffect(() => {
        if (loginAccount?.loginAccountObj?.data?.message === "success") {
            setIsLoading(false);
            navigate("/forgot-password/security-code", {
                state: {
                    contactPhone: direct_number,
                    pin: pinCodeAccount,
                    gettingType,
                    data,
                },
            });
        } else {
            setIsLoading(false);
        }
        if (message === "success") {
            setIsLoading(false);
            setForgotPasswordResponse("Envío exitoso");
            setForgotPasswordResponseCls({
                container: "success-container",
                text: "success-text",
            });

            navigate("/forgot-password/security-code", {
                state: { contactPhone, pin, gettingType, data },
            });
        } else {
            setIsLoading(false);
            setForgotPasswordResponse("Envío fallido");
            setForgotPasswordResponseCls({
                container: "error-container",
                text: "error-text",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPassword, loginAccount]);

    useEffect(() => {
        if (loginAccount?.loginAccountObj?.status === 200) {
            return () => {
                cleanAccountAction();
                setCallActionFlag(false);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPassword, loginAccount, message]);

    useEffect(() => {
        if (message === "success") {
            return () => {
                cleanForgotAction();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPassword, loginAccount, message]);

    useEffect(() => {
        if (callActionFlag) {
            fetchData();
        }
    }, [token, type, textFieldValue, callActionFlag, fetchData]);

    return {
        textFieldValue,
        errorMessage,
        type,
        setType,
        token,
        setToken,
        loadingButton,
        setLoadingButton,
        callActionFlag,
        handleTextFieldChange,
        handleButtonClick,
        setCallActionFlag,
        reCaptchaAction,
        handleContinueClick,
        setErrorMessage,
        containerProps,
        isLoading,
        setIsLoading,
        forgotPasswordResponse,
        forgotPasswordResponseCls,
    };
};
