import { GET_PROMOTIONS, GET_PROMOTIONS_ERROR } from "_models/redux/types";

import axiosTokenInstance from "services/api/service-client";

export const getPromotionsAction = () => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/promotions`
        );

        dispatch({
            type: GET_PROMOTIONS,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: GET_PROMOTIONS_ERROR,
            payload: e || {},
        });
    }
};
