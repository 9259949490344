// Import: Middleware Action & Custom Types
import MiddlewareClass from "services/MiddlewareClass";
import {
    RESET_PASSWORD_STATUS,
    RESET_PASSWORD_STATUS_ERROR,
} from "_models/redux/types";

// Action name
const action = "resetPasswordStatusAction";

// Action function
export const resetPasswordStatusAction = (params) => async (dispatch) => {
    const { token } = params || {
        token: undefined,
    };

    //Service Middleware object definition
    const serviceMiddleware = new MiddlewareClass({
        url: `/api/users/reset-password/${token}/status`,
        request: "get",
        data: {},
        // token: false,
        action: action,
        type: {
            res: RESET_PASSWORD_STATUS,
            error: RESET_PASSWORD_STATUS_ERROR,
        },
    });

    // Endpoint: Try & Catch
    try {
        await serviceMiddleware.requestService(false, action).then(() => {
            const { apiResponse } = serviceMiddleware || {
                apiResponse: undefined,
            };
            const { type, data } = apiResponse || {
                type: undefined,
                data: undefined,
            };

            dispatch({
                type: type || RESET_PASSWORD_STATUS,
                payload: data || {},
            });
        });
    } catch {
        const { type, data } = serviceMiddleware || {
            type: undefined,
            data: undefined,
        };

        dispatch({
            type: type || RESET_PASSWORD_STATUS_ERROR,
            payload: data || {},
        });
    }
};
