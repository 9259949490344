/******/
/** Import: React and other packages */
import React, { useEffect } from "react";

/******/
/** Import: Material UI Components */
import { CssBaseline } from "@mui/material";

/******/
/** Import: App Components */
import ActiveOffersWidgets from "./widgets";

/******/
/** Importa: Contexts **/
//

/******/
/** Importa: Actions **/
//

/******/
/** Importa: Hooks **/
//

/******/
/** Importa: Utils and Helpers **/
//

/******/
/** Global Variables and Constants **/
//

/******/
/** Function Component **/
const ActiveOffersPresentational = () => {
    //#region > Destructuring props
    //const {} = props || {};
    //#endregion > Destructuring props

    /******/
    /** Default useContext **/
    //

    /** Custom useContext **/
    //

    /******/
    /** Default useState **/
    //

    /** Custom useState **/
    //

    /******/
    /** Default constant and variables **/
    //

    /** Custom constant and variables **/
    //

    /******/
    /**  Default hooks **/
    //

    /** Custom hooks **/
    //

    /******/
    /** Default methods/functions **/

    /**
     *
     * @param {*} from
     * @description
     * Method that excute a code block,
     * before destroy / unmount the component
     */
    const destroyComponent = () => {};

    /** Custom methods/functions **/
    //

    /******/
    /** Default useCallback **/
    //

    /** Custom useCallback **/
    //

    /******/
    /** Default useEffect **/
    useEffect(() => {
        return () => {
            destroyComponent("useEffect [] => return()");
        };
    }, []);

    /** Custom useEffect **/
    //

    /******/
    /** Component(s) to render **/
    return (
        <div>
            <CssBaseline />
            <ActiveOffersWidgets />
        </div>
    );
};

/** Export component **/
export default ActiveOffersPresentational;
