import React from "react";

//Material UI:
import { Button, Grid2 as Grid, Typography } from "@mui/material";

//Material UI Styles:
import {
    gridContainerNoData,
    gridItemNoData,
    gridItemNoDataText,
    typographyNoDataText,
    typographyTextNoPlans,
} from "./styles-mui/noDataStylesMUI";

//Images:
import nodataImage from "assets/images/home/no-data.png";
import noplanesImage from "assets/images/home/noplanes.png";

//Css:
import "../../../../../../../assets/styles/css/portal/pages/home/sections/active-offers/components/no-data.css";

function NoDataComponent() {
    return (
        <Grid container className="no-data-container" sx={gridContainerNoData}>
            <Grid size={6} sx={gridItemNoData}>
                <img
                    src={nodataImage}
                    alt="Mujer recargada de un brazo"
                    className="no-data-image"
                />
            </Grid>
            <Grid
                size={6}
                className="no-data-text-container"
                sx={gridItemNoDataText}
            >
                <Typography variant="h1" sx={typographyNoDataText}>
                    ¡No te quedes sin datos!
                </Typography>
                <Typography variant="p" sx={typographyTextNoPlans}>
                    Conoce nuestras promociones y prueba los{" "}
                    <span>
                        <img
                            src={noplanesImage}
                            className="no-planes-image"
                            alt="Escrito que dice No planes en color morado"
                        />
                    </span>
                    , ahorra más y sin plazos forzosos.
                </Typography>

                <Button
                    className="button"
                    sx={{ m: "20px 0 0" }}
                    href="/paquetes"
                >
                    Quiero más megas
                </Button>
            </Grid>
        </Grid>
    );
}

export default NoDataComponent;
