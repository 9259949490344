import {
    CLEAN_POST_LEAD_DATA,
    CLEAN_POST_LEAD_DATA_ERROR,
} from "_models/redux/types";

export const cleanPostLeadDataAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_POST_LEAD_DATA,
            payload: [],
        });
    } catch {
        dispatch({
            type: CLEAN_POST_LEAD_DATA_ERROR,
            payload: [],
        });
    }
};
