import React from "react";
import { Grid2 as Grid } from "@mui/material";

const EsimActivationFooter = () => {
    return (
        <Grid container className="qr-footer">
            <Grid size={12}>
                <p>Dalefon © Todos los derechos registrados.</p>
            </Grid>
        </Grid>
    );
};

export default EsimActivationFooter;
