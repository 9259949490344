import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";

import { Button, Grid2 as Grid } from "@mui/material";

import purchaseImage from "assets/images/purchase/purchase.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import WhatsappImage from "assets/images/template/whatsapp.png";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";

import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";

const ComprobarCompatibilidadDevicePage = (props) => {
    const navigate = useNavigate();

    const { state } = useLocation();
    const tokenView = typeof state !== "undefined" ? state?.token : undefined;
    const imei = typeof state !== "undefined" ? state?.imeiValue : "---";

    const { cleanCheckImeiAction } = props || {};

    const [whastappUrl, setWhastappUrl] = useState();

    const checkImeiMethod = () => {
        navigate(`/compras`, {
            state: { dataToken: tokenView },
        });
    };

    useEffect(() => {
        setWhastappUrl(
            // encodeURI(
            "https://api.whatsapp.com/send/?phone=5215586483831&text=Hola, Dalefon. Al parecer necesitan más información de mi equipo para ver si soy compatible con ustedes, ¿me podrían ayudar por favor? Mi IMEI es: " +
                imei +
                "&type=phone_number&app_absent=0"
            // )
        );

        return () => {
            cleanCheckImeiAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            size={12}
            className={"main-component"}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                color: "white",
            }}
        >
            <Grid size={{ xs: 12, md: 6 }} className={"main-image-container"}>
                <img src={purchaseImage} className={"main-image"} />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} className="purchase-form">
                <form>
                    <Grid size={12} className="centered">
                        <h1 className="m-0">¡Ups!</h1>
                        <p>
                            Parece que necesitamos más información de tu equipo.
                        </p>

                        <p>
                            Para brindarte el mejor servicio por favor ponte en
                            contacto con nosotros para revisar la compatibilidad
                            de tu dispositivo.
                        </p>
                    </Grid>

                    <Grid size={12} className="centered">
                        <h3>Llámanos</h3>
                        <Grid container sizze={12}>
                            <Grid size={6} className="compatibility">
                                <p className="phones">
                                    <span>
                                        <WifiCalling3Icon />
                                    </span>{" "}
                                    800 649 0754
                                </p>
                            </Grid>
                            <Grid size={6} className="compatibility">
                                <p className="phones">
                                    <span>
                                        {" "}
                                        <WifiCalling3Icon />
                                    </span>{" "}
                                    777 362 2340
                                </p>
                            </Grid>
                        </Grid>

                        <p>O escríbenos a</p>
                        <a href={whastappUrl}>
                            <img
                                src={WhatsappImage}
                                className={"whatsapp-image"}
                            />
                        </a>
                    </Grid>
                    <Grid size={12} className="compatibility">
                        <Button onClick={checkImeiMethod}>
                            Probar un equipo diferente
                        </Button>
                    </Grid>
                </form>
            </Grid>

            <FormFooterComponent />
        </Grid>
    );
};

// export default NoCompatiblePurchasePage;

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    checkImei: state.checkImei,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCheckImeiAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComprobarCompatibilidadDevicePage);
