import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePixels } from "./usePixels";
import deparam from "deparam.js";
import { getCampaingData } from "utils/helpers/localstorage/campaing";

export const useGift = (
    initRegisterAction,
    initRegister,
    cleanCheckImeiGift,
    cleanCheckZipCodeGift,
    cleanVerifyPinGift,
    cleanSetUserGift,
    cleanSendEmailAndCompleteGift
) => {
    const sourceId = 1;
    const [channelId, setChannelId] = useState();
    const [campaingId, setCampaingId] = useState();
    const [referenceLead, setReferenceLead] = useState();
    const [loading, setLoading] = useState(true);
    const [tokenLead, setTokenLead] = useState("");
    const hasError = initRegister?.initRegisterObj?.response?.status > 300;
    const navigate = useNavigate();
    const { pixelViewContent } = usePixels();

    const [searchParams, setSearchParams] = useState();
    const [queryParams, setQueryParams] = useState();

    useEffect(() => {
        const { location } = window || {};
        const { search } = location || {};
        setSearchParams(deparam(search));
    }, []);

    useEffect(() => {
        const qp = new URLSearchParams(searchParams).toString();
        setQueryParams(qp);
    }, [searchParams]);

    useEffect(() => {
        if (queryParams !== undefined) {
            const {
                campaingId: cid,
                channelId: chid,
                referenceLead: r,
            } = getCampaingData();
            setCampaingId(cid);
            setChannelId(chid);
            setReferenceLead(r);
        }
    }, [queryParams]);

    useEffect(() => {
        if (channelId !== undefined && campaingId !== undefined) {
            const init = {
                source_id: parseInt(sourceId),
                channel_id: parseInt(channelId),
                campaing_id: parseInt(campaingId),
                landing_id: 9,
                reference: referenceLead,
            };
            initRegisterAction(init);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelId, campaingId, initRegisterAction]);

    useEffect(() => {
        if (initRegister?.initRegisterObj?.status === 200) {
            // Llamar a las funciones de limpieza
            cleanCheckImeiGift();
            cleanCheckZipCodeGift();
            cleanVerifyPinGift();
            cleanSetUserGift();
            cleanSendEmailAndCompleteGift();
            setLoading(false);
            const token = initRegister?.initRegisterObj?.data?.token || "";
            pixelViewContent(token);
            setTokenLead(token);
            navigate(`?${queryParams}`, {
                state: { tokenLead: token },
            });
        } else if (initRegister?.initRegisterObj?.response?.status >= 300) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initRegister, navigate]);

    return {
        hasError,
        loading,
        tokenLead,
    };
};
