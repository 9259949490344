// Import: Middleware Action & Custom Types
import MiddlewareClass from "services/MiddlewareClass";
import { TICKETS, TICKETS_ERROR } from "_models/redux/types";

// Action name
const action = "getTicketsAction";

// Action function
export const getTickets = (params) => async (dispatch) => {
    const { token, key, new_password } = params || {
        token: undefined,
        key: undefined,
        new_password: undefined,
    };

    const data = {
        token,
        key,
        new_password,
    };

    //Service Middleware object definition
    const serviceMiddleware = new MiddlewareClass({
        url: `/api/support/tickets`,
        request: "get",
        data: data,
        token: true,
        action: action,
        type: { res: TICKETS, error: TICKETS_ERROR },
    });

    // Endpoint: Try & Catch
    try {
        await serviceMiddleware.requestService(false, action).then(() => {
            const { apiResponse } = serviceMiddleware || {
                apiResponse: undefined,
            };
            const { type, data } = apiResponse || {
                type: undefined,
                data: undefined,
            };

            dispatch({
                type: type || TICKETS,
                payload: data || {},
            });
        });
    } catch {
        const { type, data } = serviceMiddleware || {
            type: undefined,
            data: undefined,
        };
        // const { error } = type || { error: undefined };
        if (typeof logFlag === "boolean") {
            console.log("catch > data: ", data);
            console.log("catch > type: ", type);
        }

        dispatch({
            type: type || TICKETS_ERROR,
            payload: data || {},
        });
    }
};
