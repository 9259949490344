import React from "react";
import { Container, Grid2 as Grid } from "@mui/material";

const NumberChangedTextComponent = () => {
    return (
        <Container>
            <Grid container className={"number-changed-text"}>
                <Grid size={12} className={"number-changed-title"}>
                    <h1>¡Cambio de número exitoso!</h1>
                </Grid>

                <Grid
                    size={{ xs: 12, sm: 9, md: 6 }}
                    className={"number-changed-description"}
                >
                    <p>
                        Inicia sesión nuevamente y sigue disfrutando de los
                        beneficios que Dalefon tiene para ti
                    </p>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NumberChangedTextComponent;
