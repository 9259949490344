import React from "react";

//Material UI:
import {
    Box,
    CircularProgress,
    Grid2 as Grid,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

//CSS:
import "assets/styles/css/purchase/purchase.css";

//Styles MUI:
import { gridItemContainerCard } from "./styles-mui/purchaseFormStylesMUI";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function PurchaseFormComponent({
    getLoading,
    initRegister,
    addPurchaseContactInfo,
    handleSubmit,
    onSubmit,
    register,
    registerOptions,
    errors,
    contactFullName,
    setContactFullName,
    contactPhone,
    setContactPhone,
    contactEmail,
    setContactEmail,
    checkedConditions,
    handleChangeConditions,
    detail,
    submitLoading,
}) {
    return (
        <Grid size={{ xs: 12, lg: 5, xl: 4 }} sx={gridItemContainerCard}>
            <Box>
                {getLoading ? (
                    <CircularProgress className="Circular-progress" />
                ) : initRegister?.initRegisterObj?.status >= 200 &&
                  initRegister?.initRegisterObj?.status < 300 ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            size={12}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                            }}
                        >
                            <TextField
                                fullWidth
                                {...register("contactFullName", {
                                    ...registerOptions.contactFullName,
                                    // Validación para caracteres especiales
                                    validate: (value) => {
                                        const invalidCharsRegex = /[^\p{L}\s]/u;
                                        if (invalidCharsRegex.test(value)) {
                                            return "El nombre no puede contener caracteres especiales.";
                                        }
                                        return true;
                                    },
                                    minLength: {
                                        value: 5,
                                        message:
                                            "Debe tener al menos 5 caracteres",
                                    },
                                    maxLength: {
                                        value: 100,
                                        message:
                                            "El nombre no debe exceder los 100 caracteres",
                                    },
                                })}
                                id="outlined-basic"
                                label="Nombre completo"
                                variant="outlined"
                                className="purchase-textfield-container"
                                value={contactFullName}
                                onChange={(event) => {
                                    setContactFullName(event.target.value);
                                }}
                            />
                            <span>
                                {errors?.contactFullName && (
                                    <p className="required-incidence">
                                        {errors.contactFullName.message}
                                    </p>
                                )}
                            </span>

                            <TextField
                                fullWidth
                                {...register(
                                    "contactPhone",
                                    registerOptions.contactPhone
                                )}
                                id="outlined-basic"
                                label="Número telefónico"
                                variant="outlined"
                                className="purchase-textfield-container"
                                value={contactPhone}
                                onChange={(event) => {
                                    setContactPhone(event.target.value);
                                }}
                            />
                            <span>
                                {errors?.contactPhone &&
                                    errors?.contactPhone && (
                                        <p className="required-incidence">
                                            {" "}
                                            {errors.contactPhone.message}
                                        </p>
                                    )}
                            </span>

                            <TextField
                                fullWidth
                                {...register(
                                    "contactEmail",
                                    registerOptions.contactEmail
                                )}
                                id="outlined-basic"
                                label="Correo electrónico (opcional)"
                                variant="outlined"
                                className="purchase-textfield-container"
                                value={contactEmail}
                                onChange={(event) => {
                                    setContactEmail(event.target.value);
                                }}
                            />
                            <span>
                                {errors?.contactEmail &&
                                    errors?.contactEmail && (
                                        <p className="required-incidence">
                                            {" "}
                                            {errors.contactEmail.message}
                                        </p>
                                    )}
                            </span>
                        </Grid>

                        <FormControlLabel
                            required
                            sx={{
                                textAlign: "start",
                                ".MuiFormControlLabel-asterisk": {
                                    display: "none",
                                },
                            }}
                            control={
                                <Checkbox
                                    checked={checkedConditions}
                                    onChange={handleChangeConditions}
                                    color="secondary"
                                />
                            }
                            label={
                                <h4 className={"terms-conditions-purchase"}>
                                    <span> Acepto los </span>
                                    <a
                                        href="https://cdn.dalefon.mx/legales/terminos_y_condiciones_dalefon.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        términos y condiciones
                                    </a>
                                    <span> y el </span>
                                    <a
                                        href="https://dalefon.mx/aviso-de-privacidad"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        aviso de privacidad*
                                    </a>
                                </h4>
                            }
                        />

                        <Grid
                            container
                            className="required-incidence error-response-container"
                        >
                            {" "}
                            <Grid
                                size={12}
                                className="required-incidence error-response"
                            >
                                {detail
                                    ? detail
                                    : addPurchaseContactInfo
                                            ?.addPurchaseContactInfoObj?.data
                                            ?.detail
                                      ? addPurchaseContactInfo
                                            ?.addPurchaseContactInfoObj?.data
                                            ?.detail
                                      : addPurchaseContactInfo
                                            ?.addPurchaseContactInfoObj
                                            ?.detail ||
                                        addPurchaseContactInfo?.response
                                            ?.detail}
                            </Grid>
                        </Grid>

                        <Grid size={12}>
                            <Box>
                                <CustomButtonComponent
                                    text={"Continuar"}
                                    bgColor={"violet"}
                                    height={"50px"}
                                    async={true}
                                    loading={submitLoading}
                                />
                            </Box>
                        </Grid>
                    </form>
                ) : (
                    <p>
                        {initRegister?.initRegisterObj?.response?.data?.detail}
                    </p>
                )}
            </Box>
        </Grid>
    );
}

export default PurchaseFormComponent;
