import React from "react";

//CSS:
import "assets/styles/css/utils/components/flows-products/components/content-no-plans-products.css";

// Material Ui:
import { Box, Grid2 as Grid } from "@mui/material";

// Swiper:
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

//Images Titles:
import Fit from "assets/images/products/fit.png";

//Images Desktop & Mobile:
import imgNoPlansPromoFit from "assets/images/utils/components/section-show-title/banner-desktop-fit.png";
import imgNoPlansPromoFitMobile from "assets/images/utils/components/section-show-title/banner-movil-fit.png";
//Components:
import AProductCardsItem from "modules/purchase/pages/widgets/productCardsItem";
import BProductCardsItem from "modules/compras/pages/widgets/productCardsItem";
import ProductCardsItemPrepaid from "modules/portal/pages/products/widgets/ProductCardsItemPrepaid";
import SectionCarouselProductComponent from "utils/components/section-carousel-product/SectionCarouselProductComponent";

function ContentFitNoPlans({ fitProducts, flow }) {
    const ProductCard =
        flow === "prepaid"
            ? ProductCardsItemPrepaid
            : flow === "purchase"
              ? AProductCardsItem
              : BProductCardsItem;

    const swiperStyle = {
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const boxImgPromoResponsive = {
        display: { xs: "flex", md: "none" },
        my: 1,
    };

    const boxImgPromoInterestFree = {
        pt: { xs: 2, md: 2 },
        display: { xs: "none", md: "flex" },
    };
    return (
        <>
            {/*** NO Planes - FIT ***/}
            {fitProducts.length > 0 && (
                <>
                    <Box sx={{ mt: 10, textAlign: "center" }}>
                        <img
                            src={Fit}
                            alt="Titulo de los paquetes Fit"
                            className="img-title-no-plans"
                        />
                        <Box>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Box
                                    component="img"
                                    src={imgNoPlansPromoFit}
                                    alt="Banner de promoción de planes Fit"
                                    sx={{
                                        maxWidth: "45%",
                                        height: "auto",
                                        display: "block",
                                        margin: "0 auto",
                                        "@media (max-width: 900px)": {
                                            height: "180px",
                                        },
                                        ...boxImgPromoInterestFree,
                                    }}
                                />
                                <Box sx={boxImgPromoResponsive}>
                                    <Box
                                        component="img"
                                        src={imgNoPlansPromoFitMobile}
                                        alt="Banner de promoción de planes Lite en móvil"
                                        sx={{
                                            width: "fit-content",
                                            height: "110px",
                                            "@media (max-width: 900px)": {
                                                width: "100%",
                                                height: "180px",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    <SectionCarouselProductComponent showButton={false}>
                        {fitProducts.map(
                            (
                                {
                                    mobile_data,
                                    mobile_data_roaming,
                                    mobile_data_throttling,
                                    gigabyte_divisor,
                                    ...rest
                                },
                                i
                            ) => {
                                const gigaBytes =
                                    (mobile_data +
                                        mobile_data_roaming +
                                        mobile_data_throttling) /
                                    gigabyte_divisor;

                                return (
                                    <SwiperSlide
                                        key={rest.name}
                                        style={swiperStyle}
                                    >
                                        <ProductCard
                                            key={i}
                                            index={i}
                                            colorClass={3}
                                            element={{
                                                mobile_data,
                                                mobile_data_roaming,
                                                mobile_data_throttling,
                                                ...rest,
                                            }}
                                            gigaBytesParent={gigaBytes}
                                            recommendCls=""
                                        />
                                    </SwiperSlide>
                                );
                            }
                        )}
                    </SectionCarouselProductComponent>
                </>
            )}
        </>
    );
}
export default ContentFitNoPlans;
