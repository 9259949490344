export const setInfoDataHelper = (params = {}) => {
    if (
        typeof params.account_info !== "undefined" &&
        params.account_info === null
    )
        return false;

    const { name, last_name, mothers_last_name, direct_number } =
        params.account_info || {
            name: "",
            last_name: "",
            mothers_last_name: "",
            direct_number: "",
        };

    localStorage.setItem("user_name", name);
    localStorage.setItem("user_last_name", last_name);
    localStorage.setItem("user_mothers_last_name", mothers_last_name);
    localStorage.setItem("user_direct_number", direct_number);

    return true;
};
