import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Modal,
    Box,
    Grid2 as Grid,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import preview from "assets/images/activation/SIM.png";
import { Close } from "@mui/icons-material";

const CardVideo = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Grid size={{ xs: 12, md: 6 }}>
            <Card
                onClick={handleOpen}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 70,
                    background: "none",
                    boxShadow: "0px 3px 6px #06060629",
                    borderRadius: "10px",
                    opacity: 1,
                    position: "relative",
                    zIndex: 1,
                    marginTop: 5,
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background:
                            "transparent linear-gradient(78deg, #5C73F6 0%, #952BCE 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "10px",
                        opacity: 0.47,
                        zIndex: -1,
                    }}
                />
                <img
                    src={preview}
                    alt="Miniatura"
                    style={{
                        width: "50px",
                        height: "50px",
                        marginLeft: "16px",
                        zIndex: 2,
                    }}
                />
                <CardContent sx={{ flexGrow: 1, zIndex: 2 }}>
                    {" "}
                    <Typography variant="body1">Ver tutorial</Typography>
                </CardContent>
                <IconButton sx={{ color: "white", zIndex: 2 }}>
                    <ChevronRightIcon />
                </IconButton>
            </Card>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        width: { xs: "90vw", md: "60vw" },
                        background:
                            "transparent linear-gradient(180deg, #450D81 0%, #30227B 50%, #5F1789 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "30px",
                        opacity: 1,
                        margin: "auto",
                        padding: 2,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10,
                    }}
                >
                    <div style={{ textAlign: "center" }}>
                        <iframe
                            width="100%"
                            height="400"
                            src="https://www.youtube.com/embed/Q8mKNTqhcJs"
                            title="Tutorial"
                            border="0px"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ borderRadius: "30px", border: "none" }}
                        ></iframe>
                    </div>

                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            bottom: "-50px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: "white",
                            zIndex: 15,
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
            </Modal>
        </Grid>
    );
};

export default CardVideo;
