import React from "react";

//Css:
import "assets/styles/css/portal/pages/payment/components/payment-error.css";

//React Router Dom:
import { useLocation, useNavigate } from "react-router-dom";

//Material UI:
import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";

//Styles MUI:
import {
    boxContainerDesc,
    boxDetail,
    containerPending,
    gridContainerPending,
    gridItemContainerTexts,
    typographyDetail,
    typographyMessage,
    typographyTitle,
} from "./styles-mui/paymentErrorStylesMUI";

//Components:
import PortalFooter from "modules/portal/components/footer";
import PortalNavigation from "modules/portal/components/navigation";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//Assets:
import imgErrorPayment from "assets/images/portal/pages/payment/components/error-payment/error-payment.png";

export default function PaymentErrorComponent() {
    const { state } = useLocation();
    const history = useNavigate();

    const { title, detail } = state || "undefined";

    return (
        <>
            <PortalNavigation />
            <Container
                className="container-payment-error"
                sx={containerPending}
                maxWidth="false"
            >
                <Grid container sx={gridContainerPending}>
                    <Grid size={12}>
                        <img
                            src={imgErrorPayment}
                            className="error-image"
                            alt="Persona asombrado con un icono de error de fondo"
                        />
                    </Grid>
                    <Grid size={12} sx={gridItemContainerTexts}>
                        <Box>
                            <Typography variant="h1" sx={typographyTitle}>
                                {title || "Pago no exitoso"}
                            </Typography>
                            <Typography sx={typographyMessage}>
                                Tu pago no pudo ser procesado:
                            </Typography>

                            <Box sx={boxContainerDesc}>
                                <Box sx={boxDetail}>
                                    <Typography sx={typographyDetail}>
                                        {detail ||
                                            "El pago no se pudo completar."}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 10, md: 3.5, lg: 3, xl: 2 }}>
                        <CustomButtonComponent
                            text="Volver al inicio"
                            bgColor="violet"
                            height="50px"
                            onClick={() => history(`/`)}
                        />
                    </Grid>
                </Grid>
            </Container>
            <PortalFooter />
        </>
    );
}
