import React from "react";

import { Grid2 as Grid } from "@mui/material";
import EsimGiftConfirmation from "assets/images/landings/gift/esim-gift-confirmation.png";

const GiftConfirmationContent = () => {
    return (
        <>
            <Grid
                container
                size={12}
                display={"flex"}
                justifyContent={"center"}
                height={"100vh"}
            >
                <Grid className={"main-form no-bg success-form centered"}>
                    <Grid
                        size={12}
                        className="purchase-form no-form gift-confirmation-form"
                    >
                        <form>
                            <Grid
                                size={12}
                                paddingY={{ xs: 0, md: 10 }}
                                className="centered"
                            >
                                <div>
                                    <h4>¡Hemos enviado tu regalo!</h4>
                                </div>

                                <div className={"congratulations-container"}>
                                    <img
                                        src={EsimGiftConfirmation}
                                        className={
                                            "main-image esim-gift-confirmation"
                                        }
                                    />
                                </div>

                                <div className="success-p">
                                    <p>Ya puedes cerrar esta página</p>
                                </div>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GiftConfirmationContent;
