import React from "react";

//Material UI:
import { Grid2 as Grid, Box, Typography } from "@mui/material";

//Styles MUI:
import {
    gridContainerCheckCompatibility,
    gridItemTitle,
    typographyTitle,
    typographyTitle2,
    typographySubTitle,
    gridContainerContent,
    typographyTheftReport,
} from "./styles-mui/baseImeiCheckStylesMUI";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

function BaseImeiCheckComponent({ children }) {
    return (
        <BaseFirstStepsComponent>
            <Grid container sx={gridContainerCheckCompatibility}>
                <Grid size={12} sx={gridItemTitle}>
                    <Typography sx={typographyTitle}>
                        ¿Tu celular es compatible con Dalefon?
                    </Typography>
                    <Typography sx={typographyTitle2}>¡Descúbrelo!</Typography>
                    <Typography sx={typographySubTitle}>
                        Verifica la compatibilidad con cualquiera de las
                        siguientes opciones y recuerda que si no ves tu
                        dispositivo en la lista, tendrás que revisarlo con tu
                        IMEI.
                    </Typography>
                </Grid>

                <Grid container size={12} sx={gridContainerContent}>
                    {children}
                </Grid>

                <Grid size={12} sx={{ mt: 5 }}>
                    <Box>
                        <Typography sx={typographyTheftReport}>
                            Descubre si tu equipo tiene reporte de robo{" "}
                            <a
                                href="https://www.ift.org.mx/usuarios-y-audiencias/consulta-de-imei"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Typography
                                    variant="span"
                                    sx={{ color: "white", fontWeight: "bold" }}
                                >
                                    aquí
                                </Typography>
                            </a>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </BaseFirstStepsComponent>
    );
}

export default BaseImeiCheckComponent;
