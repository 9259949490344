/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { InvoiceContext } from "../data/invoiceContext";
import { useFormInputsHook } from "./invoiceFormInputsHook";

export const useFormHook = ({
    watch,
    reset,
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    setError,
    errors,
}) => {
    const invoiceContext = useContext(InvoiceContext);
    const { invoiceStep1, invoiceStep2, dispatchInvoiceData } = invoiceContext;

    const {
        invoiceDataStep2,
        dispatchInvoiceDataStep2,

        postInvoiceSimPurchase,
        postInvoiceSimPurchaseAction,
    } = invoiceStep2 || {};

    const { reCaptchaToken, reCaptchaSubmit } = invoiceDataStep2 || {};

    const selectedOption = invoiceStep1?.invoiceDataStep1?.selectedOption;
    const simOrderNumber = invoiceStep1?.invoiceDataStep1?.simOrderNumber;
    const phoneNumber = invoiceStep1?.invoiceDataStep1?.phoneNumber;
    const transactionId = invoiceStep1?.invoiceDataStep1?.transactionId;

    useEffect(() => {
        if (
            postInvoiceSimPurchase?.payload?.uuid !== undefined &&
            postInvoiceSimPurchase?.status === 200
        ) {
            dispatchInvoiceData({
                property: "stepCounter",
                value: 3,
            });
        }
    }, [postInvoiceSimPurchase]);

    const onSubmit = (e) => {
        dispatchInvoiceDataStep2({
            property: "reCaptchaSubmit",
            value: { status: "clicked", e: e },
        });

        dispatchInvoiceDataStep2({
            property: "loaderResponse",
            value: true,
        });
    };

    useEffect(() => {
        const { e } = reCaptchaSubmit || {};

        if (reCaptchaToken && e) {
            let postData = e;

            const commonData = {
                receiver_legal_id: e.receiver_legal_id,
                receiver_legal_name: e.receiver_legal_name,
                receiver_legal_zipcode: e.receiver_legal_zipcode,
                receiver_tax_regime: e.receiver_tax_regime,
                cfdi_use: e.cfdi_use,
                payment_method_id: e.payment_method_id,
                email: e.email,
                recaptcha_token: reCaptchaToken,
            };

            if (selectedOption === "sim_purchase") {
                postData = {
                    sim_purchase: {
                        order_number: simOrderNumber,
                    },
                    ...commonData,
                };
            } else if (selectedOption === "prepaid_purchase") {
                postData = {
                    prepaid_purchase: {
                        transaction_id: parseInt(transactionId),
                        direct_number: phoneNumber,
                    },
                    ...commonData,
                };
            }
            if (postData["email"] === "") {
                delete postData.email;
            }

            postData["recaptcha_token"] = reCaptchaToken;

            postInvoiceSimPurchaseAction({ postData });
        }
    }, [reCaptchaToken]);

    useEffect(() => {
        const { loaded, payload } = postInvoiceSimPurchase || {};
        const { title, context, detail } = payload || {};

        const { credit_note, invoice } = payload || {};

        if (loaded) {
            if (context) {
                dispatchInvoiceDataStep2({
                    property: "formResponse",
                    value: (
                        <>
                            <h3>{title}</h3>
                            <h4>{detail}</h4>
                        </>
                    ),
                });

                // setFormResponse(
                //     <>
                //         <h3>{title}</h3>
                //         <h4>{detail}</h4>
                //     </>
                // );

                dispatchInvoiceDataStep2({
                    property: "sectionResponse",
                    value: "error",
                });

                // setSectionResponse("error");

                dispatchInvoiceDataStep2({
                    property: "reCaptchaSubmit",
                    value: undefined,
                });

                dispatchInvoiceDataStep2({
                    property: "reCaptchaToken",
                    value: undefined,
                });

                // setReCaptchaSubmit();
                // );
            }

            if (credit_note) {
                // setSectionResponse("success");
                dispatchInvoiceDataStep2({
                    property: "sectionResponse",
                    value: "success",
                });
            }

            if (invoice) {
                // setSectionResponse("success");
                dispatchInvoiceDataStep2({
                    property: "sectionResponse",
                    value: "success",
                });
            }
        }
    }, [postInvoiceSimPurchase]);

    const { inputs } = useFormInputsHook({
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    });

    return {
        inputs,
        onSubmit,
    };
};
