export const ACTION_DEFAULT = "ACTION_DEFAULT";
export const ACTION_DEFAULT_ERROR = "ACTION_DEFAULT_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_CLEAN = "LOGIN_CLEAN";
export const LOGIN_CLEAN_ERROR = "LOGIN_CLEAN_ERROR";

export const LOGINACCOUNT = "LOGINACCOUNT";
export const LOGINLOGINACCOUNT_ERROR = "LOGINLOGINACCOUNT_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_CLEAN = "FORGOT_PASSWORD_CLEAN";
export const FORGOT_PASSWORD_CLEAN_ERROR = "FORGOT_PASSWORD_CLEAN_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_CLEAN = "RESET_PASSWORD_CLEAN";
export const RESET_PASSWORD_CLEAN_ERROR = "RESET_PASSWORD_CLEAN_ERROR";

export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";
export const RESET_PASSWORD_STATUS_ERROR = "RESET_PASSWORD_STATUS_ERROR";
export const RESET_PASSWORD_STATUS_CLEAN = "RESET_PASSWORD_STATUS_CLEAN";
export const RESET_PASSWORD_STATUS_CLEAN_ERROR =
    "RESET_PASSWORD_STATUS_CLEAN_ERROR";

export const TICKETS = "TICKETS";
export const TICKETS_ERROR = "TICKETS_ERROR";

export const VALIDATEPIN = "VALIDATEPIN";
export const VALIDATEPIN_ERROR = "VALIDATEPIN_ERROR";
export const CLEAN_VALIDATEPIN = "CLEAN_VALIDATEPIN";
export const CLEAN_VALIDATEPIN_ERROR = "CLEAN_VALIDATEPIN_ERROR";

export const NEWPASSWORD = "NEWPASSWORD";
export const NEWPASSWORD_ERROR = "NEWPASSWORD_ERROR";
export const CLEAN_NEWPASSWORD = "CLEAN_NEWPASSWORD";
export const CLEAN_NEWPASSWORD_ERROR = "CLEAN_NEWPASSWORD_ERROR";

export const ACCOUNTINFO = "ACCOUNTINFO";
export const ACCOUNTINFO_ERROR = "ACCOUNTINFO_ERROR";

export const ACTIVE_OFFERS = "ACTIVE_OFFERS";
export const ACTIVE_OFFERS_ERROR = "ACTIVE_OFFERS_ERROR";
export const HISTORYSHOPPING = "HISTORYSHOPPING";
export const HISTORYSHOPPING_ERROR = "HISTORYSHOPPING_ERROR";

export const INITREGISTER = "INITREGISTER";
export const INITREGISTER_ERROR = "INITREGISTER_ERROR";

export const INITREGISTER_CLEAN = "INITREGISTER_CLEAN";
export const INITREGISTER_CLEAN_ERROR = "INITREGISTER_CLEAN_ERROR";

export const PRODUCTITEM = "PRODUCTITEM";
export const PRODUCTITEM_ERROR = "PRODUCTITEM_ERROR";
export const SET_LOADING_UUID = "SET_LOADING_UUID";

export const PAYMENT = "PAYMENT";
export const PAYMENT_ERROR = "PAYMENT_ERROR";

export const REENVIOPIN = "REENVIOPIN";
export const REENVIOPIN_ERROR = "REENVIOPIN_ERROR";

export const ADDPURCHASECONTACTINFO = "ADDPURCHASECONTACTINFO";
export const ADDPURCHASECONTACTINFO_ERROR = "ADDPURCHASECONTACTINFO_ERROR";

export const CHECKIMEI = "CHECKIMEI";
export const CHECKIMEI_ERROR = "CHECKIMEI_ERROR";

export const GETOFFERS = "GETOFFERS";
export const GETOFFERS_ERROR = "GETOFFERS_ERROR";

export const OFFERSELECTION = "OFFERSELECTION";
export const OFFERSELECTION_ERROR = "OFFERSELECTION_ERROR";

export const SHIPPINGADDRESS = "SHIPPINGADDRESS";
export const SHIPPINGADDRESS_ERROR = "SHIPPINGADDRESS_ERROR";

export const MUNICIPALITIES = "MUNICIPALITIES";
export const MUNICIPALITIES_ERROR = "MUNICIPALITIES_ERROR";

export const NEIGHBORHOODS = "NEIGHBORHOODS";
export const NEIGHBORHOODS_ERROR = "NEIGHBORHOODS_ERROR";

export const PAYMENTCONFIRM = "PAYMENTCONFIRM";
export const PAYMENTCONFIRM_ERROR = "PAYMENTCONFIRM_ERROR";

export const GET_LEADS_PAYMENTS = "GET_LEADS_PAYMENTS";
export const GET_LEADS_PAYMENTS_ERROR = "GET_LEADS_PAYMENTS_ERROR";

export const GET_LEADS_PAYMENTS_CLEAN = "GET_LEADS_PAYMENTS_CLEAN";
export const GET_LEADS_PAYMENTS_CLEAN_ERROR = "GET_LEADS_PAYMENTS_CLEAN_ERROR";

export const COMPLETEPROCESS = "COMPLETEPROCESS";
export const COMPLETEPROCESS_ERROR = "COMPLETEPROCESS_ERROR";

export const ADDPURCHASECONTACTINFO_CLEAN = "ADDPURCHASECONTACTINFO_CLEAN";
export const ADDPURCHASECONTACTINFO_CLEAN_ERROR =
    "ADDPURCHASECONTACTINFO_CLEAN_ERROR";

export const CHECKIMEI_CLEAN = "CHECKIMEI_CLEAN";
export const CHECKIMEI_CLEAN_ERROR = "CHECKIMEI_CLEAN_ERROR";

export const OFFERSELECTION_CLEAN = "OFFERSELECTION_CLEAN";
export const OFFERSELECTION_CLEAN_ERROR = "OFFERSELECTION_CLEAN_ERROR";

export const SHIPPINGADDRESS_CLEAN = "SHIPPINGADDRESS_CLEAN";
export const SHIPPINGADDRESS_CLEAN_ERROR = "SHIPPINGADDRESS_CLEAN_ERROR";

export const PAYMENT_CLEAN = "PAYMENT_CLEAN";
export const PAYMENT_CLEAN_ERROR = "PAYMENT_CLEAN_ERROR";

export const PAYMENTCONFIRM_CLEAN = "PAYMENTCONFIRM_CLEAN ";
export const PAYMENTCONFIRM_CLEAN_ERROR = "PAYMENTCONFIRM_CLEAN_ERROR";

export const PAYMENT_LEADS_URL = "PAYMENT_LEADS_URL";
export const PAYMENT_LEADS_URL_ERROR = "PAYMENT_LEADS_URL_ERROR";

export const PAYMENT_LEADS_URL_CLEAN = "PAYMENT_LEADS_URL_CLEAN";
export const PAYMENT_LEADS_URL_CLEAN_ERROR = "PAYMENT_LEADS_URL_CLEAN_ERROR";

export const COMPLETEPROCESS_CLEAN = "COMPLETEPROCESS_CLEAN ";
export const COMPLETEPROCESS_CLEAN_ERROR = "COMPLETEPROCESS_CLEAN_ERROR";

export const GETOFFERS_CLEAN = "GETOFFERS_CLEAN ";
export const GETOFFERS_CLEAN_ERROR = "GETOFFERS_CLEAN_ERROR";

export const MUNICIPALITIES_CLEAN = "MUNICIPALITIES_CLEAN ";
export const MUNICIPALITIES_CLEAN_ERROR = "MUNICIPALITIES_CLEAN_ERROR";

export const NEIGHBORHOODS_CLEAN = "NEIGHBORHOODS_CLEAN ";
export const NEIGHBORHOODS_CLEAN_ERROR = "NEIGHBORHOODS_CLEAN_ERROR";

export const LISTRFC = "LISTRFC ";
export const LISTRFC_ERROR = "LISTRFC_ERROR";
export const LISTRFC_CLEAN = "LISTRFC_CLEAN";
export const LISRFC_CLEAN_ERROR = "LISRFC_CLEAN_ERROR";

export const MYLEGALS_ACTION = "MYLEGALS_ACTION";
export const MYLEGALS_ACTION_ERROR = "MYLEGALS_ERROR";
export const CLEAN_MYLEGALS_ACTION = "CLEAN_MYLEGALS_ACTION";
export const CLEAN_MYLEGALS_ACTION_ERROR = "CLEAN_MYLEGALS_ERROR";

export const MYLEGAL_ACTION = "MYLEGAL_ACTION";
export const MYLEGAL_ACTION_ERROR = "MYLEGAL_ACTION_ERROR";
export const CLEAN_MYLEGAL_ACTION = "CLEAN_MYLEGAL_ACTION";
export const CLEAN_MYLEGAL_ACTION_ERROR = "CLEAN_MYLEGAL_ACTION_ERROR";

export const ACCOUNTCARDSTOKENS = "ACCOUNTCARDSTOKENS";
export const ACCOUNTCARDSTOKENS_ERROR = "ACCOUNTCARDSTOKENS_ERROR";
export const ACCOUNTCARDSTOKENS_CLEAN = "ACCOUNTCARDSTOKENS_CLEAN";
export const ACCOUNTCARDSTOKENS_CLEAN_ERROR = "ACCOUNTCARDSTOKENS_CLEAN_ERROR";

export const PORTABILITY_ACTION = "PORTABILITY_ACTION";
export const PORTABILITY_ACTION_ERROR = "PORTABILITY_ERROR";
export const CLEAN_PORTABILITY_ACTION = "CLEAN_PORTABILITY_ACTION";
export const CLEAN_PORTABILITY_ACTION_ERROR = "CLEAN_PORTABILITY_ERROR";

export const MYCARDS_ACTION = "MYCARDS_ACTION";
export const MYCARDS_ACTION_ERROR = "MYCARDS_ERROR";
export const CLEAN_MYCARDS_ACTION = "CLEAN_MYCARDS_ACTION";
export const CLEAN_MYCARDS_ACTION_ERROR = "CLEAN_MYCARDS_ERROR";

export const MYCARD_ACTION = "MYCARD_ACTION";
export const MYCARD_ACTION_ERROR = "MYCARD_ACTION_ERROR";
export const CLEAN_MYCARD_ACTION = "CLEAN_MYCARD_ACTION";
export const CLEAN_MYCARD_ACTION_ERROR = "CLEAN_MYCARD_ACTION_ERROR";

export const PREPAID_SUBSCRIPTION = "PREPAID_SUBSCRIPTION";
export const PREPAID_SUBSCRIPTION_ERROR = "PREPAID_SUBSCRIPTION_ERROR";

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_ERROR = "DELETE_SUBSCRIPTION_ERROR";

export const PURCHASEREQUEST = "PURCHASEREQUEST";
export const PURCHASEREQUEST_ERROR = "PURCHASEREQUEST_ERROR";
export const PURCHASEREQUEST_CLEAN = "PURCHASEREQUEST_CLEAN";
export const PURCHASEREQUEST_ERROR_CLEAN = "PURCHASEREQUEST_ERROR_CLEAN";

export const LEADS_PURCHASEREQUEST = "LEADS_PURCHASEREQUEST";
export const LEADS_PURCHASEREQUEST_ERROR = "LEADS_PURCHASEREQUEST_ERROR";
export const LEADS_PURCHASEREQUEST_CLEAN = "LEADS_PURCHASEREQUEST_CLEAN";
export const LEADS_PURCHASEREQUEST_ERROR_CLEAN =
    "LEADS_PURCHASEREQUEST_ERROR_CLEAN";

export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const GET_PROMOTIONS_ERROR = "GET_PROMOTIONS_ERROR";
export const GET_PROMOTIONS_CLEAN = "GET_PROMOTIONS_CLEAN";
export const GET_PROMOTIONS_ERROR_CLEAN = "GET_PROMOTIONS_ERROR_CLEAN";

export const GET_PREPAID_CONFIRMATION = "GET_PREPAID_CONFIRMATION";
export const GET_PREPAID_CONFIRMATION_ERROR = "GET_PREPAID_CONFIRMATION_ERROR";
export const GET_PREPAID_CONFIRMATION_CLEAN = "GET_PREPAID_CONFIRMATION_CLEAN";
export const GET_PREPAID_CONFIRMATION_CLEAN_ERROR =
    "GET_PREPAID_CONFIRMATION_CLEAN_ERROR";

export const GET_PURCHASE_PAYMENT_STATUS = "GET_PURCHASE_PAYMENT_STATUS";
export const GET_PURCHASE_PAYMENT_STATUS_ERROR =
    "GET_PURCHASE_PAYMENT_STATUS_ERROR";
export const CLEAN_GET_PURCHASE_PAYMENT_STATUS =
    "CLEAN_GET_PURCHASE_PAYMENT_STATUS";
export const CLEAN_GET_PURCHASE_PAYMENT_STATUS_ERROR =
    "CLEAN_GET_PURCHASE_PAYMENT_STATUS_ERROR";

export const GET_LANDING_ESIM = "GET_LANDING_ESIM";
export const GET_LANDING_ESIM_ERROR = "GET_LANDING_ESIM_ERROR";
export const CLEAN_GET_LANDING_ESIM = "CLEAN_GET_LANDING_ESIM";
export const CLEAN_GET_LANDING_ESIM_ERROR = "CLEAN_GET_LANDING_ESIM_ERROR";

export const POST_INVOICE_SIM_PURCHASE = "POST_INVOICE_SIM_PURCHASE";
export const POST_INVOICE_SIM_PURCHASE_ERROR =
    "POST_INVOICE_SIM_PURCHASE_ERROR";
export const CLEAN_POST_INVOICE_SIM_PURCHASE =
    "CLEAN_POST_INVOICE_SIM_PURCHASE";
export const CLEAN_POST_INVOICE_SIM_PURCHASE_ERROR =
    "CLEAN_POST_INVOICE_SIM_PURCHASE_ERROR";

export const GET_BRANDS_DEVICES = "GET_BRANDS_DEVICES";
export const GET_BRANDS_DEVICES_ERROR = "GET_BRANDS_DEVICES_ERROR";
export const CLEAN_GET_BRANDS_DEVICES = "CLEAN_GET_BRANDS_DEVICES";
export const CLEAN_GET_BRANDS_DEVICES_ERROR = "CLEAN_GET_BRANDS_DEVICES_ERROR";

export const GET_DEVICES_MODELS = "GET_DEVICES_MODELS";
export const GET_DEVICES_MODELS_ERROR = "GET_DEVICES_MODELS_ERROR";
export const CLEAN_GET_DEVICES_MODELS = "CLEAN_GET_DEVICES_MODELS";
export const CLEAN_GET_DEVICES_MODELS_ERROR = "CLEAN_GET_DEVICES_MODELS_ERROR";

export const CHECKING_COMPATIBLE_DEVICE = "CHECKING_COMPATIBLE_DEVICE";
export const CHECKING_COMPATIBLE_DEVICE_ERROR =
    "CHECKING_COMPATIBLE_DEVICE_ERROR";
export const CLEAN_CHECKING_COMPATIBLE_DEVICE =
    "CLEAN_CHECKING_COMPATIBLE_DEVICE";
export const CLEAN_CHECKING_COMPATIBLE_DEVICE_ERROR =
    "CLEAN_CHECKING_COMPATIBLE_DEVICE_ERROR";

export const PROFILE_UPDATED_DEVICE = "PROFILE_UPDATED_DEVICE";
export const PROFILE_UPDATED_ERROR = "PROFILE_UPDATED_ERROR";
export const CLEAN_PROFILE_UPDATED = "CLEAN_PROFILE_UPDATED";
export const CLEAN_PROFILE_UPDATED_ERROR = "CLEAN_PROFILE_UPDATED_ERROR";

export const POST_GIFT_FORM = "POST_GIFT_FORM";
export const POST_GIFT_FORM_ERROR = "POST_GIFT_FORM_ERROR";
export const CLEAN_POST_GIFT_FORM = "CLEAN_POST_GIFT_FORM";
export const CLEAN_POST_GIFT_FORM_ERROR = "CLEAN_POST_GIFT_FORM_ERROR";

export const GET_LANDING_GIFT = "GET_LANDING_GIFT";
export const GET_LANDING_GIFT_ERROR = "GET_LANDING_GIFT_ERROR";
export const CLEAN_GET_LANDING_GIFT = "CLEAN_GET_LANDING_GIFT";
export const CLEAN_GET_LANDING_GIFT_ERROR = "CLEAN_GET_LANDING_GIFT_ERROR";

export const GET_STEP_ONE_INVOICE = "GET_STEP_ONE_INVOICE";
export const GET_STEP_ONE_INVOICE_ERROR = "GET_STEP_ONE_INVOICE_ERROR";
export const CLEAN_GET_STEP_ONE_INVOICE = "CLEAN_GET_STEP_ONE_INVOICE";
export const CLEAN_GET_STEP_ONE_INVOICE_ERROR =
    "CLEAN_GET_STEP_ONE_INVOICE_ERROR";

export const CREATE_INVOICE_STEP_2 = "CREATE_INVOICE_STEP_2";
export const CREATE_INVOICE_STEP_2_ERROR = "CREATE_INVOICE_STEP_2_ERROR";
export const CLEAN_CREATE_INVOICE_STEP_2 = "CLEAN_CREATE_INVOICE_STEP_2";
export const CLEAN_CREATE_INVOICE_STEP_2_ERROR =
    "CLEAN_CREATE_INVOICE_STEP_2_ERROR";
export const POST_LEAD_DATA = "POST_LEAD_DATA";
export const POST_LEAD_DATA_ERROR = "POST_LEAD_DATA_ERROR";
export const CLEAN_POST_LEAD_DATA = "CLEAN_POST_LEAD_DATA";
export const CLEAN_POST_LEAD_DATA_ERROR = "CLEAN_POST_LEAD_DATA_ERROR";

export const POST_LEAD_CHECK_IMEI = "POST_LEAD_CHECK_IMEI";
export const POST_LEAD_CHECK_IMEI_ERROR = "POST_LEAD_CHECK_IMEI_ERROR";
export const CLEAN_POST_LEAD_CHECK_IMEI = "CLEAN_POST_LEAD_CHECK_IMEI";
export const CLEAN_POST_LEAD_CHECK_IMEI_ERROR =
    "CLEAN_POST_LEAD_CHECK_IMEI_ERROR";

export const GET_LEAD_OFFERS = "GET_LEAD_OFFERS";
export const GET_LEAD_OFFERS_ERROR = "GET_LEAD_OFFERS_ERROR";
export const CLEAN_GET_LEAD_OFFERS = "CLEAN_GET_LEAD_OFFERS";
export const CLEAN_GET_LEAD_OFFERS_ERROR = "CLEAN_GET_LEAD_OFFERS_ERROR";

export const GET_LEAD_DATA = "GET_LEAD_DATA";
export const GET_LEAD_DATA_ERROR = "GET_LEAD_DATA_ERROR";
export const CLEAN_GET_LEAD_DATA = "CLEAN_GET_LEAD_DATA";
export const CLEAN_GET_LEAD_DATA_ERROR = "CLEAN_GET_LEAD_DATA_ERROR";

export const INVOICE_DOWNLOAD = "INVOICE_DOWNLOAD";
export const INVOICE_DOWNLOAD_ERROR = "INVOICE_DOWNLOAD_ERROR";
export const CLEAN_INVOICE_DOWNLOAD = "CLEAN_CINVOICE_DOWNLOAD";
export const CLEAN_INVOICE_DOWNLOAD_ERROR = "CLEAN_INVOICE_DOWNLOAD_ERROR";

export const GET_STORED_LEGALS = "GET_STORED_LEGALS";
export const GET_STORED_LEGALS_ERROR = "GET_STORED_LEGALS_ERROR";
export const CLEAN_GET_STORED_LEGALS = "CLEAN_GET_STORED_LEGALS";
export const CLEAN_GET_STORED_LEGALS_ERROR = "CLEAN_GET_STORED_LEGALS_ERROR";

export const POST_PREPAID_AUTHN_INVOICE = "POST_PREPAID_AUTHN_INVOICE";
export const POST_PREPAID_AUTHN_INVOICE_ERROR =
    "POST_PREPAID_AUTHN_INVOICE_ERROR";
export const CLEAN_POST_PREPAID_AUTHN_INVOICE =
    "CLEAN_POST_PREPAID_AUTHN_INVOICE";
export const CLEAN_POST_PREPAID_AUTHN_INVOICE_ERROR =
    "CLEAN_POST_PREPAID_AUTHN_INVOICE_ERROR";

export const INVOICE_AUTENTIFICATION = "INVOICE_AUTENTIFICATION";
export const INVOICE_AUTENTIFICATION_ERROR = "INVOICE_AUTENTIFICATION_ERROR";
export const CLEAN_INVOICE_AUTENTIFICATION = "CLEAN_INVOICE_AUTENTIFICATION";
export const CLEAN_INVOICE_AUTENTIFICATION_ERROR =
    "CLEAN_INVOICE_AUTENTIFICATION_ERROR";

export const CHECK_IMEI_GIFT = "CHECK_IMEI_GIFT";
export const CHECK_IMEI_GIFT_ERROR = "CHECK_IMEI_GIFT_ERROR";
export const CLEAN_CHECK_IMEI_GIFT = "CLEAN_CHECK_IMEI_GIFT";
export const CLEAN_CHECK_IMEI_GIFT_ERROR = "CLEAN_CHECK_IMEI_GIFT_ERROR";

export const CHECK_ZIP_CODE_GIFT = "CHECK_ZIP_CODE_GIFT";
export const CHECK_ZIP_CODE_GIFT_ERROR = "CHECK_ZIP_CODE_GIFT_ERROR";
export const CLEAN_CHECK_ZIP_CODE_GIFT = "CLEAN_CHECK_ZIP_CODE_GIFT";
export const CLEAN_CHECK_ZIP_CODE_GIFT_ERROR =
    "CLEAN_CHECK_ZIP_CODE_GIFT_ERROR";

export const VERIFY_PIN_GIFT = "VERIFY_PIN_GIFT";
export const VERIFY_PIN_GIFT_ERROR = "VERIFY_PIN_GIFT_ERROR";
export const CLEAN_VERIFY_PIN_GIFT = "CLEAN_VERIFY_PIN_GIFT";
export const CLEAN_VERIFY_PIN_GIFT_ERROR = "CLEAN_VERIFY_PIN_GIFT_ERROR";
export const NOTIFICATIONS_TYPES = {
    SMS: "sms",
    WHATSAPP: "whatsapp",
};

export const SET_USER_GIFT = "SET_USER_GIFT";
export const SET_USER_GIFT_ERROR = "SET_USER_GIFT_ERROR";
export const CLEAN_SET_USER_GIFT = "CLEAN_SET_USER_GIFT";
export const CLEAN_SET_USER_GIFT_ERROR = "CLEAN_SET_USER_GIFT_ERROR";

export const SEND_EMAIL_COMPLETE_GIFT = "SEND_EMAIL_COMPLETE_GIFT";
export const SEND_EMAIL_COMPLETE_GIFT_ERROR = "SEND_EMAIL_COMPLETE_GIFT_ERROR";
export const CLEAN_SEND_EMAIL_COMPLETE_GIFT = "CLEAN_SEND_EMAIL_COMPLETE_GIFT";
export const CLEAN_SEND_EMAIL_COMPLETE_GIFT_ERROR =
    "CLEAN_SEND_EMAIL_COMPLETE_GIFT_ERROR";

export const PORTABILITY_GIFT = "PORTABILITY_GIFT";
export const PORTABILITY_GIFT_ERROR = "PORTABILITY_GIFT_ERROR";
export const CLEAN_PORTABILITY_GIFT = "CLEAN_PORTABILITY_GIFT";
export const CLEAN_PORTABILITY_GIFT_ERROR = "CLEAN_PORTABILITY_GIFT_ERROR";
