import { Link } from "react-router-dom";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { GiftLayoutPage } from "../components/shared/GiftLayoutPage";
import { iconArrowBack } from "../styles-mui/giftStyleMUI";
import CardEmail from "../components/gift-email/card-email/CardEmail";

export const GiftEmailPage = () => {
    return (
        <Box className="gift-verification-container" width={"100%"}>
            <GiftLayoutPage>
                <Grid
                    size={{ xs: 12, md: 7 }}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Link to="/esim-gift/verification">
                        <ArrowCircleLeftIcon sx={iconArrowBack} />
                    </Link>
                    <Typography
                        variant="p"
                        fontSize={{ xs: 36, md: 60 }}
                        className="font-medium white-color"
                        lineHeight={{ xs: 1.2, md: 1 }}
                    >
                        ¡Genial!
                    </Typography>
                    <Typography
                        variant="p"
                        fontSize={{ xs: 40, md: 64 }}
                        mb={{ xs: "30px", md: 0 }}
                        className="font-semibold purple-color"
                    >
                        Estamos cerca
                    </Typography>
                </Grid>
                <Grid
                    size={{ xs: 12, md: 5 }}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <CardEmail />
                </Grid>
            </GiftLayoutPage>
        </Box>
    );
};
