import React from "react";
import { Grid2 as Grid } from "@mui/material";

import NumberChangedImageComponent from "./number-changed-image-component";
import NumberChangedTextComponent from "./number-changed-text-component";
import NumberChangedButtonComponent from "./number-changed-button-component";

const NumberChangedComponent = () => {
    return (
        <Grid className={"number-changed-component"}>
            <NumberChangedImageComponent />
            <NumberChangedTextComponent />
            <NumberChangedButtonComponent />
        </Grid>
    );
};

export default NumberChangedComponent;
