/******/
/** Import: React and other packages */
import React, { useEffect } from "react";

/******/
/** Import: Material UI Components */
import { Grid2 as Grid, CssBaseline, Container, Button } from "@mui/material";

import { Chart } from "react-google-charts";

/******/
/** Import: App Components */
import ProgressBar from "modules/portal/components/progress-bar";

const CardWidget = (props) => {
    //#region > Destructuring props
    const { item } = props || {};

    const {
        carrier_product_name,
        totals,
        data,
        sms,
        voice,
        leftDaysNumber,
        leftDaysPrefix,
        leftDaysLegend,
        vigencia,
        recargado,
    } = item || {};
    //#endregion > Destructuring props

    /** Style Colors **/
    const colorsList = ["#6D39EF", "#FF28DF", "#00F5FF"];

    /** Custom options variable **/
    const options = {
        legend: "none",
        pieHole: 0.7,
        pieSliceText: "none",
        pieStartAngle: -125,
        tooltip: { trigger: "none" },
        slices: {
            0: { color: colorsList[0 % colorsList.length] },
            1: { color: "#F1F1F1" },
            2: { color: "transparent" },
        },
    };

    /******/
    /** Default constant and variables **/
    //

    /******/
    /** Default methods/functions **/

    /**
     *
     * @param {*} from
     * @description
     * Method that excute a code block,
     * before destroy / unmount the component
     */
    const destroyComponent = () => {};

    /** Custom methods/functions **/
    //

    /******/
    /** Default useCallback **/
    //

    /** Custom useCallback **/
    //

    /******/
    /** Default useEffect **/
    useEffect(() => {
        return () => {
            destroyComponent("useEffect [] => return()");
        };
    }, []);

    /** Custom useEffect **/
    //

    /******/
    /** Component(s) to render **/
    return (
        <Container className="active-plan">
            <CssBaseline />
            <Grid container size={12} className="plan-header">
                <Grid size={6} className="plan-dates">
                    <h2>{carrier_product_name}</h2>
                    <p>
                        Se recargó el <span>{recargado}</span>. Expira el{" "}
                        <span>{vigencia}</span>.
                    </p>
                </Grid>
                <Grid size={6} className="right p-r-30 p-t-10">
                    <p>{leftDaysLegend} </p>

                    {leftDaysPrefix >= 0 || leftDaysNumber <= 3 ? (
                        <Button className="button" href="/paquetes">
                            Quiero más megas
                        </Button>
                    ) : null}
                </Grid>
            </Grid>
            <Grid container size={12} spacing={5} className="bags">
                <Grid size={{ xs: 12, md: 4 }}>
                    <h4>Datos</h4>
                    <Grid container size={12} className="chart-container">
                        <Grid size={12} className="chart">
                            <Chart
                                chartType="PieChart"
                                data={[
                                    ["", "Percentage"],
                                    [
                                        "",
                                        ((totals.data.unused * 100) /
                                            totals.data.total) *
                                            0.7,
                                    ],
                                    [
                                        "",
                                        70 -
                                            ((totals.data.unused * 100) /
                                                totals.data.total) *
                                                0.7,
                                    ],
                                    ["", 30],
                                ]}
                                options={options}
                                width={"100%"}
                                height={"300px"}
                                position={"relative"}
                                margin={"-100px 0 0"}
                            />
                        </Grid>

                        <Grid container size={12} className="data">
                            <Grid size={6}>
                                <p className="colored">
                                    {Math.round(
                                        totals.data.total - totals.data.unused
                                    )}
                                    {"MB"}
                                </p>
                                <p>Consumidos</p>
                            </Grid>
                            <Grid size={6}>
                                <p className="colored">
                                    {totals.data.total} {"MB"}
                                </p>
                                <p>Totales</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className="free">
                        <p>Disponibles</p>
                        <h4>{Math.round(totals.data.unused)} MB</h4>
                    </Grid>

                    <Grid container size={12} className="detail">
                        {data.map((item, idx) => (
                            <Grid key={"card-data-progress-" + idx}>
                                <h5>{item.name}</h5>
                                <ProgressBar
                                    key={"color-" + idx}
                                    bgcolor={
                                        colorsList[idx % colorsList.length]
                                    }
                                    completed={Math.round(
                                        ((item.initialAmt - item.unusedAmt) *
                                            100) /
                                            item.initialAmt
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <h4>SMS</h4>
                    <Grid container size={12} className="chart-container">
                        <Grid size={12} className="chart">
                            <Chart
                                chartType="PieChart"
                                data={[
                                    ["", "Percentage"],
                                    [
                                        "",
                                        ((totals.sms.unused * 100) /
                                            totals.sms.total) *
                                            0.7,
                                    ],
                                    [
                                        "",
                                        70 -
                                            ((totals.sms.unused * 100) /
                                                totals.sms.total) *
                                                0.7,
                                    ],
                                    ["", 30],
                                ]}
                                options={options}
                                width={"100%"}
                                height={"350px"}
                                position={"relative"}
                            />
                        </Grid>

                        <Grid container size={12} className="data">
                            <Grid size={6}>
                                <p className="colored">
                                    {Math.round(
                                        totals.sms.total - totals.sms.unused
                                    )}
                                    {"MB"}
                                </p>
                                <p>Consumidos</p>
                            </Grid>
                            <Grid size={6}>
                                <p className="colored">
                                    {totals.sms.total} {"MB"}
                                </p>
                                <p>Totales</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className="free">
                        <p>Disponibles</p>
                        <h4>{Math.round(totals.sms.unused)} MB</h4>
                    </Grid>

                    <Grid container size={12} className="detail">
                        {sms.map((item, idx) => (
                            <Grid key={"card-sms-progress-" + idx}>
                                <h5>{item.name}</h5>
                                <ProgressBar
                                    key={"color-" + idx}
                                    bgcolor={
                                        colorsList[idx % colorsList.length]
                                    }
                                    completed={Math.round(
                                        ((item.initialAmt - item.unusedAmt) *
                                            100) /
                                            item.initialAmt
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <h4>Minutos</h4>
                    <Grid container size={12} className="chart-container">
                        <Grid size={12} className="chart">
                            <Chart
                                chartType="PieChart"
                                data={[
                                    ["", "Percentage"],
                                    [
                                        "",
                                        ((totals.voice.unused * 100) /
                                            totals.voice.total) *
                                            0.7,
                                    ],
                                    [
                                        "",
                                        70 -
                                            ((totals.voice.unused * 100) /
                                                totals.voice.total) *
                                                0.7,
                                    ],
                                    ["", 30],
                                ]}
                                options={options}
                                width={"100%"}
                                height={"350px"}
                                position={"relative"}
                            />
                        </Grid>

                        <Grid container size={12} className="data">
                            <Grid size={6}>
                                <p className="colored">
                                    {Math.round(
                                        totals.voice.total - totals.voice.unused
                                    )}
                                    {"MB"}
                                </p>
                                <p>Consumidos</p>
                            </Grid>
                            <Grid size={6}>
                                <p className="colored">
                                    {totals.voice.total} {"MB"}
                                </p>
                                <p>Totales</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className="free">
                        <p>Disponibles</p>
                        <h4>{Math.round(totals.voice.unused)} MB</h4>
                    </Grid>

                    <Grid container size={12} className="detail">
                        {voice.map((item, idx) => (
                            <Grid key={"card-voice-progress-" + idx}>
                                <h5>{item.name}</h5>
                                <ProgressBar
                                    key={"color-" + idx}
                                    bgcolor={
                                        colorsList[idx % colorsList.length]
                                    }
                                    completed={Math.round(
                                        ((item.initialAmt - item.unusedAmt) *
                                            100) /
                                            item.initialAmt
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

/** Export component **/
export default CardWidget;
