import React from "react";

//Material UI:
import { Grid2 as Grid } from "@mui/material";

//Components:
import SignUpMainFormComponent from "./components/SignUpMainFormComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

function SignUpMainPageComponent() {
    return (
        <BaseFirstStepsComponent>
            <Grid container>
                <SignUpMainFormComponent />
            </Grid>
        </BaseFirstStepsComponent>
    );
}

export default SignUpMainPageComponent;
