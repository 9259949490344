export const getLoginDataHelper = () => {
    const userToken = localStorage.getItem("user_token");
    const userExpiresAt = localStorage.getItem("user_expires_at");
    const userRefreshToken = localStorage.getItem("user_refresh_token");
    const userLogged = localStorage.getItem("user_logged");
    const userMessage = localStorage.getItem("user_message");
    const userName = localStorage.getItem("user_name");
    const userLastName = localStorage.getItem("user_last_name");
    const userMothersLastName = localStorage.getItem("user_mothers_last_name");
    const userDirectNumber = localStorage.getItem("user_direct_number");
    const userPermissions = localStorage.getItem("user_permissions");

    const params = {
        token: userToken,
        expires_at: userExpiresAt,
        refresh_token: userRefreshToken,
        userMessage,
        userLogged,
        userName,
        userLastName,
        userMothersLastName,
        userDirectNumber,
        userPermissions,
    };

    return params;
};
