import axiosTokenInstance from "services/api/service-client";

import { HISTORYSHOPPING, HISTORYSHOPPING_ERROR } from "_models/redux/types";

export const historyShoppingAction = () => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/prepaids/history`
        );

        const { data } = response;

        dispatch({
            type: HISTORYSHOPPING,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: HISTORYSHOPPING_ERROR,
            payload: e || {},
        });
    }
};
