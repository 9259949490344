import { Box, Grid2 as Grid, Typography } from "@mui/material";
import "assets/styles/css/gift/gift-coverage.css";
import { GiftLayoutPage } from "../components/shared/GiftLayoutPage";
import CardPortability from "../components/gift-portability/CardPortability";
import imgStep1 from "assets/images/gift/step-1.svg";
import imgStep2 from "assets/images/gift/step-2.svg";
import CardVideo from "../components/gift-portability/card-video/CardVideo";

export const GiftPortabilityPage = () => {
    return (
        <Box className="gift-coverage-container">
            <GiftLayoutPage>
                <Grid
                    size={{ xs: 12, md: 7 }}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"start"}
                >
                    <Typography
                        variant="body1"
                        fontSize={{ xs: 35, md: 40 }}
                        mb={{ xs: "15px", md: 0 }}
                        className="font-medium white-color"
                    >
                        Quédate con
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize={{ xs: 45, md: 60 }}
                        className="font-semibold purple-color"
                        sx={{ lineHeight: "1" }}
                    >
                        tu número
                    </Typography>
                    <Grid
                        container
                        size={12}
                        sx={{
                            display: "flex",
                            direction: "row",
                            alignItems: "center",
                            paddingTop: 5,
                        }}
                    >
                        <Grid>
                            <img
                                src={imgStep1}
                                width={50}
                                height={50}
                                className="circular-icon"
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 10, md: 6 }}
                            sx={{
                                paddingLeft: { xs: 3 },
                            }}
                        >
                            <Typography
                                variant="body1"
                                fontSize={{ xs: 15, md: 17 }}
                                color="white"
                                sx={{ fontFamily: "Poppins-Regular" }}
                            >
                                Envía la palabra{" "}
                                <span
                                    style={{ fontFamily: "Poppins-SemiBold" }}
                                >
                                    NIP
                                </span>{" "}
                                al{" "}
                                <span
                                    style={{ fontFamily: "Poppins-SemiBold" }}
                                >
                                    051
                                </span>{" "}
                                desde tu número a portar.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        size={12}
                        sx={{
                            display: "flex",
                            direction: "row",
                            alignItems: "center",
                            paddingTop: 5,
                        }}
                    >
                        <Grid>
                            <img
                                src={imgStep2}
                                width={50}
                                height={50}
                                className="circular-icon"
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 10, md: 6 }}
                            sx={{ paddingLeft: { xs: 3 } }}
                        >
                            <Typography
                                variant="body1"
                                fontSize={{ xs: 15, md: 17 }}
                                color="white"
                                sx={{ fontFamily: "Poppins-Regular" }}
                            >
                                Ingresa el{" "}
                                <span
                                    style={{ fontFamily: "Poppins-SemiBold" }}
                                >
                                    NIP
                                </span>{" "}
                                de{" "}
                                <span
                                    style={{ fontFamily: "Poppins-SemiBold" }}
                                >
                                    4 dígitos
                                </span>{" "}
                                y a continuación confirmalo.
                            </Typography>
                        </Grid>
                    </Grid>
                    <CardVideo />
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <CardPortability />
                </Grid>
            </GiftLayoutPage>
        </Box>
    );
};
