import {
    NEIGHBORHOODS_CLEAN,
    NEIGHBORHOODS_CLEAN_ERROR,
} from "_models/redux/types";

export const cleanNeighborhoodsAction = () => async (dispatch) => {
    try {
        dispatch({
            type: NEIGHBORHOODS_CLEAN,
            payload: [],
        });
    } catch {
        dispatch({
            type: NEIGHBORHOODS_CLEAN_ERROR,
            payload: [],
        });
    }
};
