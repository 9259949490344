import React, { useState, useEffect } from "react";
import { Container, Grid2 as Grid, Typography } from "@mui/material";
import Modal from "./modal";
import { getPromotionsAction } from "../../../../../_models/redux/get-promotions/action";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const PromotionsContent = (props) => {
    const { getPromotions } = props || undefined;
    const { getPromotionsObj } = getPromotions || undefined;
    const { data } = getPromotionsObj || undefined;
    const { promotions } = data || {};
    const [modalOpen, setModalOpen] = useState(false);
    const [content, setContent] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (getPromotionsObj?.status !== "") {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotions]);

    useEffect(() => {
        props.getPromotionsAction();
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {isLoading ? (
                <center>
                    <CircularProgress color="secondary" />
                </center>
            ) : (
                <Container
                    maxWidth={false}
                    sx={{ backgroundColor: "white", px: "0px !important" }}
                >
                    <Grid container className="promotions-content">
                        {promotions && promotions?.length > 0 ? (
                            promotions?.map((item, i) => (
                                <div className="card-container" key={i}>
                                    <div className="card">
                                        <a
                                            onClick={() => {
                                                setContent(item);
                                                setModalOpen(true);
                                            }}
                                        >
                                            <figure className="card__thumbnail">
                                                <img
                                                    src={item?.image_url}
                                                    alt="Promotion"
                                                />
                                            </figure>
                                        </a>
                                        {modalOpen && (
                                            <Modal
                                                key={i}
                                                setOpenModal={setModalOpen}
                                                item={content}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    width: "100%",
                                    mt: 2,
                                }}
                            >
                                Por el momento no hay promociones
                            </Typography>
                        )}
                    </Grid>
                </Container>
            )}
        </div>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    getPromotions: state.getPromotions,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getPromotionsAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(PromotionsContent);
