/******/
/** Import: React and other packages */
import React, { useContext, useState, useEffect } from "react";
/******/
/** Import: Material UI Components */
import { CssBaseline, Container, Grid2 as Grid } from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

import GenericCardComponent from "./GenericCardComponent";

/** Importa: Contexts **/
import { HomeActiveOffersHooksContext } from "../context";

/******/
/** Function Component **/
const CardsWidget = () => {
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /** Custom useContext **/
    const homeActiveOffersHooksContext = useContext(
        HomeActiveOffersHooksContext
    );

    const { loadMethod } = homeActiveOffersHooksContext || {};

    /**
     *
     * @param {*} from
     * @description
     * Method that excute a code block,
     * before destroy / unmount the component
     */
    const destroyComponent = () => {};

    /******/
    /** Default useEffect **/
    useEffect(() => {
        loadMethod();

        return () => {
            destroyComponent("useEffect [] => return()");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /******/
    /** Component(s) to render **/
    return (
        <div className="services-page">
            <CssBaseline />

            <Container className="services-container">
                <TabContext value={value}>
                    <Grid className="services-tabs">
                        <TabList
                            onChange={handleChange}
                            aria-label="Servicios"
                            selectionFollowsFocus
                        >
                            <Tab
                                label="Datos"
                                value="1"
                                className={"services-tab first"}
                            />
                            <Tab
                                label="SMS"
                                value="2"
                                className={"services-tab second"}
                            />
                            <Tab
                                label="Minutos"
                                value="3"
                                className={"services-tab third"}
                            />
                        </TabList>
                    </Grid>
                    <Grid size={12} className="services-content">
                        <TabPanel value="1">
                            <GenericCardComponent type={"data"} />
                        </TabPanel>
                        <TabPanel value="2">
                            <GenericCardComponent type={"sms"} />
                        </TabPanel>
                        <TabPanel value="3">
                            <GenericCardComponent type={"voice"} />
                        </TabPanel>
                    </Grid>
                </TabContext>
            </Container>
            {/* 
            {cards.map((card) => {
                return card;
            })} */}
        </div>
    );
};

/** Export component **/
export default CardsWidget;
