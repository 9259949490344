import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { usePixels } from "./usePixels";

export const useCardEmail = ({
    sendEmailAndCompleteGiftAction,
    sendEmailAndCompleteGift,
    cleanSendEmailAndCompleteGift,
}) => {
    const { payload } = sendEmailAndCompleteGift || {};
    const { response } = payload || {};
    const location = useLocation();
    const { pixelStartTrial } = usePixels();

    const navigate = useNavigate();
    const tokenLead = location.state?.tokenLead || "";
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [modalTitle, setModaltitle] = useState("");

    const [loading, setLoading] = useState(false);
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (payload) {
            setLoading(false);
            if (payload?.status === "success") {
                pixelStartTrial(tokenLead);
                navigate("/esim-gift/welcome");
                cleanSendEmailAndCompleteGift();
            } else if (
                payload?.status === "fail" ||
                payload?.status === "error"
            ) {
                navigate("/esim-gift/not-send");
            } else if (response?.status >= 300) {
                if (
                    response?.data?.context === "lead_closed_error" &&
                    response?.status === 409
                ) {
                    navigate("/esim-gift-error", {
                        state: {
                            title: response?.data?.title,
                            responseMessage: response?.data?.detail,
                        },
                    });
                }
                setModaltitle(response?.data?.title);
                setErrorMessage(response?.data?.detail);
                setOpen(true);
            } else {
                setErrorMessage(payload?.data?.detail);
                setOpen(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload, navigate]);

    const onSubmit = (data) => {
        setLoading(true);
        sendEmailAndCompleteGiftAction({ email: data.email, token: tokenLead });
    };

    const handleClose = () => {
        setOpen(false);
        if (response.status === 401) {
            navigate("/esim-gift");
            cleanSendEmailAndCompleteGift();
        }
    };

    return {
        register,
        getValues,
        handleSubmit,
        errors,
        loading,
        open,
        errorMessage,
        onSubmit,
        handleClose,
        modalTitle,
    };
};
