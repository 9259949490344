/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useReducer } from "react";

import { useLeadReducer } from "./data/reducers/useLeadReducer";

import { useFormInputsHook } from "./hooks/leadFormInputsHook";
import { useFormValues } from "./hooks/leadUseformHook";

import { useLeadMainHook } from "./hooks/leadMainHook";

import { useLocation, useNavigate } from "react-router-dom";

export const useLeadContainer = (props) => {
    const history = useNavigate();

    const {
        getLeadData,
        getLeadDataAction,
        cleanGetLeadDataAction,

        postLeadData,
        postLeadDataAction,
        cleanPostLeadDataAction,

        postLeadCheckImei,
        postLeadCheckImeiAction,
        cleanPostLeadCheckImeiAction,

        neighborhoodsData,
        neighborhoodsAction,
        cleanNeighborhoodsAction,

        municipalitiesData,
        municipalitiesAction,
        cleanMunicipalitiesAction,

        getLeadOffers,
        getLeadOffersAction,
        cleanGetLeadOffersAction,

        staticStatesOptions,
    } = props || {};

    useLeadMainHook(props);

    const { formValues } = useFormValues();
    const { inputs } = useFormInputsHook({
        formValues,
        staticStatesOptions,
        getLeadOffers,
    });

    const initialLeadData = {
        inputs,
        formValues,
        pageLoaded: false,

        municipalitiesOptions: [],
        neighborhoodsOptions: [],

        municipalityOptionSelected: undefined,
        neighborhoodOptionSelected: undefined,

        stateOptionSelected: undefined,

        offersOptionSelected: undefined,
        offersOptions: [],

        compatibility: undefined,

        customModalOpen: false,
        customModalResponse: undefined,
        customModalResponseTitle: undefined,
        customModalResponseContent: undefined,
    };

    const [gralLoaderOpen, setGralLoaderOpen] = useState(true);

    const [formSubmmited, setFormSubmmited] = useState();

    const [leadData, dispatchLeadData] = useReducer(
        useLeadReducer,
        initialLeadData
    );

    const {
        compatibility,
        municipalityOptionSelected,
        stateOptionSelected,
        customModalOpen,
        customModalResponse,
    } = leadData || {};

    const stateOnChangeHandler = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        dispatchLeadData({
            property: "stateOptionSelected",
            value: value,
        });
    };

    const municipalityOnChangeHandler = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        dispatchLeadData({
            property: "municipalityOptionSelected",
            value: value,
        });
    };

    const neighborhoodOnChangeHandler = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        dispatchLeadData({
            property: "neighborhoodOptionSelected",
            value: value,
        });
    };

    const packageOnChangeHandler = (e) => {
        // const { value } = e.target;
        // setPackageSelected(value);

        const { target } = e || {};
        const { value } = target || {};

        dispatchLeadData({
            property: "offersOptionSelected",
            value: value,
        });
    };

    const packageOnFocusHandler = (e) => {};

    const stateOnFocusHandler = (e) => {};

    const municipalityOnFocusHandler = (e) => {};

    const neighborhoodOnFocusHandler = (e) => {};

    useEffect(() => {
        if (municipalityOptionSelected) {
            neighborhoodsAction(municipalityOptionSelected);
        }
    }, [municipalityOptionSelected]);

    useEffect(() => {
        if (stateOptionSelected) {
            municipalitiesAction(stateOptionSelected);
        }
    }, [stateOptionSelected]);

    useEffect(() => {
        const { loading, municipalitiesObj } = municipalitiesData || {};
        const { data, status } = municipalitiesObj || {};
        const { municipalities } = data || {};

        if (loading && status === 200 && municipalities) {
            dispatchLeadData({
                property: "municipalitiesOptions",
                value: municipalities,
            });
        }
    }, [municipalitiesData]);

    useEffect(() => {
        const { loading, neighborhoodsObj } = neighborhoodsData || {};
        const { data, status } = neighborhoodsObj || {};
        const { neighborhoods } = data || {};

        if (loading && status === 200 && neighborhoods) {
            dispatchLeadData({
                property: "neighborhoodsOptions",
                value: neighborhoods,
            });
        }
    }, [neighborhoodsData]);

    useEffect(() => {
        dispatchLeadData({
            property: "pageLoaded",
            value: true,
        });
    }, []);

    const setCustomModalValues = (values) => {
        const { open, response, title, content } = values || {};

        dispatchLeadData({
            property: "customModalOpen",
            value: open,
        });

        dispatchLeadData({
            property: "customModalResponse",
            value: response,
        });

        dispatchLeadData({
            property: "customModalResponseTitle",
            value: title,
        });

        dispatchLeadData({
            property: "customModalResponseContent",
            value: content,
        });

        setGralLoaderOpen(false);
    };

    const setCustomModalClose = () => {
        dispatchLeadData({
            property: "customModalOpen",
            value: false,
        });

        if (customModalResponse === "getLeadData") {
            history("/");
        }
    };

    useEffect(() => {
        if (!customModalOpen) {
            dispatchLeadData({
                property: "customModalResponse",
                value: undefined,
            });

            dispatchLeadData({
                property: "customModalResponseTitle",
                value: undefined,
            });

            dispatchLeadData({
                property: "customModalResponseContent",
                value: undefined,
            });
        }
    }, [customModalOpen]);

    //HOOKS VARIABLES
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const lead = searchParams.get("l");
    const key = searchParams.get("x");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getLeadOffersAction({ lead_id: lead });
    }, []);

    useEffect(() => {
        const { payload } = getLeadOffers || {};
        const { products } = payload || {};
    }, [getLeadOffers]);

    const [token, setToken] = useState();
    const [callActionFlag, setCallActionFlag] = useState();
    const [reCaptchaAction, setReCaptchaAction] = useState();
    const reCaptchaCallback = (token) => {};

    const [onSubmitData, setOnSubmitData] = useState();

    const onSubmit = (e) => {
        setLoading(true);
        setOnSubmitData(e);
    };

    useEffect(() => {
        if (onSubmitData) {
            setReCaptchaAction("recover_leads_generate_link");
        }
    }, [onSubmitData]);

    useEffect(() => {
        if (reCaptchaAction === "recover_leads_generate_link") {
            setCallActionFlag(true);
        }
    }, [reCaptchaAction]);

    useEffect(() => {
        if (
            token &&
            onSubmitData &&
            reCaptchaAction === "recover_leads_generate_link"
        ) {
            const sim_type_id =
                compatibility === "esim"
                    ? 2
                    : compatibility === "sim"
                      ? 1
                      : null;

            const {
                name,
                email,
                phone_number,
                address,
                neighborhood,
                contact_phone_number,
                offer_id,
            } = onSubmitData || {};
            const postData = {
                basic_info: {
                    name: name,
                    contact_phone: phone_number,
                    email: email !== "" ? email : undefined,
                },
                carrier_product_id: parseInt(offer_id),
                sim_type_id: sim_type_id,
                lead_key: key,
                recaptcha_token: token,
            };

            let callAction = undefined;
            if (sim_type_id === 1) {
                postData.shipping_address = {
                    address: address,
                    neighborhood_id: parseInt(neighborhood),
                    contact_phone: contact_phone_number,
                };
                callAction = true;
            } else if (sim_type_id === 2) {
                callAction = true;
            } else if (sim_type_id === null) {
                setCustomModalValues({
                    open: true,
                    response: "checkImeiCompatibility",
                    title: "",
                    content: "Verifica la compatibilidad de tu equipo.",
                });
                setToken();
                setReCaptchaAction();
                setCallActionFlag(false);
                setLoading(false);
                callAction = false;
            }

            if (callAction) {
                postLeadDataAction({
                    lead_id: lead,
                    lead_key: key,
                    recaptcha: token,
                    postData,
                });
            }
        }
    }, [token]);

    useEffect(() => {
        const { loaded, state, status, payload } = postLeadData || {};

        const { payment_link, payment_order_uuid } = payload || {};

        if (loaded && state === "success" && status === 200 && payment_link) {
            window.location.replace(payment_link);
        } else if (loaded) {
            // && state === "error" && status === 400
            setLoading(false);
            setReCaptchaAction();
            setCallActionFlag(false);

            const { type, title, context, detail, additional_details } =
                payload || {};

            setCustomModalValues({
                open: true,
                response: "postLeadData",
                title: title + " (" + status + ").",
                content: detail,
            });
        } else {
            // setLoading(false);
            // setReCaptchaAction();
            // setCallActionFlag(false);
        }

        /*if (postLeadData) {
        }*/
    }, [postLeadData]);

    const reduxValues = {
        getLeadData,
        getLeadDataAction,
        cleanGetLeadDataAction,

        postLeadData,
        postLeadDataAction,
        cleanPostLeadDataAction,

        postLeadCheckImei,
        postLeadCheckImeiAction,
        cleanPostLeadCheckImeiAction,

        neighborhoodsData,
        neighborhoodsAction,
        cleanNeighborhoodsAction,

        municipalitiesData,
        municipalitiesAction,
        cleanMunicipalitiesAction,

        getLeadOffers,
        getLeadOffersAction,
        cleanGetLeadOffersAction,
    };

    const recaptchaContainerProps = {
        token,
        setToken,
        callActionFlag,
        setCallActionFlag,
        reCaptchaAction,
        setReCaptchaAction,
        reCaptchaCallback,
    };

    const leadValues = {
        onSubmit,
        loading,
        setLoading,

        leadData,
        dispatchLeadData,

        municipalityOnChangeHandler,
        neighborhoodOnChangeHandler,

        municipalityOnFocusHandler,
        neighborhoodOnFocusHandler,

        stateOnChangeHandler,
        stateOnFocusHandler,

        staticStatesOptions,

        packageOnChangeHandler,
        packageOnFocusHandler,

        reduxValues,
        formValues,

        recaptchaContainerProps,

        formSubmmited,
        setFormSubmmited,

        gralLoaderOpen,
        setGralLoaderOpen,

        setCustomModalValues,
        setCustomModalClose,
    };

    return {
        leadValues,
    };
};
