import {
    INITREGISTER_CLEAN,
    INITREGISTER_CLEAN_ERROR,
} from "_models/redux/types";

export const cleanInitRegister = () => async (dispatch) => {
    try {
        dispatch({
            type: INITREGISTER_CLEAN,
            payload: [],
        });
    } catch {
        dispatch({
            type: INITREGISTER_CLEAN_ERROR,
            payload: [],
        });
    }
};
