import React from "react";

//Material UI:
import {
    Box,
    CardContent,
    Collapse,
    Grid2 as Grid,
    Typography,
} from "@mui/material";

//Components:
import ProgressBar from "modules/portal/components/progress-bar";

//Styles MUI:
import {
    boxContainerServiceItem,
    gridItemCollapseCards,
    typographyPercentage,
} from "./styles-mui/collapseCards";

/** Style Colors **/
const colorsList = ["#CD9EE7", "#CD9EE7", "#CD9EE7"];

function CollapseCardsComponent({
    expandedArray,
    index,
    serviceStateArray,
    graphLabel,
}) {
    return (
        <Grid size={12}>
            <Collapse in={expandedArray[index]} timeout="auto" unmountOnExit>
                <CardContent sx={{ p: 0, m: 0 }}>
                    <Grid container>
                        {serviceStateArray[index]?.map((item, index) => {
                            return (
                                <Grid
                                    size={12}
                                    sx={gridItemCollapseCards}
                                    key={item?.name || index}
                                >
                                    <Typography sx={typographyPercentage}>
                                        {item?.name}
                                    </Typography>
                                    <ProgressBar
                                        key={"color-" + index}
                                        bgcolor={
                                            colorsList[
                                                index % colorsList.length
                                            ]
                                        }
                                        completed={Math.round(
                                            100 -
                                                ((item?.initialAmt -
                                                    item?.unusedAmt) *
                                                    100) /
                                                    item?.initialAmt
                                        )}
                                    />
                                    <Box sx={boxContainerServiceItem}>
                                        <span>
                                            Te quedan{" "}
                                            {Math.round(
                                                item?.unusedAmt
                                            ).toLocaleString("en")}{" "}
                                        </span>{" "}
                                        {graphLabel} de{" "}
                                        <span>
                                            {Math.round(
                                                item?.initialAmt
                                            ).toLocaleString("en")}
                                        </span>{" "}
                                        {graphLabel}
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </CardContent>
            </Collapse>
        </Grid>
    );
}

export default CollapseCardsComponent;
