import React from "react";
import PortalNavigation from "../../../../modules/portal/components/navigation";

import PortalFooter from "modules/portal/components/footer";
import ProductAcordion from "./widgets/productAccordionItem";
import ProductsView from "./ProductsView";

const ProductsPage = () => {
    return (
        <div>
            <PortalNavigation />
            <ProductsView />
            <ProductAcordion />

            {/* <ProductsContent /> */}
            <PortalFooter />
        </div>
    );
};

export default ProductsPage;
