import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";

import { useLeadContentHook } from "../hooks/leadContentHook";

import LeadImeiComponent from "./LeadImeiComponent";

import Header from "./Header";

import HelperFormInputComponent from "utils/helpers/forms/inputs/helper-form-input-component";

import Prepaid from "assets/images/lead-recovery/recarga.png";

import LeadOffersComponent from "./LeadOffersComponent";

const LeadContentComponent = () => {
    const { customObj } = useLeadContentHook();
    const {
        handleSubmit,
        onSubmit,
        clientInputs,
        control,
        register,
        errors,
        offersOptionSelected,
    } = customObj || {};

    return (
        <Grid container className="lead-content" mt={3}>
            <Container>
                <Header />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        sx={{ mb: 10, mt: 3 }}
                        container
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Grid container>
                            <Grid
                                size={{ xs: 12, md: 6 }}
                                className="lead-recovery-section first-section"
                                sx={{
                                    display: "flex",
                                    alignItems: { xs: "start", md: "center" },
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    className="poppins-semibold-font"
                                    sx={{
                                        fontSize: { xs: 32, md: 55 },
                                        textAlign: { xs: "center", md: "left" },
                                        mb: 3,
                                        lineHeight: { xs: 1.5, md: 1.2 },
                                    }}
                                >
                                    <Typography
                                        variant="span"
                                        sx={{ fontSize: { xs: 28, md: 45 } }}
                                    >
                                        <span className="poppins-font">
                                            Únete a la
                                        </span>
                                    </Typography>
                                    <br></br>
                                    <span className="poppins-font">
                                        telefonía{" "}
                                        <span className="span-word-title">
                                            morada
                                        </span>
                                    </span>
                                </Typography>
                            </Grid>

                            <Grid
                                size={{ xs: 12, md: 6 }}
                                className="lead-recovery-section white-bg"
                            >
                                <Box
                                    sx={{
                                        py: { xs: 2, md: 4 },
                                        px: { xs: 3, md: 6 },
                                    }}
                                >
                                    <Typography sx={{ fontSize: 25, mb: 2 }}>
                                        <span className="poppins-semibold-font">
                                            Paso 1 de 3
                                        </span>
                                    </Typography>

                                    <Typography>
                                        <span className="poppins-regular-font">
                                            Ingresa tus datos de contacto.
                                        </span>
                                    </Typography>

                                    {clientInputs?.map((item, index) => {
                                        return (
                                            <HelperFormInputComponent
                                                register={register}
                                                control={control}
                                                key={index}
                                                id={item.id}
                                                validations={item.validations}
                                                placeholder={item.placeholder}
                                                // cls={item.cls}
                                                onChangeHandler={
                                                    item.onChangeHandler
                                                }
                                                onFocusHandler={
                                                    item.onFocusHandler
                                                }
                                                type={item.type}
                                                error={item.error}
                                                // label={item.label}
                                                options={item.options}
                                                containerCls={item.containerCls}
                                                value={item.value}
                                                errors={errors}
                                                valueProgrammatically={
                                                    item.valueProgrammatically
                                                }
                                            />
                                        );
                                    })}
                                </Box>
                            </Grid>
                        </Grid>

                        <LeadImeiComponent />

                        <Grid container>
                            <Grid
                                size={{ xs: 12, md: 6 }}
                                sx={{
                                    display: { xs: "none", md: "flex" },
                                    alignItems: { xs: "start", md: "center" },
                                }}
                            >
                                <img
                                    src={Prepaid}
                                    className={"compatibility-image"}
                                    alt="Imagen de compatibilidad"
                                />
                            </Grid>

                            {offersOptionSelected ||
                            offersOptionSelected === null ? (
                                <LeadOffersComponent />
                            ) : null}
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Grid>
    );
};

export default LeadContentComponent;
