import { useContext } from "react";
import { GiftContext } from "../data/gift-context";
import { registerList } from "../data/gift-register-options";
import { giftFormInputs } from "../data/gift-form-inputs";

export const useGiftFormInputsHook = () => {
    const giftContext = useContext(GiftContext);
    const { formValues } = giftContext || {};
    const { errors } = formValues || {};

    const { registerOptions } = registerList() || {};

    const onFocusHandler = () => {};
    const onChangeHandler = () => {};

    const { inputs } = giftFormInputs({
        errors,
        registerOptions,
        handlers: {
            onFocusHandler,
            onChangeHandler,
        },
    });

    return { inputs };
};
