/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from "react";

import { useReducerHelper } from "utils/helpers/reducers/useReducerHelper";

import { LeadContext } from "../data/contexts/leadContext";

import { useLocation } from "react-router-dom";

export const useLeadContentHook = () => {
    const initialLeadContentReducer = {
        // variable: undefined,
    };

    const [leadContentReducer, dispatchLeadContentReducer] = useReducer(
        useReducerHelper,
        initialLeadContentReducer
    );

    const leadContentObj = {
        leadContentReducer,
        dispatchLeadContentReducer,
    };

    /*** ***/

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const lead = searchParams.get("l");
    const key = searchParams.get("x");

    const leadContext = useContext(LeadContext);

    /*** ***/
    const {
        onSubmit,
        leadData,
        reduxValues,
        dispatchLeadData,
        formValues,
        recaptchaContainerProps,

        setGralLoaderOpen,

        setCustomModalValues,
    } = leadContext || {};

    const { inputs, offersOptionSelected } = leadData || {};

    const { clientInputs } = inputs || {};

    /*** ***/

    const { getLeadData, getLeadDataAction, getLeadOffers } = reduxValues || {};

    const { token, setToken, setCallActionFlag, reCaptchaAction } =
        recaptchaContainerProps || {};

    const { control, setValue, register, handleSubmit, errors } = formValues;

    useEffect(() => {
        if (token && reCaptchaAction === "recover_leads_details") {
            getLeadDataAction({
                lead_id: lead,
                lead_key: key,
                recaptcha: token,
            });
            setCallActionFlag(false);
            setToken();
        }
    }, [token]);

    useEffect(() => {
        if (getLeadData) {
            const { loaded, state, status, payload } = getLeadData || {};

            if ((loaded && state === "success") || status === 200) {
                const {
                    name,
                    contact_phone,
                    email,
                    sim_type_id,
                    zipcode,
                    address,
                    contact_for_service,
                    carrier_product_id,
                    state_id,
                    municipality_id,
                    neighborhood_id,
                } = payload || {};

                setValue("name", name);
                setValue("phone_number", contact_phone);
                setValue("email", email);

                setValue("zipcode", zipcode);
                setValue("address", address);
                setValue("contact_phone_number", contact_for_service);

                // setValue("offer_id", carrier_product_id);
                dispatchLeadData({
                    property: "offersOptionSelected",
                    value: carrier_product_id,
                });

                // setValue("state", state_id);
                dispatchLeadData({
                    property: "stateOptionSelected",
                    value: state_id,
                });

                // setValue("municipality", municipality_id);
                dispatchLeadData({
                    property: "municipalityOptionSelected",
                    value: municipality_id,
                });

                // setValue("neighborhood", neighborhood_id);
                dispatchLeadData({
                    property: "neighborhoodOptionSelected",
                    value: neighborhood_id,
                });

                if (sim_type_id === 1) {
                    dispatchLeadData({
                        property: "compatibility",
                        value: "sim",
                    });
                } else if (sim_type_id === 2) {
                    dispatchLeadData({
                        property: "compatibility",
                        value: "esim",
                    });
                }

                setGralLoaderOpen(false);
            } else if (status) {
                const { title, detail } = payload || {};

                setCustomModalValues({
                    open: true,
                    response: "getLeadData",
                    title:
                        title && status ? title + " (" + status + ")" : "Error",
                    content: detail || "Inténtelo más tarde",
                });
            } else {
                // setGralLoaderOpen(false);
            }
        } else {
            // setGralLoaderOpen(false);
        }
    }, [getLeadData]);

    useEffect(() => {
        const { payload } = getLeadOffers || {};
        const { products } = payload || {};

        if (products) {
            const offers = products.map((product) => {
                const {
                    carrier_product_id,
                    esim_price,
                    hotspot,
                    is_monthly,
                    mobile_data,
                    mobile_data_roaming,
                    mobile_data_throttling,
                    name,
                    offer,
                    price,
                    product_duration,
                    shipping_price,
                    sim_price,
                    sku,
                    gigabyte_divisor,
                    social_network_data,
                    social_network_data_roaming,
                    social_network_data_throttling,
                    social_gigabyte_divisor,
                } = product || {};
                return {
                    id: carrier_product_id,
                    name: name,
                    sku: sku,
                    is_monthly: is_monthly,
                    hotspot: hotspot,
                    product_duration: product_duration,
                    price: price,
                    offer: offer,
                    mobile_data: mobile_data,
                    mobile_data_roaming: mobile_data_roaming,
                    mobile_data_throttling: mobile_data_throttling,
                    gigabyte_divisor: gigabyte_divisor,
                    social_network_data: social_network_data,
                    social_network_data_roaming: social_network_data_roaming,
                    social_network_data_throttling:
                        social_network_data_throttling,
                    social_gigabyte_divisor: social_gigabyte_divisor,
                    freeSim: sim_price === 0 && esim_price === 0,
                    freeShipping: shipping_price === 0,
                };
            });

            dispatchLeadData({
                property: "offersOptions",
                value: offers,
            });
        }
    }, [getLeadOffers]);

    return {
        leadContentObj,

        customObj: {
            handleSubmit,
            onSubmit,
            clientInputs,
            control,
            register,
            errors,
            offersOptionSelected,
        },
    };
};
