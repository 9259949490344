import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Grid2 as Grid } from "@mui/material";

import EsimActivationHeader from "../widgets/header";
import EsimIntroductionVideo from "../widgets/introduction";
import EsimActiveQR from "../widgets/active-qr";
import EsimInactiveQR from "../widgets/inactive-qr";
import EsimSecondVideo from "../widgets/second-video";
import EsimActivationFooter from "../widgets/footer";
import EsimLoader from "../widgets/loader";

import { getLandingEsimAction } from "_models/redux/get-landing-esim/action";
import { cleanGetLandingEsimAction } from "_models/redux/get-landing-esim/clean";

const QRContent = (props) => {
    const navigate = useNavigate();

    const { getLandingEsim, getLandingEsimAction } = props || {};
    const { loaded, payload } = getLandingEsim || {};

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);

    const uuid = searchParams.get("uuid");
    const order = searchParams.get("order");

    const [activated, setActivated] = useState("none");

    useEffect(() => {
        if (uuid && order) {
            getLandingEsimAction({
                uuid,
                order,
            });
        }
    }, [uuid, order, getLandingEsimAction]);

    useEffect(() => {
        if (loaded) {
            if (payload?.order_number) {
                setActivated(true);
            } else {
                setActivated(false);
                navigate("/pagina-no-encontrada", {
                    state: {
                        payload,
                    },
                });
            }
        }
    }, [loaded, payload, navigate]);

    return (
        <Grid container className="qr-activation-container">
            {activated === "none" ? (
                <EsimLoader />
            ) : activated ? (
                <Grid size={12}>
                    <EsimActivationHeader esimDetails={payload} />
                    <EsimIntroductionVideo />
                    <Grid container className="bg-container">
                        <EsimActiveQR esimDetails={payload} />
                        <EsimSecondVideo />
                    </Grid>
                    <EsimActivationFooter />
                </Grid>
            ) : (
                <Grid size={12}>
                    <EsimActivationHeader esimDetails={payload} />
                    <EsimIntroductionVideo />
                    <Grid container className="bg-container">
                        <EsimInactiveQR esimDetails={payload} />
                        <EsimSecondVideo />
                    </Grid>
                    <EsimActivationFooter />
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({ getLandingEsim: state?.getLandingEsim });

const mapDispatchToProps = {
    getLandingEsimAction,
    cleanGetLandingEsimAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(QRContent);
