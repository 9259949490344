import React from "react";
import { Container, Grid2 as Grid } from "@mui/material";

const EsimSecondVideo = () => {
    return (
        <Container>
            <Grid
                container
                className="qr-second-video"
                columnSpacing={{ xs: 0, md: 10 }}
            >
                <Grid size={{ xs: 12, md: 6 }}>
                    <h1>Disfruta de los mejores beneficios con tu eSIM</h1>
                    <p>
                        Ahora toda la información se descarga y se almacena en
                        tu dispositivo. Protege tus datos y respalda la
                        información de tu línea.
                    </p>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <div className="video-responsive">
                        <iframe
                            src="https://www.youtube.com/embed/s3bdcvolDKw"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EsimSecondVideo;
