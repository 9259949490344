import React, { useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { purple, grey } from "@mui/material/colors";
import {
    inputCardStyle,
    regularCoverageTerms,
} from "modules/gift/components/gift-compatibility/card-zip-code/cardZipCodeStyleMUI";
import { CardGift } from "modules/gift/components/shared/CardGift";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const CardGoodCoverage = ({
    register,
    getValues,
    coverageQuality,
    handleSubmit,
    errors,
    onSubmit,
    loading,
}) => {
    const [isChecked, setIsChecked] = useState(coverageQuality !== "Regular");

    const [showError, setShowError] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if (event.target.checked) {
            setShowError(false);
        }
    };

    const handleFormSubmit = (data) => {
        if (!isChecked) {
            setShowError(true);
        } else {
            onSubmit(data);
        }
    };

    return (
        <CardGift>
            <Typography
                variant="body1"
                fontSize={{ xs: 18, md: 16 }}
                textAlign={{ xs: "center", md: "left" }}
            >
                Recibe tu eSIM y sigue los pasos para activarla.
            </Typography>
            <Typography
                variant="body1"
                mb={1}
                fontSize={13}
                mt={{ xs: "39px", md: "28px" }}
            >
                Nombre completo
            </Typography>
            <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Nombres y apellidos"
                sx={inputCardStyle}
                {...register("name", {
                    required: "El nombre completo es requerido",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                name="name"
            />
            <Typography variant="p" mb={1} fontSize={13} mt={"21px"}>
                Correo electrónico
            </Typography>
            <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Correo electrónico"
                type="email"
                sx={inputCardStyle}
                {...register("email", {
                    required: "El correo es obligatorio",
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Introduce un correo electrónico válido",
                    },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                name="email"
            />

            <Typography variant="p" mb={1} fontSize={13} mt={"21px"}>
                Confirmación correo
            </Typography>
            <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Confirma tu correo electrónico"
                type="email"
                sx={inputCardStyle}
                {...register("confirmEmail", {
                    validate: (value) =>
                        value === getValues("email") ||
                        "Los correos electrónicos no coinciden",
                })}
                error={Boolean(errors.confirmEmail)}
                helperText={errors.confirmEmail?.message}
                name="confirmEmail"
            />

            {coverageQuality === "Regular" && (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                sx={{
                                    color: { xs: grey[100], md: grey[500] },
                                    "&.Mui-checked": {
                                        color: {
                                            xs: grey[300],
                                            md: purple[900],
                                        },
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography
                                variant="p"
                                mb={1}
                                fontSize={12}
                                mt={"21px"}
                                sx={regularCoverageTerms}
                            >
                                Acepto que puedo presentar problemas de
                                velocidad y conectividad en mi localidad.
                            </Typography>
                        }
                    />
                    {showError && (
                        <Typography
                            variant="span"
                            fontSize={11}
                            display={"flex"}
                            alignItems={"center"}
                            mt={"13px"}
                            sx={{ color: "#D22A2A" }}
                        >
                            Debes aceptar los términos para continuar.
                        </Typography>
                    )}
                </>
            )}

            <Box mt={{ xs: "70px", md: "32px" }}>
                <CustomButtonComponent
                    async={true}
                    bgColor={"primary-purple"}
                    text={"Continuar"}
                    height={55}
                    onClick={handleSubmit(handleFormSubmit)}
                    loading={loading}
                />
            </Box>
        </CardGift>
    );
};

export default CardGoodCoverage;
