import React from "react";
import { Grid2 as Grid } from "@mui/material";
import TicketContainer from "./container";

const TicketPage = () => {
    return (
        <Grid container size={12}>
            <TicketContainer />
        </Grid>
    );
};

export default TicketPage;
