import { Grid2 as Grid, CircularProgress } from "@mui/material";

const InvoiceLoaderResponse = () => {
    return (
        <Grid container className="invoice-response">
            <Grid size={12} className={"center_circular_progress"}>
                <CircularProgress />
            </Grid>
        </Grid>
    );
};

export default InvoiceLoaderResponse;
