import React from "react";
import { Grid2 as Grid, InputAdornment, TextField } from "@mui/material";

const FieldComponent = (props = {}) => {
    const {
        fullWidth,
        fieldContext,
        fielEndIcon,
        fieldStartIcon,
        fieldClassName,
        fieldName,
        fieldLabel,
        fieldPlaceholder,
        fieldDisabled,
        fileldDefaultValue,
        fieldRequired,
        fieldError,

        fieldType,
        fieldEndAdornmentCallback,
    } = props;

    //const {} = useField({});

    return (
        <Grid size={12} className={"custom-field"}>
            <TextField
                fullWidth={fullWidth ? true : false}
                type={typeof fieldType === "undefined" ? "text" : fieldType}
                className={"custom-textfield " + fieldClassName || ""}
                label={fieldLabel || ""}
                placeholder={fieldPlaceholder || ""}
                disabled={fieldDisabled}
                defaultValue={fileldDefaultValue || ""}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {fieldStartIcon || ""}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={
                                typeof fieldEndAdornmentCallback !== "undefined"
                                    ? () => fieldEndAdornmentCallback()
                                    : null
                            }
                        >
                            {fielEndIcon || ""}
                        </InputAdornment>
                    ),
                }}
                {...fieldContext.register(fieldName || "", fieldRequired || {})}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        color: "#1f1e24",
                        fontFamily: "Poppins-Regular",
                        fontWeight: "Regular",
                        height: "62px",
                        fontSize: "15px",
                        input: {
                            "&:-webkit-autofill": {
                                "-webkit-box-shadow": "0 0 0 100px #fff inset",
                                "-webkit-text-fill-color": "#1f1e24",
                                "-webkit-font-family": "Poppins-Regular",
                            },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#a8a2b8",
                            borderWidth: "1px",
                        },
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#612cab",
                                borderWidth: "2px",
                            },
                        },
                        "&:hover:not(.Mui-focused)": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#a8a2b8",
                            },
                        },
                    },
                    "& .MuiInputLabel-outlined": {
                        color: "#a8a2b8",
                        fontWeight: 500,
                        "&.Mui-focused": {
                            color: "#612cab",
                            fontWeight: 500,
                        },
                    },
                }}
            />
            {fieldError}
        </Grid>
    );
};

export default FieldComponent;
