import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { GiftContext } from "../data/gift-context";
import { Grid2 as Grid } from "@mui/material";

import { useGiftFormInputsHook } from "./gift-form-inputs-hook";

import { registerList } from "../data/gift-register-options";

export const useGiftFormHook = () => {
    const history = useNavigate();

    const { registerOptions } = registerList() || {};

    const giftContext = useContext(GiftContext);
    const {
        formValues,
        reduxValues,
        reCaptchaValues,
        responseValues,
        formData,
        setFormData,
        setLoading,
    } = giftContext || {};

    const { setCustomError } = formValues || {};

    const { postGiftForm, postGiftFormAction } = reduxValues || {};

    const {
        reCaptchaToken,
        setReCaptchaToken,
        reCaptchaSubmit,
        setReCaptchaSubmit,
    } = reCaptchaValues || {};

    const { setFormResponse, setSectionResponse } = responseValues || {};

    const location = useLocation();
    const search = location.search;
    const searchParams = new URLSearchParams(search);
    const uuid = searchParams.get("uuid");
    const order = searchParams.get("order");

    const onSubmit = (e) => {
        const validateEmail = validateEmailFunction(e);

        if (validateEmail) {
            setReCaptchaSubmit({ status: "clicked", e: e });
            setFormResponse("");
            setCustomError("");
            setLoading(true);
        } else {
            setCustomError(
                <p className="required-incidence">
                    {"Los correos no coinciden"}
                </p>
            );
        }
    };

    const validateEmailFunction = (e) => {
        const { email, confirm_email } = e || {};

        if (
            typeof email === "undefined" ||
            typeof confirm_email === "undefined" ||
            email !== confirm_email
        )
            return false;
        else return true;
    };

    useEffect(() => {
        const { e } = reCaptchaSubmit || {};

        if (reCaptchaToken && e) {
            setFormData(e);
            const postData = e;

            postData["recaptcha_token"] = reCaptchaToken;
            postData["platform"] = "web";
            delete postData.confirm_email;

            postGiftFormAction({
                uuid,
                order,
                postData,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reCaptchaToken]);

    useEffect(() => {
        const { loaded, payload } = postGiftForm || {};
        const { title, context, detail } = payload || {};

        const { message } = payload || {};

        if (loaded) {
            setLoading(false);
            if (context) {
                setFormResponse(
                    <Grid className="gift-response error">
                        <h3>{title}</h3>
                        <h4>{detail}</h4>
                    </Grid>
                );

                setSectionResponse("error");

                setReCaptchaSubmit();
                setReCaptchaToken();
            } else if (message) {
                const { name, email } = formData || {};

                setSectionResponse("success");
                setFormResponse(
                    <Grid className="gift-response success">
                        <h3>{"Se ha enviado su regalo a " + name}</h3>
                        <h3>
                            <nobr>{"(" + email + ")"}</nobr>
                        </h3>
                    </Grid>
                );

                history("/gift/confirmation");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postGiftForm]);

    const { inputs } = useGiftFormInputsHook();

    return { inputs, onSubmit, registerOptions };
};
