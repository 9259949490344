import { Grid2 as Grid } from "@mui/material";
import GiftFormComponent from "./gift-form-component";
import GiftFormRecaptchaComponent from "./gift-form-recaptcha-component";
import giftImage from "assets/images/landings/gift/bun.png";
import Logo from "assets/images/logo.png";

const GiftComponent = () => {
    return (
        <Grid container className={"gift-wrapper"}>
            <Grid
                size={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                className="gift-content"
            >
                <Grid size={{ xs: 12, md: 7 }} className="gift-header">
                    <img src={giftImage} className={"gift-img"} />
                </Grid>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid
                        size={{ xs: 12, md: 12, lg: 6, xl: 6 }}
                        className="gift-text"
                    >
                        <Grid
                            style={{
                                paddingTop: "140px",
                            }}
                        >
                            <img src={Logo} className={"logo"} />
                            <h1 style={{ fontSize: "36px" }}>
                                Envía tu eSIM de regalo.
                            </h1>
                            <p style={{ fontSize: "15px" }}>
                                Solo completa los datos de esa persona especial
                                y ¡listo!
                            </p>
                            <p style={{ fontSize: "15px" }}>
                                Recuérdale que tiene <span>30 días</span> {""}
                                para activar su <span>eSIM</span> {""}una vez
                                que la reciba por email.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                        md={5}
                        className="gift-form"
                        sx={{
                            maxWidth: {
                                xs: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "40%",
                                xl: "40%",
                            },
                            marginLeft: "auto",
                            marginTop: "auto",
                            alignItems: "center",
                            height: "550px",
                        }}
                    >
                        <GiftFormComponent />
                        <GiftFormRecaptchaComponent />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GiftComponent;
