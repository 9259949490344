import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { MyContext } from "./context";
import MyComponent from "./component";
import { useMyHook } from "./hook";

import { Container } from "@mui/material";

/*** INICIO () ***/

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
/*** FIN () ***/

/*** INICIO () ***/

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

/*** FIN() ***/

const MyContainer = (props) => {
    useNavigate();
    const { myProperty } = props;

    const { myMethod } = useMyHook({
        myProperty,
    });

    const sendMyMethod = (data) => {
        myMethod(data);
    };

    /*** INICIO () ***/

    // This styling will be applied to a <NavLink> when the
    // route that it links to is currently selected.
    const activeStyle = {
        textDecoration: "underline",
    };

    const activeClassName = "underline";

    /******/

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const handleClick = () => {
        setOpenMenu(!openMenu);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    /*** FIN() ***/

    const myValues = {
        sendMyMethod,

        /*** INICIO () ***/
        drawerWidth,
        openedMixin,
        closedMixin,
        DrawerHeader,
        AppBar,
        Drawer,
        /*** FIN () ***/

        /*** INICIO () ***/
        activeStyle,
        activeClassName,
        theme,
        open,
        setOpen,
        openMenu,
        setOpenMenu,
        handleClick,
        handleDrawerOpen,
        handleDrawerClose,
        /*** FIN () ***/
    };

    useEffect(() => {
        sendMyMethod({ sample: "new" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container disableGutters maxWidth="xxl">
            <MyContext.Provider value={myValues}>
                <MyComponent />
            </MyContext.Provider>
        </Container>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = () => ({});

/** Redux map dispatch to props **/
const mapDispatchToProps = {};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(MyContainer);
