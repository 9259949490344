import React from "react";

//Material UI:
import { Box, Grid2 as Grid, Typography } from "@mui/material";

//Styles MUI:
import {
    gridContainerInfoProduct,
    gridItemInfoProduct1,
    typographyNameProduct,
    typographyPriceProduct,
    gridItemButtonInvoice,
    gridItemInfoPayment,
    boxContainerInfoProduct,
    gridContainerDetailsProduct,
    typographyTitleDetail,
    typographyValueOfItem,
} from "./styles-mui/cardSuccessStylesMUI";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function CardSuccessComponent({
    product_name,
    total_amount,
    sim_type_id,
    infoProduct,
    history,
}) {
    return (
        <Grid container sx={gridContainerInfoProduct}>
            <Grid size={12} sx={gridItemInfoProduct1}>
                <Box>
                    <Typography variant="h3" sx={typographyNameProduct}>
                        {product_name}
                    </Typography>
                    <Typography variant="h2" sx={typographyPriceProduct}>
                        ${Number(total_amount).toLocaleString()}
                    </Typography>
                </Box>
            </Grid>

            <Grid size={12} sx={gridItemButtonInvoice}>
                <Box sx={{ width: "60%" }}>
                    <CustomButtonComponent
                        text={"Factura tu recarga"}
                        bgColor={"pink"}
                        height={"50px"}
                        onClick={() => history(`/factura`)}
                    />
                </Box>
            </Grid>

            {sim_type_id === 2 ? null : (
                <Grid size={12} sx={gridItemInfoPayment}>
                    <Box sx={boxContainerInfoProduct}>
                        {infoProduct.map((item, index) => (
                            <Grid
                                container
                                sx={gridContainerDetailsProduct}
                                key={index}
                            >
                                <Grid size={7} sx={{ textAlign: "left" }}>
                                    <Typography sx={typographyTitleDetail}>
                                        {item.title}
                                    </Typography>
                                </Grid>
                                <Grid size={5}>
                                    <Typography sx={typographyValueOfItem}>
                                        {item.value}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}

export default CardSuccessComponent;
