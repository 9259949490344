import LoadingButton from "@mui/lab/LoadingButton";
import { Grid2 as Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import miniLogo from "assets/images/mini-logo.png";
import purchaseImage from "assets/images/purchase/purchase.png";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import { municipalitiesAction } from "../../../_models/redux/municipalities/action";
import { neighborhoodsAction } from "../../../_models/redux/neighborhoods/action";
import { shippinghAddressAction } from "../../../_models/redux/shipping-address/action";
import { cleanShippinghAddressAction } from "../../../_models/redux/shipping-address/clean";
import getStaticStates from "../../../modules/purchase/pages/widgets/states";

import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";
// import { clarity } from "react-microsoft-clarity";

const AddressPage = (props) => {
    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_COMPRA_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels ***/
    const tiktokPixelId = process.env?.REACT_APP_COMPRA_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId = process.env?.REACT_APP_COMPRA_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        debug: debugPixels,
        autoConfig: true,
    };

    /*** ***/

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_COMPRA_CLARITY_PIXEL;

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );
        FacebookPixel.pageView();
        /*** ***/

        /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        /*** ***/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();
    const { state } = useLocation();
    const { tokenView, elementSelect, supportsDevices, supportsDevicesModels } =
        state || {};
    const { states } = getStaticStates();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { municipalitiesData, neighborhoodsData, shippingAddress } =
        props || {};
    const { shippingAddressObj } = shippingAddress;
    const { response } = shippingAddressObj || "undefined";
    const { status } = response || "undefined";
    const shippingView = shippingAddressObj.data;
    const { message } = shippingView || "undefined";

    const { neighborhoodsObj } = neighborhoodsData;
    const objNeghborhoods = neighborhoodsObj.data;
    const { neighborhoods } = objNeghborhoods || "undefined";
    const { municipalitiesObj } = municipalitiesData || "undefined";
    const { data } = municipalitiesObj || "undefined";
    const { municipalities } = data || "undefined";

    const [neighborhood_id, createNeighborhood_id] = useState([]);

    const [statesSelects, createStatesSelects] = useState();

    const [municipalitiesValue, createMunicipalitiesValue] = useState();

    const [address, createAddress] = useState("");
    const [contact_phone, createContact_phone] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);

    const [responseErrors, setResponseErrors] = useState();

    const registerOptions = {
        statesSelects: { required: "Debe seleccionar un Estado " },
        municipalitiesValue: { required: "Debe seleccionar un Municipio" },
        neighborhood_id: { required: "Debe seleccionar una Colonia" },
        address: {
            required: "Debe ingresar su calle y número",
            maxLength: 250,
            minLength: 3,
        },
        contact_phone: {
            required: "Número telefónico es requerido",
            maxLength: 12,
            minLength: 10,
            pattern: {
                value: /^(0|[0-9]\d*)(\.\d+)?$/,
            },
        },
    };

    if (message === "success") {
        /*** GOOGLE pixel  ***/
        // Track the "add_shipping_info" event
        ReactGA.event("ecommerce", "add_shipping_info", {
            currency: "MXN",
            value: 0.0, // total_amount
            items: [
                {
                    item_id: "",
                    item_name: "",
                    quantity: 1,
                },
            ],
        });
        /*** ***/

        /*** TIKTOK pixel  ***/
        const ttParams = {};
        const ttTrackingOpt = {
            // event_id: "1234", //OPTIONAL
        };
        TiktokPixel.track("SubmitForm", ttParams, ttTrackingOpt);
        /*** ***/

        /*** FACEBOOK pixel  ***/
        const fbParams = {};
        FacebookPixel.track("SubmitApplication", fbParams);
        /*** ***/

        navigate(`/compras/payment-method`, {
            state: {
                tokenView,
                elementSelect,
                supportsDevices,
                supportsDevicesModels,
            },
        });
    }

    useEffect(() => {
        if (tokenView === undefined) window.location.replace("/compras");

        return () => {
            props.cleanShippinghAddressAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // props.shippinghAddressAction(neighborhood_id,);
        if (typeof statesSelects !== "undefined") {
            props.municipalitiesAction(statesSelects);
        }
        if (typeof municipalitiesValue !== "undefined") {
            props.neighborhoodsAction(municipalitiesValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statesSelects, municipalitiesValue]);

    useEffect(() => {
        if (shippingAddress?.shippingAddressObj?.response?.status === 401) {
            navigate(`/compras`);
        }

        const { loading, shippingAddressObj } = shippingAddress || {};

        if (!loading) {
            const { name, response } = shippingAddressObj || {};

            if (name === "AxiosError" && typeof response !== "undefined") {
                const { data } = response || {};
                const { additional_details } = data || {};
                const { errors } = additional_details || {};

                if (typeof errors !== "undefined") {
                    setResponseErrors(errors);
                }
            }
        }

        if (status >= 400) {
            setBtnLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingAddress]);

    const onSubmit = () => {
        setBtnLoading(true);
        //   e.preventDefault();

        const post = {
            address: address,
            neighborhood_id: neighborhood_id,
            contact_phone: contact_phone,
            tokenView: tokenView,
        };

        props.shippinghAddressAction(post);
    };

    return (
        <Grid container size={12} className={"main-page"}>
            <Grid size={12} className={"main-component-container m-t-40"}>
                <Grid container size={12} className={"main-component"}>
                    <Grid size={12} className={"main-form"}>
                        <Grid
                            size={{ xs: 12, md: 6 }}
                            className={"main-image-container"}
                        >
                            <img src={purchaseImage} className={"main-image"} />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 6 }}
                            className="purchase-form"
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid
                                    size={12}
                                    className={"purchase-title-container flex"}
                                >
                                    <Grid container size={10}>
                                        <h2>Recibe tu SIM Dalefon</h2>
                                    </Grid>

                                    <Grid
                                        size={2}
                                        className={"main-logo-container"}
                                    >
                                        <img
                                            title="Mini Logo"
                                            alt="Mini Logo"
                                            src={miniLogo}
                                            className={"main-logo"}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid size={12}>
                                    <p>
                                        Ingresa la dirección a donde enviaremos
                                        tu nuevo SIM.
                                    </p>

                                    <TextField
                                        select
                                        fullWidth
                                        {...register(
                                            "statesSelects",
                                            registerOptions.statesSelects
                                        )}
                                        label="Estado"
                                        variant="outlined"
                                        className="purchase-textfield-container"
                                        onChange={(event) => {
                                            createStatesSelects(
                                                event.target.value
                                            );
                                        }}
                                        defaultValue=""
                                    >
                                        {states?.map((option, index) => (
                                            <MenuItem
                                                key={index || 0}
                                                value={option.id || 0}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <span>
                                        {errors?.statesSelects && (
                                            <p className="required-incidence">
                                                {errors.statesSelects.message}
                                            </p>
                                        )}
                                    </span>

                                    <TextField
                                        select
                                        fullWidth
                                        {...register(
                                            "municipalitiesValue",
                                            registerOptions.municipalitiesValue
                                        )}
                                        id="outlined-basic"
                                        label="Municipio"
                                        variant="outlined"
                                        className="purchase-textfield-container"
                                        defaultValue=""
                                        onChange={(event) => {
                                            createMunicipalitiesValue(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        {municipalities?.map(
                                            (option, index) => (
                                                <MenuItem
                                                    key={index || 0}
                                                    value={option.id || 0}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                    <span>
                                        {errors?.municipalitiesValue && (
                                            <p className="required-incidence">
                                                {
                                                    errors.municipalitiesValue
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </span>

                                    <TextField
                                        select
                                        fullWidth
                                        defaultValue=""
                                        {...register(
                                            "neighborhood_id",
                                            registerOptions.neighborhood_id
                                        )}
                                        label="Colonia"
                                        variant="outlined"
                                        className="purchase-textfield-container"
                                        // value={neighborhood_id}
                                        onChange={(event) => {
                                            createNeighborhood_id(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        {neighborhoods?.map((option, index) => (
                                            <MenuItem
                                                key={index || 0}
                                                value={option?.id || ""}
                                            >
                                                {option?.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <span>
                                        {errors?.neighborhood_id && (
                                            <p className="required-incidence">
                                                {errors.neighborhood_id.message}
                                            </p>
                                        )}
                                    </span>

                                    <TextField
                                        {...register(
                                            "address",
                                            registerOptions.address
                                        )}
                                        fullWidth
                                        id="outlined-basic"
                                        label="Calle y número int/ext"
                                        variant="outlined"
                                        className="purchase-textfield-container"
                                        value={address}
                                        onChange={(event) => {
                                            createAddress(event.target.value);
                                        }}
                                    />
                                    <span>
                                        {errors?.address && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.address.message}
                                            </p>
                                        )}
                                    </span>
                                    <span>
                                        {errors?.address &&
                                            ["minLength", "maxLength"].indexOf(
                                                errors?.address.type
                                            ) !== -1 && (
                                                <p className="required-incidence">
                                                    Mínimo 3 caracteres, máximo
                                                    350 caracteres
                                                </p>
                                            )}
                                    </span>
                                    <TextField
                                        {...register(
                                            "contact_phone",
                                            registerOptions.contact_phone
                                        )}
                                        fullWidth
                                        label="Número telefónico"
                                        variant="outlined"
                                        className="purchase-textfield-container"
                                        value={contact_phone}
                                        onChange={(event) => {
                                            createContact_phone(
                                                event.target.value
                                            );
                                        }}
                                    />
                                    <span>
                                        {errors?.contact_phone && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.contact_phone.message}
                                            </p>
                                        )}
                                    </span>
                                    <span>
                                        {errors?.contact_phone &&
                                            ["minLength", "maxLength"].indexOf(
                                                errors?.contact_phone.type
                                            ) !== -1 && (
                                                <p className="required-incidence">
                                                    Mínimo 10 dígitos
                                                </p>
                                            )}
                                    </span>
                                    <span>
                                        {errors?.contact_phone &&
                                            errors?.contact_phone.type ===
                                                "pattern" && (
                                                <p className="required-incidence">
                                                    Tiene algún dígito
                                                    incorrecto
                                                </p>
                                            )}
                                    </span>
                                </Grid>

                                <Grid
                                    container
                                    size={12}
                                    className="required-incidence error-response-container"
                                >
                                    <Grid
                                        size={12}
                                        className="required-incidence error-response"
                                    >
                                        {response?.data?.detail}
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    size={12}
                                    className="required-incidence error-response-container"
                                >
                                    {typeof responseErrors !== "undefined"
                                        ? responseErrors.map(
                                              (reElement, reIndex) => {
                                                  return typeof reElement.message !==
                                                      "undefined" ? (
                                                      <Grid
                                                          key={reIndex}
                                                          size={12}
                                                          className="required-incidence error-response"
                                                      >
                                                          {reElement.message}
                                                      </Grid>
                                                  ) : (
                                                      ""
                                                  );
                                              }
                                          )
                                        : null}
                                </Grid>

                                <Grid size={12}>
                                    <LoadingButton
                                        className={"signup-button"}
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        //onClick={() => onSubmit(setBtnLoading(true))}
                                        loading={btnLoading}
                                        loadingPosition="center"
                                    >
                                        Enviar a esta dirección
                                    </LoadingButton>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    municipalitiesData: state.municipalitiesData,
    shippingAddress: state.shippingAddress,
    neighborhoodsData: state.neighborhoodsData,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    shippinghAddressAction,
    municipalitiesAction,
    neighborhoodsAction,
    cleanShippinghAddressAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(AddressPage);
