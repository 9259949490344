import React from "react";
import { Grid2 as Grid } from "@mui/material";

import PortabilityImage from "assets/images/number-changed/portabilidad.png";

const NumberChangedImageComponent = () => {
    return (
        <Grid className={"number-changed-image"}>
            <img src={PortabilityImage} alt="Cambio de número realizado" />
        </Grid>
    );
};

export default NumberChangedImageComponent;
