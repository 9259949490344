import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Material UI:
import { Container, Grid2 as Grid, Box, Typography } from "@mui/material";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//Images:
import successImage from "assets/images/sign-up/success.png";

//Styles MUI:
import {
    gridContainer,
    imageContainer,
    typographyTitle,
    typographyDescription,
    buttonContainer,
} from "../styles-mui/responseStyleMUI";

const SuccessSignUpPage = () => {
    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToLogin = () => {
        history("/login");
    };
    return (
        <BaseFirstStepsComponent>
            <Container maxWidth={"lg"}>
                <Grid container sx={gridContainer}>
                    <Grid size={12}>
                        <Box sx={imageContainer}>
                            <img src={successImage} width={60} />
                        </Box>
                        <Box>
                            <Typography sx={typographyTitle}>
                                ¡Listo!
                            </Typography>
                            <Typography sx={typographyDescription}>
                                Hemos creado tu cuenta, inicia sesión y dale más
                                megas a tu fon.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4, md: 2 }} sx={buttonContainer}>
                        <CustomButtonComponent
                            async={true}
                            onClick={goToLogin}
                            bgColor={"primary-purple"}
                            text={"Iniciar sesión"}
                            width={250}
                            height={55}
                        />
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

export default SuccessSignUpPage;
