// Import: Middleware Action & Custom Types
import {
    CLEAN_MYCARDS_ACTION,
    CLEAN_MYCARDS_ACTION_ERROR,
} from "_models/redux/types";

// Action function
export const cleanMyCardsAction = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_MYCARDS_ACTION,
            payload: undefined,
        });
    } catch {
        dispatch({
            type: CLEAN_MYCARDS_ACTION_ERROR,
            payload: undefined,
        });
    }
};
