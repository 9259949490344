// Import: Middleware Action & Custom Types
import {
    CLEAN_CHECK_IMEI_GIFT,
    CLEAN_CHECK_IMEI_GIFT_ERROR,
} from "_models/redux/types";

// Función de acción
export const cleanCheckImeiGift = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_CHECK_IMEI_GIFT,
            payload: undefined,
        });
    } catch {
        dispatch({
            type: CLEAN_CHECK_IMEI_GIFT_ERROR,
            payload: undefined,
        });
    }
};
