import React from "react";

//CSS:
import "assets/styles/css/portal/pages/payment/components/pending.css";

//Material UI:
import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";

//React Router Dom:
import { useLocation, useNavigate } from "react-router-dom";

//Assets:
import imgPending from "assets/images/portal/pages/payment/components/pending/pago-pendiente.png";

//Components:
import PortalFooter from "modules/portal/components/footer";
import PortalNavigation from "modules/portal/components/navigation";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//Styles MUI:
import {
    boxContainerDesc,
    boxDetail,
    containerPending,
    gridContainerPending,
    gridItemContainerTexts,
    typographyDetail,
    typographyMessage,
    typographyTitle,
} from "./styles-mui/pendingStylesMUI";

export default function PendingComponent() {
    const { state } = useLocation();
    const history = useNavigate();

    const { title, detail } = state || "undefined";

    return (
        <>
            <PortalNavigation />
            <Container
                className="container-pending"
                sx={containerPending}
                maxWidth="false"
            >
                <Grid container sx={gridContainerPending}>
                    <Grid size={12}>
                        <img
                            src={imgPending}
                            className="pending-image"
                            alt="Persona guiñando el ojo"
                        />
                    </Grid>
                    <Grid size={12} sx={gridItemContainerTexts}>
                        <Box>
                            <Typography variant="h1" sx={typographyTitle}>
                                {title || "Pago pendiente"}
                            </Typography>
                            <Typography sx={typographyMessage}>
                                Tu pago se encuentra pendiente debido al
                                siguiente motivo:
                            </Typography>

                            <Box sx={boxContainerDesc}>
                                <Box sx={boxDetail}>
                                    <Typography sx={typographyDetail}>
                                        {detail ||
                                            "Estamos revisando el estado del pago."}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 10, md: 3.5, lg: 3, xl: 2 }}>
                        <CustomButtonComponent
                            text="Consultar nuevamente"
                            bgColor="violet"
                            height="50px"
                            onClick={() =>
                                history(`/completado/paquete/${state.uuid}`)
                            }
                        />
                    </Grid>
                </Grid>
            </Container>
            <PortalFooter />
        </>
    );
}
