import React from "react";
import { Grid2 as Grid } from "@mui/material";
import Logo from "../../../assets/images/logo.png";

const PortalFooter = () => {
    return (
        <Grid container size={12}>
            <Grid>
                <a href="/login">
                    <img src={Logo} className={"invoice-logo"} />
                </a>
            </Grid>
        </Grid>
    );
};

export default PortalFooter;
