import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

export const useCardPortability = ({
    portabilityGiftAction,
    portabilityGift,
    cleanPortabilityGift,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const tokenLead = location.state?.tokenLead || "";
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        const payload = {
            number_to_port: data?.number_to_port,
            portability_pin: data?.portability_pin,
        };

        setLoading(true);
        portabilityGiftAction({
            payload,
            token: tokenLead,
        });
    };

    useEffect(() => {
        if (portabilityGift?.status === 200) {
            navigate("/esim-gift/verification", {
                state: {
                    tokenLead: tokenLead,
                },
            });
            cleanPortabilityGift();
        } else if (portabilityGift?.payload?.response?.status >= 300) {
            if (
                portabilityGift?.payload?.response?.data?.context ===
                    "lead_closed_error" &&
                portabilityGift?.payload?.response?.status === 409
            ) {
                navigate("esim-gift-error", {
                    state: {
                        title: portabilityGift?.payload?.response?.data?.title,
                        responseMessage:
                            portabilityGift?.payload?.response?.data?.detail,
                    },
                });
            }
            setOpenModal(true);
            setModalMessage(portabilityGift?.payload?.response?.data?.detail);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portabilityGift, navigate, tokenLead]);

    const handleDialogClose = () => {
        setOpenModal(false);
        if (portabilityGift?.payload?.response?.status === 401) {
            navigate("/esim-gift");
            cleanPortabilityGift();
        }
    };

    return {
        register,
        handleSubmit,
        getValues,
        errors,
        onSubmit,
        loading,
        openModal,
        modalMessage,
        handleDialogClose,
    };
};
