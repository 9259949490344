import {
    CLEAN_GET_LANDING_GIFT,
    CLEAN_GET_LANDING_GIFT_ERROR,
} from "_models/redux/types";

export const cleanGetLandingGiftAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_GET_LANDING_GIFT,
            payload: [],
        });
    } catch {
        dispatch({
            type: CLEAN_GET_LANDING_GIFT_ERROR,
            payload: [],
        });
    }
};
