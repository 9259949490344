import { useCallback, useEffect } from "react";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Grid2 as Grid } from "@mui/material";

const ReCaptchaComponent = (props) => {
    const { formReCaptchaValues } = props || {};
    const { stateReducer, dispatchReducer } = formReCaptchaValues || {};
    const { reCaptchaSubmit, reCaptchaAction } = stateReducer || {};

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha(reCaptchaAction);

        dispatchReducer({
            property: "reCaptchaToken",
            value: token,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        if (reCaptchaSubmit?.status === "clicked") {
            handleReCaptchaVerify();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reCaptchaSubmit]);

    useEffect(() => {
        return () => {};
    }, []);

    return null;
};

const FormRecaptchaComponent = (props) => {
    const { stateReducer } = props || {};
    const { reCaptchaKey } = stateReducer || {};

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <Grid>
            <div id="recaptchaContainer"></div>
            <GoogleReCaptchaProvider
                reCaptchaKey={reCaptchaKey}
                useEnterprise={true}
                container={{
                    element: "recaptchaContainer",
                    parameters: {
                        badge: "inline",
                        theme: "default",
                    },
                }}
            >
                <ReCaptchaComponent formReCaptchaValues={props} />
            </GoogleReCaptchaProvider>
        </Grid>
    );
};

export default FormRecaptchaComponent;
