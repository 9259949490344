import React from "react";
import { Grid2 as Grid } from "@mui/material";
import inactiveQRImage from "assets/images/landings/esim-activation/inactive-qr.png";

const EsimQR = (props) => {
    const { esimDetails } = props || {};

    return (
        <Grid container className="qr-code-container">
            <Grid size={12} className="qr-code">
                <h1>Actívala ahora</h1>
                <p>La vigencia de tu paquete iniciará al instalar tu eSIM</p>
                <Grid container className="qr-box inactive">
                    <Grid size={{ xs: 12, md: 6 }}>
                        <h3>Tu número Dalefon es</h3>
                        <h1>{esimDetails?.direct_number || "---"}</h1>
                        <h4>Este código QR ya fue utilizado</h4>
                        <p>
                            Para obtener un nuevo código, comunícate con
                            nosotros al 777 362 2340.
                        </p>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }} className="qr-image">
                        <img
                            src={inactiveQRImage}
                            className={"active-qr-image"}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EsimQR;
