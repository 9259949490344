import { POST_LEAD_DATA, POST_LEAD_DATA_ERROR } from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const postLeadDataAction = (params) => async (dispatch) => {
    try {
        const { lead_id, postData } = params || {};

        const response = await axiosAuthInstance.post(
            `/api/recover/leads/${lead_id}/get-payment-order`,
            postData || {}
        );

        const { data, status } = response || {};

        dispatch({
            type: POST_LEAD_DATA,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: POST_LEAD_DATA_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
