import React, { useEffect } from "react";

import { connect } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";

import { Button, Grid2 as Grid } from "@mui/material";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import NoCompatiblePurchaseImage from "assets/images/purchase/no-compatible.svg";

import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";

const NoCompatibleDevicePage = (props) => {
    const navigate = useNavigate();

    const { state } = useLocation();
    const tokenView = typeof state !== "undefined" ? state?.token : undefined;

    const { cleanCheckImeiAction } = props || {};

    const checkImeiMethod = () => {
        navigate(`/compras`, {
            state: { dataToken: tokenView },
        });
    };

    useEffect(() => {
        return () => {
            cleanCheckImeiAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container size={12} className={"main-page"} minHeight="100dvh">
            <Grid
                size={12}
                className="purchase-form no-compatible"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "white",
                }}
            >
                <form>
                    <Grid size={12} className="centered">
                        <h2>Lo sentimos...</h2>
                        <h3>Tu equipo no es compatible con Dalefon</h3>
                        <img
                            src={NoCompatiblePurchaseImage}
                            className={"main-image m-tb-20"}
                        />
                        <p>Te recomendamos probar con un equipo diferente</p>
                    </Grid>

                    <Grid size={12} sx={{ textAlign: "center" }}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={"main-button"}
                            onClick={checkImeiMethod}
                        >
                            Probar un equipo diferente
                        </Button>
                    </Grid>
                </form>
            </Grid>

            <FormFooterComponent />
        </Grid>
    );
};

// export default NoCompatiblePurchasePage;

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    checkImei: state.checkImei,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCheckImeiAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoCompatibleDevicePage);
