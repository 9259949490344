import React, { useEffect } from "react";
import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { historyShoppingAction } from "../../../../../_models/redux/history-shopping/action";
import moment from "moment";
import PortalNavigation from "modules/portal/components/navigation";
import HistoryHeader from "./header";
import PortalFooter from "modules/portal/components/footer";

//CSS:
import "../../../../../assets/styles/css/portal/pages/history/components/history-list.css";

//Styles MUI:
import {
    boxContainerHistoryCard,
    boxMessageInvoiceGenerated,
    gridContainerHistoryDetail,
    gridContainerPurchaseDetails,
    gridItemMeansOfPayment,
    gridItemProductName,
    gridItemValidity,
    spanHistoryDetail,
    typographyTitleProductName,
} from "./styles-mui/historyListStylesMUI";

//React Router:
import { useNavigate } from "react-router-dom";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//React Icons:
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const HistoryListComponent = (props) => {
    const navigate = useNavigate();
    const { historyShopping } = props;
    const { historyShoppingObj } = historyShopping || "";
    const { transactions } = historyShoppingObj || [];

    useEffect(() => {
        props.historyShoppingAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <PortalNavigation />
            <HistoryHeader />
            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: "white",
                    px: "0px !important",
                    pt: 6,
                    pb: 5,
                }}
            >
                <Grid container sx={{ px: 1 }}>
                    {transactions?.map((element, i) => {
                        // Obtener fecha actual
                        const today = moment();
                        const currentMonth = today.month(); // Enero = 0, Febrero = 1, etc.
                        const currentYear = today.year();

                        // Obtener fecha de compra
                        const purchaseDate = moment.unix(element.created);
                        const purchaseMonth = purchaseDate.month();
                        const purchaseYear = purchaseDate.year();

                        // Validar si la compra fue en el mismo mes y año actual
                        const isSameMonth =
                            purchaseMonth === currentMonth &&
                            purchaseYear === currentYear;

                        return (
                            <Grid
                                size={{ xs: 12, md: 12 }}
                                sx={{ mb: 3.5 }}
                                key={i}
                            >
                                <Box
                                    className="history-card"
                                    sx={boxContainerHistoryCard}
                                >
                                    <Grid
                                        container
                                        className={
                                            today.isBefore(
                                                moment.unix(
                                                    element.offer_expires_at
                                                )
                                            )
                                                ? "active-product"
                                                : "expired-product"
                                        }
                                        sx={gridContainerPurchaseDetails}
                                    >
                                        <Grid
                                            size={{ xs: 8, md: 6 }}
                                            className="product-name"
                                            sx={gridItemProductName}
                                        >
                                            <Typography
                                                variant="h2"
                                                sx={typographyTitleProductName}
                                            >
                                                {element.product_name}
                                            </Typography>
                                            <Typography
                                                variant="p"
                                                sx={{ fontSize: "15px" }}
                                            >
                                                Fecha de compra{" "}
                                                {moment
                                                    .unix(element.created)
                                                    .format("DD/MM/YY")}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            size={{ xs: 4, md: 6 }}
                                            sx={gridItemValidity}
                                        >
                                            {today.isBefore(
                                                moment.unix(
                                                    element.offer_expires_at
                                                )
                                            ) ? (
                                                <Typography variant="p">
                                                    Vigente hasta el{" "}
                                                    {moment
                                                        .unix(
                                                            element.offer_expires_at
                                                        )
                                                        .format("L")}
                                                </Typography>
                                            ) : (
                                                <Typography variant="p">
                                                    Expiró el{" "}
                                                    {moment
                                                        .unix(
                                                            element.offer_expires_at
                                                        )
                                                        .format("L")}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        sx={gridContainerHistoryDetail}
                                    >
                                        <Grid
                                            size={{ xs: 12, md: 6 }}
                                            sx={{ my: 1 }}
                                        >
                                            <Typography
                                                variant="p"
                                                sx={{ fontSize: 18 }}
                                            >
                                                Monto:{" "}
                                                <Typography
                                                    variant="span"
                                                    sx={spanHistoryDetail}
                                                >
                                                    $
                                                    {Number(
                                                        element.amount
                                                    ).toLocaleString()}{" "}
                                                    MXN
                                                </Typography>
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            size={{ xs: 12, md: 6 }}
                                            sx={gridItemMeansOfPayment}
                                        >
                                            <Typography variant="p">
                                                Medio de pago:{" "}
                                                <Typography
                                                    variant="span"
                                                    sx={spanHistoryDetail}
                                                >
                                                    {element.application_source}
                                                </Typography>
                                            </Typography>
                                        </Grid>

                                        {/*  mostrar el botón o mensaje de expiración */}
                                        {element.self_invoiced ? (
                                            <Box
                                                sx={boxMessageInvoiceGenerated}
                                            >
                                                <IoMdCheckmarkCircleOutline />
                                                <Typography
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Factura generada
                                                </Typography>
                                            </Box>
                                        ) : isSameMonth ? (
                                            // Mostrar botón si la compra fue en el mismo mes
                                            <Grid
                                                size={{ xs: 12, md: 5 }}
                                                sx={{ my: 1 }}
                                            >
                                                <CustomButtonComponent
                                                    bgColor={"primary-purple"}
                                                    text={"Generar factura"}
                                                    height={"50px"}
                                                    disabled={!isSameMonth}
                                                    onClick={() =>
                                                        navigate(
                                                            `/paquete/facturar`,
                                                            {
                                                                state: element,
                                                            }
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
            <PortalFooter />
        </div>
    );
};

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    historyShopping: state.historyShopping,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    historyShoppingAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryListComponent);
