import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Material UI:
import { Container, Grid2 as Grid, Box, Typography } from "@mui/material";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//Styles MUI:
import {
    gridContainer,
    typographyTitle,
    typographyDescription,
    buttonContainer,
} from "../styles-mui/responseStyleMUI";

const ExpiredResetPasswordPage = () => {
    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToForgotPassword = () => {
        history("/forgot-password");
    };
    return (
        <BaseFirstStepsComponent>
            <Container maxWidth={"lg"}>
                <Grid container sx={gridContainer}>
                    <Grid size={12}>
                        <Box>
                            <Typography sx={typographyTitle}>¡Ups!</Typography>
                            <Typography sx={typographyDescription}>
                                Tu enlace ha caducado, por favor inténtalo
                                nuevamente.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }} sx={buttonContainer}>
                        <CustomButtonComponent
                            async={true}
                            onClick={goToForgotPassword}
                            bgColor={"primary-purple"}
                            text={"Reintentar"}
                            width={250}
                            height={55}
                        />
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

export default ExpiredResetPasswordPage;
