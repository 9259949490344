export const closeLoggedSession = (numberChanged = false) => {
    localStorage.clear();

    if (numberChanged) {
        window.location.href = "/login";
    } else {
        window.location.href = "/login";
    }

    return true;
};
