import React from "react";
import { Grid2 as Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "./new-ticket";
import { useState } from "react";

const TicketHeader = () => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <Grid container className="headers">
            <Grid item xs={6}>
                <h3>Mis incidencias</h3>
            </Grid>
            <Grid item xs={6}>
                <button
                    className="right openModalBtn"
                    onClick={() => {
                        setModalOpen(true);
                    }}
                >
                    <AddIcon className="svg" />
                    Nueva incidencia
                </button>

                {modalOpen && <Modal setOpenModal={setModalOpen} />}
            </Grid>
        </Grid>
    );
};

export default TicketHeader;
