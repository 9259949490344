import { useContext } from "react";
import { MyContext } from "modules/layout/context";
import * as React from "react";
import SubnavigationRoutes from "router/routes/subnavigation";
import CssBaseline from "@mui/material/CssBaseline";

const MyComponent = () => {
    useContext(MyContext);

    return (
        <div>
            <CssBaseline />
            <SubnavigationRoutes />
        </div>
    );
};

export default MyComponent;
