import React from "react";

import CustomRecaptchaComponent from "utils/recaptchaProvider";

import { useForgotPasswordController } from "./hook/useForgotPasswordController";
import { useLocation } from "react-router-dom";

//Material UI:
import { Container, Grid2 as Grid, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//Styles MUI:
import {
    formContainer,
    gridContainer,
    titleContainer,
    typographyTitleOne,
    typographyTitleTwo,
    typographyDescription,
    boxCustomRecaptcha,
    iconArrowBack,
} from "./styles-mui/codeOptionsToVerifyPin";

//Components:
import CodeOptionsView from "./CodeOptionsComponent";
import BaseFirstStepsComponent from "../base-first-steps/BaseFirstStepsComponent";

const CodeOptionsToVerifyPinComponent = ({
    cleanAccountAction,
    loginAccountsAction,
    forgotPasswordAction,
    forgotPassword,
    cleanForgotAction,
    controllerName,
    controllerNameStorage,
    loginAccount,
}) => {
    const location = useLocation();
    const { state } = location || {};
    const { data, contactPhone, gettingType } = state || {};
    const { handleButtonClick, containerProps, isLoading } =
        useForgotPasswordController({
            cleanAccountAction,
            gettingType,
            forgotPasswordAction,
            forgotPassword,
            cleanForgotAction,
            textFieldValue123: contactPhone,
            loginAccountsAction,
            loginAccount,
            controllerName,
            controllerNameStorageNew: controllerNameStorage,
            data,
            contactPhone,
        });

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainer}>
                <Grid container display={"flex"} alignItems={"center"}>
                    <Grid size={{ xs: 12, md: 7 }} sx={titleContainer}>
                        <a href="/forgot-password">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={typographyTitleOne}>
                            Recibe tu código
                        </Typography>
                        <Typography sx={typographyTitleTwo}>
                            de verificación
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 5 }} sx={formContainer}>
                        <form noValidate autoComplete="off">
                            <Typography sx={typographyDescription}>
                                ¿Cómo deseas recibir tu código?
                            </Typography>

                            <Box>
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <CodeOptionsView
                                        handleButtonClick={handleButtonClick}
                                    />
                                )}
                                <span style={{ color: "red" }}>
                                    {forgotPassword?.forgotPassword?.response
                                        ?.data?.detail ||
                                        loginAccount?.loginAccountObj?.response
                                            ?.data?.detail}
                                </span>
                            </Box>

                            <Box sx={boxCustomRecaptcha}>
                                <CustomRecaptchaComponent
                                    containerProps={containerProps}
                                />
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

export default CodeOptionsToVerifyPinComponent;
