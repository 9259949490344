/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// React Hook Form:
import { useForm } from "react-hook-form";

// Deparam:
import deparam from "deparam.js";

// React Router Dom:
import { useNavigate } from "react-router-dom";

//Controllers:
import { getCampaingData } from "utils/helpers/localstorage/campaing";
import { usePixelsBuying } from "./usePixelsBuying";

export default function useCheckCompatibilityImeiHook(props) {
    const navigate = useNavigate();
    const { pixelSearch } = usePixelsBuying({});
    const { checkImei, initRegister, postCompatibleDevices } = props;
    const { initRegisterObj } = initRegister || {};
    const { imeiObj, loading } = checkImei || "undefined";
    const { compatibleDevicesObj } = postCompatibleDevices || {};
    const sourceId = 1;
    const dataToken = initRegisterObj?.data?.token;
    const { data, response } = imeiObj || "undefined";

    const [createLeadLoading, setCreateLeadLoading] = useState(false);
    const [checkboxError, setCheckboxError] = useState("");
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [channelId, setChannelId] = useState();
    const [campaingId, setCampaingId] = useState();
    const [imeiValue, setImeiValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [imeiLoading, setImeiLoading] = useState(false);
    const [isModelsSelected, setIsModelsSelected] = useState(false);
    const [isBrandSelected, setIsBrandSelected] = useState(false);
    const [verifyButtonClicked, setVerifyButtonClicked] = useState(false);
    const [selectedBrandId, setSelectedBrandId] = useState(null);
    const [selectedModelId, setSelectedModelId] = useState("");
    const [deviceIsValid, setDeviceIsValid] = useState(undefined);
    const [imeiIsValid, setImeiIsValid] = useState(undefined);
    const [responseErrors, setResponseErrors] = useState();

    const registerOptions = {
        imeiValue: {
            required: "IMEI es requerido",
            maxLength: 15,
            minLength: 15,
            pattern: {
                value: /[0-9]{15}/,
            },
        },
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            const { location } = window || {};
            const { search } = location || {};

            const obj = deparam(search);

            if (Number.isNaN(parseInt(obj?.cid))) {
                setCampaingId(1);
            } else {
                setCampaingId(obj.cid);
            }

            if (Number.isNaN(parseInt(obj?.chid))) {
                setChannelId(1);
            } else {
                setChannelId(obj.chid);
            }
        }
    }, []);

    useEffect(() => {
        props.listBrandsDevices();
    }, []);

    useEffect(() => {
        return () => {
            props.cleanCheckingDeviceAction();
        };
    }, []);

    useEffect(() => {
        setCreateLeadLoading(true);
        if (channelId !== undefined && campaingId !== undefined) {
            const { referenceLead } = getCampaingData();
            const init = {
                source_id: parseInt(sourceId),
                channel_id: parseInt(channelId),
                campaing_id: parseInt(campaingId),
                landing_id: 3,
                reference: referenceLead,
            };

            props.initRegisterAction(init);
        }
    }, [channelId]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmitByModel = (e) => {
        e.preventDefault();

        setVerifyButtonClicked(true);

        if (selectedBrandId && selectedModelId) {
            if (!isCheckboxChecked) {
                // Establece el mensaje de error
                setCheckboxError(
                    "Marca la casilla antes de enviar el formulario."
                );
                return;
            }

            // Limpia el mensaje de error si el checkbox está marcado
            setCheckboxError("");

            setIsLoading(true);
            const payload = {
                device_id: selectedModelId,
                dataToken: dataToken,
            };
            props.postCheckingCompatibleDevice(payload);
        }
    };

    const onSubmit = (e) => {
        setImeiLoading(true);

        const post = {
            imei: e.imeiValue,
            token: dataToken,
        };
        props.checkImeiAction(post);
    };

    useEffect(() => {
        return () => {
            setImeiValue(undefined);
            props.cleanCheckImeiAction();
        };
    }, []);

    useEffect(() => {
        const { imeiObj, loading } = checkImei || {};
        const { response } = imeiObj || {};

        if (typeof imeiObj !== "undefined") {
            if (typeof imeiObj.data !== "undefined") {
                const { is_valid } = imeiObj.data || {};
                setImeiIsValid(is_valid);
            } else if (typeof response !== "undefined") {
                const { data } = response || {};

                const { additional_details } = data || "undefined";
                const responseErrorsObj =
                    typeof additional_details !== "undefined"
                        ? additional_details.errors
                        : [];

                setResponseErrors(responseErrorsObj);
            }
        }

        if (!loading) {
            setImeiIsValid(undefined);
        }
    }, [checkImei]);

    useEffect(() => {
        const { compatibleDevicesObj, loading } = postCompatibleDevices || {};
        const { data } = compatibleDevicesObj || {};
        const { is_valid } = data || {};
        if (typeof imeiObj !== "undefined") {
            if (typeof is_valid !== "undefined") {
                setDeviceIsValid(is_valid);
            } else if (typeof response !== "undefined") {
                const { data } = response || {};
                const { additional_details } = data || "undefined";
                const responseErrorsObj =
                    typeof additional_details !== "undefined"
                        ? additional_details.errors
                        : [];
                setResponseErrors(responseErrorsObj);
            }
        }
        if (!loading) {
            setDeviceIsValid(undefined);
        }
    }, [postCompatibleDevices]);

    useEffect(() => {
        const supportsDevices = data?.supports_esim;
        const supportsDevicesModels = compatibleDevicesObj?.data?.supports_esim;

        if (loading || postCompatibleDevices?.loading) {
            if (
                typeof imeiIsValid !== "undefined" ||
                typeof deviceIsValid !== "undefined"
            ) {
                if (imeiIsValid || deviceIsValid) {
                    // TODO: Get lead id

                    pixelSearch();
                    navigate(`/compras/products`, {
                        state: {
                            tokenView: dataToken,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                } else if (data?.contact_support) {
                    navigate(`/compras/comprobar-compatibilidad`, {
                        state: { token: dataToken, imeiValue },
                    });
                } else {
                    navigate(`/compras/no-compatible`, {
                        state: { token: dataToken },
                    });
                }
            }
        }
    }, [imeiIsValid, deviceIsValid]);

    useEffect(() => {
        if (compatibleDevicesObj?.response?.status >= 400) {
            setIsLoading(false);
        }
        if (compatibleDevicesObj?.response?.status === 401) {
            navigate(`/purchase`);
        }
    }, [compatibleDevicesObj]);

    const handleBrandChange = (event) => {
        setSelectedBrandId(event.target.value);
        // eslint-disable-next-line no-implicit-coercion
        setIsBrandSelected(!!event.target.value);
    };

    const handleModelChange = (event) => {
        setSelectedModelId(event.target.value);
        // eslint-disable-next-line no-implicit-coercion
        setIsModelsSelected(!!event.target.value);
    };

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };

    useEffect(() => {
        if (selectedBrandId !== null) {
            props.listDevicesModels(selectedBrandId);
        }
    }, [selectedBrandId]);

    useEffect(() => {
        setCreateLeadLoading(true);
        setCreateLeadLoading(false);
    }, [props.initRegister]);

    useEffect(() => {
        if (response?.status >= 400) {
            setImeiLoading(false);
        }
        if (checkImei?.imeiObj?.response?.status === 401) {
            navigate(`/compras`);
        }
    }, [checkImei]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const { campaingId: cid, channelId: chid } = getCampaingData();
        setCampaingId(cid);
        setChannelId(chid);
    }, []);

    return {
        register,
        handleSubmit,
        onSubmit,
        errors,
        response,
        responseErrors,
        isLoading,
        selectedBrandId,
        selectedModelId,
        handleBrandChange,
        handleModelChange,
        isBrandSelected,
        isModelsSelected,
        isCheckboxChecked,
        handleCheckboxChange,
        checkboxError,
        onSubmitByModel,
        createLeadLoading,
        initRegisterObj,
        imeiValue,
        setImeiValue,
        imeiLoading,
        verifyButtonClicked,
        registerOptions,
    };
}
