import React from "react";

//CSS:
import "assets/styles/css/utils/components/flows-products/components/content-no-plans-products.css";

// Material Ui:
import { Box } from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

//NoPlans:
import ContentSlimNoPlans from "utils/components/flows-products/components/ContentSlimNoPlans";
import ContentFitNoPlans from "utils/components/flows-products/components/ContentFitNoPlans";
import ContentLiteNoPlans from "utils/components/flows-products/components/ContentLiteNoPlans";
import ContentClassicNoPlans from "utils/components/flows-products/components/ContentClassicNoPlans";

function ContentNoPlansProductsComponents({
    classicProducts,
    slimProducts,
    fitProducts,
    liteProducts,
    flow,
}) {
    console.log("products:", {
        classicProducts,
        slimProducts,
        fitProducts,
        liteProducts,
        flow,
    });
    console.log("flow:", flow);

    return (
        <Box style={{ backgroundColor: "#ffff" }}>
            {/*** NO Planes - SLIM ***/}
            <ContentSlimNoPlans slimProducts={slimProducts} flow={flow} />
            {/*** NO Planes - FIT ***/}
            <ContentFitNoPlans fitProducts={fitProducts} flow={flow} />
            {/*** NO Planes - LITE ***/}
            <ContentLiteNoPlans liteProducts={liteProducts} flow={flow} />
            {/*** NO Planes - CLÁSICOS ***/}
            <ContentClassicNoPlans
                classicProducts={classicProducts}
                flow={flow}
            />
        </Box>
    );
}

export default ContentNoPlansProductsComponents;
