import { connect } from "react-redux";

import { useLocation } from "react-router-dom";

import { Grid2 as Grid } from "@mui/material";

import { GiftContext } from "./data/gift-context";

import { useGiftHook } from "./hooks/gift-hook";

import { postGiftFormAction } from "_models/redux/post-gift-form/action";
import { cleanPostGiftFormAction } from "_models/redux/post-gift-form/clean";

import GiftComponent from "./components/gift-component";

const GiftPage = (props) => {
    const { giftValues } = useGiftHook(props);

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);

    const uuid = searchParams.get("uuid");
    const order = searchParams.get("order");
    return (
        <GiftContext.Provider value={giftValues}>
            <Grid container className={"gift-wrapper"}>
                {uuid && order ? (
                    <GiftComponent />
                ) : (
                    <>
                        <h1>No se encontró la página</h1>
                    </>
                )}
            </Grid>
        </GiftContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    postGiftForm: state?.postGiftForm,
});

const mapDispatchToProps = {
    postGiftFormAction,
    cleanPostGiftFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftPage);
