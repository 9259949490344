import React from "react";

//Material UI:
import { Grid2 as Grid, Typography } from "@mui/material";

//Redux:
import { connect } from "react-redux";

//Redux actions:
import { checkImeiAction } from "_models/redux/check-imei/action";
import { cleanCheckingDeviceAction } from "_models/redux/select-devices/clean";
import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";
import { listBrandsDevices } from "_models/redux/consult-brand-device/action";
import { listDevicesModels } from "_models/redux/consult-device-models/action";
import { postCheckingCompatibleDevice } from "_models/redux/select-devices/action";

//Styles MUI:
import {
    gridItemContainerSeparator,
    typographySeparator,
} from "./styles-mui/compatibilityPurchasePageStylesMUI";

//Hooks:
import useCompatibilityPurchasePageHooks from "./hooks/useCompatibilityPurchasePageHooks";

//Components:
import CompatibilityPurchaseCardBrandAndModelComponent from "./components/CompatibilityPurchaseCardBrandAndModelComponent";
import CompatibilityPurchaseCardImeiComponent from "./components/CompatibilityPurchaseCardImeiComponent";
import BaseImeiCheckComponent from "utils/components/base-imei-check/BaseImeiCheckComponent";

const CompatibilityPurchasePage = (props) => {
    const {
        handleSubmit,
        onSubmit,
        selectedBrandId,
        verifyButtonClicked,
        isBrandSelected,
        setIsPopoverOpen,
        isPopoverOpen,
        handleBrandChange,
        setSelectedBrandId,
        selectedModelId,
        handleModelChange,
        isModelsSelected,
        isCheckboxChecked,
        handleCheckboxChange,
        checkboxError,
        onSubmitByModel,
        isLoading,
        imeiValue,
        setImeiValue,
        textFieldRef,
        response,
        responseErrors,
        imeiLoading,
        calculatePopoverPosition,
        register,
        errors,
        registerOptions,
    } = useCompatibilityPurchasePageHooks(props);

    return (
        <BaseImeiCheckComponent>
            <CompatibilityPurchaseCardBrandAndModelComponent
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                selectedBrandId={selectedBrandId}
                verifyButtonClicked={verifyButtonClicked}
                props={props}
                selectedModelId={selectedModelId}
                handleBrandChange={handleBrandChange}
                handleModelChange={handleModelChange}
                isBrandSelected={isBrandSelected}
                isModelsSelected={isModelsSelected}
                isCheckboxChecked={isCheckboxChecked}
                handleCheckboxChange={handleCheckboxChange}
                checkboxError={checkboxError}
                onSubmitByModel={onSubmitByModel}
                isLoading={isLoading}
                setIsPopoverOpen={setIsPopoverOpen}
                setSelectedBrandId={setSelectedBrandId}
                response={response}
                responseErrors={responseErrors}
            />

            <Grid size={{ xs: 12, md: 1 }} sx={gridItemContainerSeparator}>
                <Typography sx={typographySeparator}>O</Typography>
            </Grid>

            <CompatibilityPurchaseCardImeiComponent
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                registerOptions={registerOptions}
                imeiValue={imeiValue}
                setImeiValue={setImeiValue}
                textFieldRef={textFieldRef}
                isPopoverOpen={isPopoverOpen}
                errors={errors}
                response={response}
                responseErrors={responseErrors}
                imeiLoading={imeiLoading}
                setIsPopoverOpen={setIsPopoverOpen}
                calculatePopoverPosition={calculatePopoverPosition}
            />
        </BaseImeiCheckComponent>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    checkImei: state.checkImei,
    getBrandsDevices: state.getBrandsDevices,
    getDevicesModels: state.getDevicesModels,
    postCompatibleDevices: state.postCompatibleDevices,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    checkImeiAction,
    cleanCheckImeiAction,
    listBrandsDevices,
    listDevicesModels,
    postCheckingCompatibleDevice,
    cleanCheckingDeviceAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompatibilityPurchasePage);
