import { useCallback, useContext, useEffect, useState } from "react";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Grid2 as Grid } from "@mui/material";
import { GiftContext } from "../data/gift-context";

const ReCaptchaComponent = () => {
    const giftContext = useContext(GiftContext);

    const { reCaptchaValues } = giftContext || {};
    const { setReCaptchaToken, reCaptchaSubmit } = reCaptchaValues || {};

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha("register_sim_gift_order");

        setReCaptchaToken(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        if (reCaptchaSubmit?.status === "clicked") {
            handleReCaptchaVerify();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reCaptchaSubmit]);

    useEffect(() => {
        return () => {};
    }, []);

    // return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;

    return null;
};

const GiftFormRecaptchaComponent = () => {
    const giftContext = useContext(GiftContext);

    const { loaderResponse } = giftContext || {};

    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "";

    useEffect(() => {
        return () => {};
    }, []);

    const [cls, setCls] = useState("");

    useEffect(() => {
        if (loaderResponse) {
            setCls("elementHidden");
        } else {
            setCls("");
        }
    }, [loaderResponse]);

    return (
        <Grid className={cls}>
            <div id="recaptchaContainer"></div>
            <GoogleReCaptchaProvider
                reCaptchaKey={reCaptchaKey}
                useEnterprise={true}
                container={{
                    element: "recaptchaContainer",
                    parameters: {
                        badge: "inline",
                        theme: "default",
                    },
                }}
            >
                <ReCaptchaComponent />
            </GoogleReCaptchaProvider>
        </Grid>
    );
};

export default GiftFormRecaptchaComponent;
