import React from "react";

import { Grid2 as Grid, CircularProgress } from "@mui/material";

const GiftLoader = () => {
    return (
        <Grid size={12} className="gift-activation-loader">
            <CircularProgress className="white" />
        </Grid>
    );
};

export default GiftLoader;
