import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePixels } from "./usePixels";

export const useCardZipCode = ({
    checkZipCodeGiftAction,
    checkZipCodeGift,
    cleanCheckZipCodeGift,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pixelSearch } = usePixels();
    const tokenLead = location.state?.tokenLead || "";
    const { payload } = checkZipCodeGift || {};
    const [zipCode, setZipCode] = useState("");
    const [error, setError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [coverageQuality, setCoverageQuality] = useState("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setZipCode(e.target.value);
        if (e.target.value) {
            setError(false);
        }
    };

    const handleButtonClick = () => {
        if (!zipCode) {
            setError(true);
            return;
        }
        setLoading(true);
        setModalMessage("");
        checkZipCodeGiftAction({ zipcode: zipCode, token: tokenLead });
        pixelSearch(tokenLead);
    };

    useEffect(() => {
        const getCoverageQuality = (coverage) => {
            if (coverage < 25) return "Mala";
            if (coverage < 50) return "Regular";
            if (coverage < 80) return "Buena";
            return "Excelente";
        };

        if (checkZipCodeGift?.status === 200) {
            const coverageQuality = getCoverageQuality(payload.coverage);
            setCoverageQuality(coverageQuality);

            navigate("/esim-gift/coverage", {
                state: {
                    coveragePercentage: payload.coverage || 0,
                    coverageQuality: coverageQuality,
                    tokenLead: tokenLead,
                    municipality: payload.municipality || "",
                    state: payload.state || "",
                    zipcode: payload.zipcode || "",
                },
            });
            cleanCheckZipCodeGift();
        } else if (checkZipCodeGift?.payload?.response?.status >= 300) {
            if (
                checkZipCodeGift?.payload?.response?.data?.context ===
                    "lead_closed_error" &&
                checkZipCodeGift?.payload?.response?.status === 401
            ) {
                navigate("/esim-gift-error", {
                    state: {
                        title: checkZipCodeGift?.payload?.response?.data?.title,
                        responseMessage:
                            checkZipCodeGift?.payload?.response?.data?.detail,
                    },
                });
            }
            setOpenModal(true);
            setModalMessage(
                checkZipCodeGift?.payload?.response?.data?.detail ||
                    "Error desconocido"
            );
            resetValuesNavigate();
        }

        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        checkZipCodeGift,
        payload,
        tokenLead,
        setCoverageQuality,
        setModalMessage,
        setLoading,
        navigate,
    ]);

    const handleCloseModal = () => {
        setOpenModal(false);
        if (checkZipCodeGift?.payload?.response?.status === 401) {
            navigate("/esim-gift");
        }
    };

    const resetValuesNavigate = () => {
        navigate("/esim-gift/coverage", {
            state: {
                coveragePercentage: 0,
                coverageQuality: 0,
                tokenLead: tokenLead,
                municipality: "",
                state: "",
                zipcode: "",
            },
        });
        setCoverageQuality("");
    };

    const handleSkipGoodCoverage = () => {
        navigate("/esim-gift/coverage", {
            state: {
                coveragePercentage: payload?.coverage || 0,
                coverageQuality: coverageQuality,
                tokenLead: tokenLead,
                municipality: payload?.municipality || "",
                state: payload?.state || "",
                zipcode: payload?.zipcode || "",
                skipGoodCoverage: true,
            },
        });
    };

    return {
        handleInputChange,
        handleButtonClick,
        zipCode,
        error,
        tokenLead,
        openModal,
        setOpenModal,
        modalMessage,
        handleCloseModal,
        coverageQuality,
        loading,
        handleSkipGoodCoverage,
    };
};
