import React from "react";
import { Container, Grid2 as Grid } from "@mui/material";

const EsimIntroductionVideo = () => {
    return (
        <Container>
            <Grid container className="qr-introduction" columnSpacing={10}>
                <Grid size={{ xs: 12, md: 6 }}>
                    <div className="video-responsive">
                        <iframe
                            src="https://www.youtube.com/embed/giNpfrfSOWQ"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} xs={12} md={6}>
                    <h1>¿Cómo activo mi eSIM?</h1>
                    <p>
                        Sólo debes escanear tu código QR para obtener tu eSIM.
                    </p>
                    <span className="label">
                        Da un salto hacia el futuro de la telefonía.
                    </span>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EsimIntroductionVideo;
