/******/
/** Import: React and other packages */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

/******/
/** Import: Material UI Components */

/******/
/** Import: App Components */
import ActiveOffersHooks from "./hooks";

/******/
/** Importa: Contexts **/
import { HomeActiveOffersContext } from "./context";

/******/
/** Importa: Actions **/
import { activeOffersAction } from "_models/redux/active-offers/action";

/******/
/** Importa: Hooks **/
//

/******/
/** Importa: Utils and Helpers **/
//

/******/
/** Global Variables and Constants **/
//

/******/
/** Function Component **/
const ActiveOffersContainer = (props) => {
    //#region > Destructuring props
    const { activeOffers, activeOffersAction } = props || {};
    //#endregion > Destructuring props

    /******/
    /** Default useContext **/
    //

    /** Custom useContext **/
    //

    /******/
    /** Default useState **/
    //

    /** Custom useState **/
    const [cards, setCards] = useState([]);

    /******/
    /** Default constant and variables **/
    //

    /** Custom constant and variables **/
    const homeActiveOffersValue = {
        activeOffers,
        activeOffersAction,

        cards,
        setCards,
    };

    /******/
    /**  Default hooks **/
    //

    /** Custom hooks **/
    //

    /******/
    /** Default methods/functions **/

    /**
     *
     * @param {*} from
     * @description
     * Method that excute a code block,
     * before destroy / unmount the component
     */
    const destroyComponent = () => {};

    /** Custom methods/functions **/
    //

    /******/
    /** Default useCallback **/
    //

    /** Custom useCallback **/
    //

    /******/
    /** Default useEffect **/
    useEffect(() => {
        return () => {
            destroyComponent("useEffect [] => return()");
        };
    }, []);

    /** Custom useEffect **/
    //

    /******/
    /** Component(s) to render **/
    return (
        <HomeActiveOffersContext.Provider value={homeActiveOffersValue}>
            <ActiveOffersHooks />
        </HomeActiveOffersContext.Provider>
    );
};

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    activeOffers: state.activeOffers,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    activeOffersAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActiveOffersContainer);

// export default ActiveOffersContainer;
