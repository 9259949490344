import React from "react";
import { Box, Typography } from "@mui/material";

const PriceSection = () => (
    <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        className="price-container"
    >
        <Typography
            variant="span"
            sx={{ fontSize: "25px", fontFamily: "Poppins-SemiBold" }}
        >
            $159
        </Typography>
        <Box bgcolor={"#FC2BDC"} height={3} width={70} mt={-3}></Box>
        <Typography
            mt={"10px"}
            variant="span"
            sx={{
                fontSize: "43px",
                fontFamily: "Poppins-Bold",
            }}
        >
            $0
        </Typography>
    </Box>
);

export default PriceSection;
