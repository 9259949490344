import React from "react";

//CSS:
import "assets/styles/css/utils/components/section-carousel-product/section-carousel-product.css";

// Material Ui:
import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Swiper:
import { Swiper } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//Styles MUI:
import {
    gridContainerButton,
    typographyTitle,
    typographyTermsAndConditions,
    boxContainerTitle,
} from "./styles-mui/sectionCarouselProductStylesMUI";

function SectionCarouselProductComponent({
    dataSection,
    title,
    img,
    alt,
    showButton = true,
    children,
}) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const styleSection = dataSection ? dataSection.style : "Prepaids";

    return (
        <Container
            maxWidth={false}
            sx={{ px: 0, pt: 4, pb: 5 }}
            className="container-section-carousel-product"
            disableGutters
        >
            {title ? (
                <Box sx={boxContainerTitle}>
                    {img ? (
                        <img src={img} alt={alt} className="img-title" />
                    ) : null}

                    <Typography sx={typographyTitle}>{title}</Typography>
                </Box>
            ) : null}

            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                initialSlide={1}
                spaceBetween={matches ? 120 : 30}
                coverflowEffect={{
                    rotate: -10,
                    stretch: 0,
                    depth: 80,
                    modifier: 1,
                    slideShadows: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {children}
            </Swiper>

            <Grid container sx={gridContainerButton}>
                {showButton ? (
                    <Grid size={10} md={3} xl={2}>
                        <CustomButtonComponent
                            text={"Ver más ofertas"}
                            bgColor={
                                styleSection === "Prepaids"
                                    ? "secondary-pink"
                                    : "primary-purple"
                            }
                            height={"50px"}
                            link={
                                styleSection === "Prepaids"
                                    ? "/recargas"
                                    : "/paquetes"
                            }
                        />
                    </Grid>
                ) : null}

                <Grid size={12} className="terms-and-conditions" sx={{ mt: 4 }}>
                    <Typography sx={typographyTermsAndConditions}>
                        *Consulta los
                        <a
                            href="https://cdn.dalefon.mx/legales/terminos_y_condiciones_dalefon.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            Términos y condiciones
                        </a>
                        , aplica
                        <a
                            href="https://cdn.dalefon.mx/legales/politica_uso_justo.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            política de uso justo
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default SectionCarouselProductComponent;
