import React from "react";
//Redux:
import { GiftLayoutPage } from "../components/shared/GiftLayoutPage";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import CardVerification from "../components/gift-verification/card-verification/CardVerification";
const GiftVerificationPage = () => {
    return (
        <Box className="gift-verification-container" width={"100%"}>
            <GiftLayoutPage>
                <Grid
                    size={{ xs: 12, md: 7 }}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        variant="p"
                        fontSize={{ xs: 40, md: 65 }}
                        className="font-medium white-color"
                        lineHeight={{ xs: 1.2, md: 1 }}
                    >
                        Código de
                    </Typography>
                    <Typography
                        variant="p"
                        fontSize={{ xs: 42, md: 78 }}
                        mb={{ xs: "30px", md: 0 }}
                        className="font-semibold purple-color"
                    >
                        Verificación
                    </Typography>
                </Grid>
                <Grid
                    size={{ xs: 12, md: 5 }}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <CardVerification />
                </Grid>
            </GiftLayoutPage>
        </Box>
    );
};

export default GiftVerificationPage;
