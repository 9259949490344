/******/
/** Import: React and other packages */
import React, { useContext, useEffect } from "react";

/******/
/** Import: Material UI Components */

/******/
/** Import: App Components */
import ActiveOffersPresentational from "./presentational";

/******/
/** Importa: Contexts **/
import {
    HomeActiveOffersContext,
    HomeActiveOffersHooksContext,
} from "./context";

/******/
/** Importa: Actions **/
//

/******/
/** Importa: Hooks **/
import { useLoadHook } from "./hooks/load";

/******/
/** Importa: Utils and Helpers **/
//

/******/
/** Global Variables and Constants **/
//

/******/
/** Function Component **/
const ActiveOffersHooks = () => {
    //#region > Destructuring props
    //const {} = props || {};
    //#endregion > Destructuring props

    /******/
    /** Default useContext **/
    //

    /** Custom useContext **/
    const homeActiveOffersContext = useContext(HomeActiveOffersContext);

    const { activeOffers, activeOffersAction, cards, setCards } =
        homeActiveOffersContext || {};

    /******/
    /** Default useState **/
    //

    /** Custom useState **/
    //
    /******/
    /**  Default hooks **/
    //

    /** Custom hooks **/
    const { loadMethod } = useLoadHook({
        activeOffers,
        activeOffersAction,

        cards,
        setCards,
    });

    /******/
    /** Default constant and variables **/
    //

    /** Custom constant and variables **/
    const homeActiveOffersHooksContextValue = { loadMethod };

    /******/
    /** Default methods/functions **/

    /**
     *
     * @param {*} from
     * @description
     * Method that excute a code block,
     * before destroy / unmount the component
     */
    const destroyComponent = () => {};

    /** Custom methods/functions **/
    //

    /******/
    /** Default useCallback **/
    //

    /** Custom useCallback **/
    //

    /******/
    /** Default useEffect **/
    useEffect(() => {
        return () => {
            destroyComponent("useEffect [] => return()");
        };
    }, []);

    /** Custom useEffect **/
    //

    /******/
    /** Component(s) to render **/
    return (
        <HomeActiveOffersHooksContext.Provider
            value={homeActiveOffersHooksContextValue}
        >
            <ActiveOffersPresentational />
        </HomeActiveOffersHooksContext.Provider>
    );
};

/** Export component **/
export default ActiveOffersHooks;
