import { Box, Grid2 as Grid, Typography } from "@mui/material";
import "assets/styles/css/gift/gift-coverage.css";
import { GiftLayoutPage } from "../components/shared/GiftLayoutPage";
import CardCoverageResponse from "../components/gift-coverage/cards/CardCoverageResponse";
import { useLocation } from "react-router-dom";
import { ChartCoverage } from "../components/gift-coverage/chart-coverage/ChartCoverage";

export const GiftCoveragePage = () => {
    const location = useLocation();
    const coveragePercentage = location.state?.coveragePercentage || 0;
    const coverageQuality = location.state?.coverageQuality || "";
    const municipality = location.state?.municipality || "";
    const state = location.state?.state || "";
    const zipcode = location.state?.zipcode || "";
    const skipGoodCoverage = location.state?.skipGoodCoverage ?? false;
    console.log("coveragePercentage:", location.state);
    return (
        <Box className="gift-coverage-container">
            <GiftLayoutPage>
                <Grid
                    size={{ xs: 12, md: 7 }}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <ChartCoverage
                        coveragePercentage={Math.round(coveragePercentage)}
                    />
                    <Typography
                        variant="body1"
                        fontSize={{ xs: 39, md: 66 }}
                        className="font-semibold purple-color"
                        sx={{ lineHeight: "1" }}
                    >
                        {coverageQuality}
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize={{ xs: 29, md: 40 }}
                        mb={{ xs: "31px", md: 0 }}
                        className="font-medium white-color"
                    >
                        Cobertura
                    </Typography>
                    {coverageQuality && (
                        <Box
                            mt={{ xs: 0, md: 2 }}
                            mb={{ xs: 5, md: 0 }}
                            textAlign="left"
                            sx={{
                                borderRadius: "20px",
                                background:
                                    "linear-gradient(342deg, rgba(164,46,123,0.25) 0%, rgba(57,60,142,0.26) 100%)",
                                padding: "20px",
                            }}
                        >
                            <Typography
                                variant="body2"
                                fontSize={15}
                                className="white-color"
                                sx={{ lineHeight: { xs: 1.9, md: 1.7 } }}
                            >
                                <b className="font-medium">Municipio:</b>{" "}
                                {municipality}
                            </Typography>
                            <Typography
                                variant="body2"
                                fontSize={{ xs: 15, md: 16 }}
                                className="white-color"
                                sx={{ lineHeight: { xs: 1.9, md: 1.7 } }}
                            >
                                <b className="font-medium">Estado:</b> {state}
                            </Typography>
                            <Typography
                                variant="body2"
                                fontSize={{ xs: 15, md: 16 }}
                                className="white-color"
                                sx={{ lineHeight: { xs: 1.9, md: 1.7 } }}
                            >
                                <b className="font-medium">Código postal:</b>{" "}
                                {zipcode}
                            </Typography>
                        </Box>
                    )}
                </Grid>
                <Grid
                    size={{ xs: 12, md: 5 }}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <CardCoverageResponse
                        isGoodCoverage={skipGoodCoverage}
                        coverageQuality={coverageQuality}
                    />
                </Grid>
            </GiftLayoutPage>
        </Box>
    );
};
