import React from "react";
import { GiftLayoutPage } from "../components/shared/GiftLayoutPage";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { useNavigate } from "react-router-dom";

export const GiftNotSendPage = () => {
    const navigate = useNavigate();
    return (
        <Box className="gift-verification-container" width={"100%"}>
            <GiftLayoutPage>
                <Grid
                    size={12}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <Typography
                        variant="p"
                        fontSize={{ xs: 40, md: 48 }}
                        className="font-bold white-color"
                    >
                        {":("}
                    </Typography>

                    <Typography
                        variant="p"
                        fontSize={{ xs: 36, md: 50 }}
                        my={{ xs: "30px", md: "40px" }}
                        className="font-bold white-color"
                        textAlign={"center"}
                    >
                        No se envió tu regalo
                    </Typography>
                    <Box
                        width={{ xs: "72%", md: "480px" }}
                        textAlign={"center"}
                    >
                        <Typography
                            variant="p"
                            fontSize={{ xs: 16, md: 18 }}
                            mb={{ xs: "30px", md: 0 }}
                            className="white-color"
                        >
                            Tu regalo no pudo ser enviado, pero no te preocupes,
                            vuelve a intentarlo.
                        </Typography>
                    </Box>

                    <Box
                        mt={{ xs: "70px", md: "80px" }}
                        width={{ xs: "100%", md: "323px" }}
                    >
                        <CustomButtonComponent
                            async={true}
                            bgColor={"primary-purple"}
                            text={"Reintentar"}
                            height={45}
                            onClick={() => navigate("/esim-gift")}
                        />
                    </Box>
                </Grid>
            </GiftLayoutPage>
        </Box>
    );
};
