import {
    CLEAN_POST_INVOICE_SIM_PURCHASE,
    CLEAN_POST_INVOICE_SIM_PURCHASE_ERROR,
} from "_models/redux/types";

export const cleanPostInvoiceSimPurchaseAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_POST_INVOICE_SIM_PURCHASE,
            payload: [],
        });
    } catch {
        dispatch({
            type: CLEAN_POST_INVOICE_SIM_PURCHASE_ERROR,
            payload: [],
        });
    }
};
