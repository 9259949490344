import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getPrepaidConfirmationAction } from "_models/redux/prepaid-confirmation/action";
import { cleanPrepaidConfirmationAction } from "_models/redux/prepaid-confirmation/clean";

const WbcPrepaidPage = (props) => {
    const history = useNavigate();
    const params = useParams(props);
    const { uuid } = params || {};

    const {
        getPrepaidConfirmation,
        getPrepaidConfirmationAction,
        cleanPrepaidConfirmationAction,
    } = props || {};

    const { prepaidConfirmationObj } = getPrepaidConfirmation || {};
    const { status, response } = prepaidConfirmationObj || {};
    const { additional_details } = prepaidConfirmationObj || {};
    const { payment_status } = additional_details || {};

    useEffect(() => {
        getPrepaidConfirmationAction({ uuid });
        return () => {
            cleanPrepaidConfirmationAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { status, data } = response || {};
        if (status >= 300) {
            history(`/paquete/error`, { state: data });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    useEffect(() => {
        prepaidConfirmationObj.uuid = uuid;
        if (typeof status !== "undefined") {
            if (status) {
                history(`/paquete/success`, {
                    state: prepaidConfirmationObj,
                });
            } else {
                payment_status === "REQUESTED" || payment_status === "PENDING"
                    ? history(`/paquete/pending`, {
                          state: prepaidConfirmationObj,
                      })
                    : history(`/paquete/error`, {
                          state: prepaidConfirmationObj,
                      });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, payment_status]);

    return null;
};

const mapStateToProps = (state) => ({
    getPrepaidConfirmation: state?.getPrepaidConfirmation,
});

const mapDispatchToProps = {
    getPrepaidConfirmationAction,
    cleanPrepaidConfirmationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WbcPrepaidPage);
