import { useContext } from "react";
import { Grid2 as Grid, Box } from "@mui/material";
import { GiftContext } from "../data/gift-context";
import { useGiftFormHook } from "../hooks/gift-form-hook";
import HelperFormInputComponent from "utils/helpers/forms/inputs/helper-form-input-component";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const GiftFormComponent = (props) => {
    const giftContext = useContext(GiftContext);
    const { formValues, loading, responseValues } = giftContext || {};
    const { register, handleSubmit, customError } = formValues || {};
    const { formResponse } = responseValues || {};

    const { inputs, onSubmit } = useGiftFormHook(props);

    return (
        <Grid container className={"gift-form"}>
            <Grid
                size={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                style={{ paddingLeft: "30px", marginTop: "25px" }} // Ajusta este valor según sea necesario
                className="section gift"
            >
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid size={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                            {inputs?.map((item, index) => {
                                return (
                                    <>
                                        <HelperFormInputComponent
                                            register={register}
                                            key={index}
                                            id={item.id}
                                            validations={item.validations}
                                            placeholder={item.placeholder}
                                            // cls={item.cls}
                                            onChangeHandler={
                                                item.onChangeHandler
                                            }
                                            onFocusHandler={item.onFocusHandler}
                                            type={item.type}
                                            error={item.error}
                                            label={item.label}
                                            options={item.options}
                                            containerCls={item.containerCls}
                                            value={item.value}
                                            typeComponent={"input"}
                                        />
                                        {item.id === "confirm_email"
                                            ? customError
                                            : null}
                                    </>
                                );
                            })}
                        </Grid>
                        <Grid size={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                            {formResponse}
                        </Grid>
                        <Box mb={3}>
                            <CustomButtonComponent
                                async={true}
                                loading={loading}
                                bgColor={"primary-purple"}
                                text={"Enviar eSIM de regalo"}
                                height={55}
                            />
                        </Box>
                    </form>
                </>
                {/* )} */}
            </Grid>
        </Grid>
    );
};

export default GiftFormComponent;
