import { Button, Grid2 as Grid } from "@mui/material";
import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";
import eSimCompatibleImage from "assets/images/template/esim-compatible.png";
import purchaseImage from "assets/images/purchase/purchase.png";
import SimCompatibleImage from "assets/images/template/sim-compatible.png";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";
// import { clarity } from "react-microsoft-clarity";

const CompatiblePurchasePage = (props) => {
    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId =
        process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

    /*** ***/

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );
        FacebookPixel.pageView();

        /*** ***/

        /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        /*** ***/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { cleanCheckImeiAction } = props || {};

    const navigate = useNavigate();
    const { state } = useLocation();
    const { supportsDevicesModels } = state || {};

    const tokenView = state?.token;
    const supportsDevices = state?.supportsDevices;
    const ProductsHandle = () => {
        /*** GOOGLE pixel  ***/
        // Track the "begin_checkout" event
        ReactGA.event("ecommerce", "begin_checkout", {
            currency: "MXN",
            value: 0.0,
            items: [
                {
                    item_id: supportsDevices ? 2 : 1,
                    item_name: supportsDevices ? "eSIM" : "SIM",
                    quantity: 1,
                },
            ],
        });
        /*** ***/

        /*** TIKTOK pixel  ***/
        const ttParams = {};
        const ttTrackingOpt = {};
        TiktokPixel.track("InitiateCheckout", ttParams, ttTrackingOpt);

        /*** ***/

        /*** FACEBOOK pixel  ***/
        const fbParams = {};
        FacebookPixel.track("InitiateCheckout", fbParams);

        /*** ***/

        navigate(`/purchase/products`, {
            state: { tokenView, supportsDevices, supportsDevicesModels },
        });
    };

    useEffect(() => {
        if (tokenView === undefined) window.location.replace("/purchase");
        return () => {
            cleanCheckImeiAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container className={"main-page"}>
            <Grid size={12} className={"main-component-container"}>
                {/* FIX: Mel item > item, tal vez main-component no va */}
                <Grid container className={"main-component"}>
                    <Grid size={12} className={"main-form"}>
                        <Grid
                            size={{ xs: 12, md: 6 }}
                            className={"main-image-container"}
                        >
                            <img
                                src={purchaseImage}
                                className={"main-image"}
                                alt="Tipo de SIM"
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 6 }}
                            className="purchase-form"
                        >
                            <form>
                                <Grid size={12} className="centered">
                                    <div>
                                        <h1>¡Felicidades!</h1>
                                    </div>
                                    {supportsDevices ||
                                    supportsDevicesModels === true ? (
                                        <div
                                            className={
                                                "esim-compatible-container"
                                            }
                                        >
                                            <h3>
                                                Tu equipo es compatible con eSIM
                                                Dalefon
                                            </h3>
                                            <img
                                                src={eSimCompatibleImage}
                                                className={"main-image"}
                                                alt="Es compatible con eSIM"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                "sim-compatible-container"
                                            }
                                        >
                                            <h3>
                                                Tu equipo es compatible con
                                                Dalefon
                                            </h3>
                                            <img
                                                src={SimCompatibleImage}
                                                className={"main-image"}
                                                alt="Es compatible con SIM"
                                            />
                                        </div>
                                    )}

                                    <p>
                                        ¿Estás listo para disfrutar de una nueva
                                        conexión?
                                    </p>
                                </Grid>

                                <Grid size={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={"main-button"}
                                        onClick={ProductsHandle}
                                    >
                                        Dale
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

// export default CompatiblePurchasePage;

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    checkImei: state.checkImei,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCheckImeiAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompatiblePurchasePage);
