import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid2 as Grid, Button } from "@mui/material";

const NumberChangedButtonComponent = () => {
    const history = useNavigate();

    const goToLogin = () => {
        history("/login");
    };

    return (
        <Container className={"menu-buttons-container"}>
            <Grid container className={"number-changed-button"}>
                <Grid size={{ xs: 9, sm: 6, md: 3 }}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={"menu-buttons pink"}
                        onClick={goToLogin}
                    >
                        Iniciar sesión
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NumberChangedButtonComponent;
