import { React, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid2 as Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const NewPin = () => {
    const [loading] = useState(false);

    // useEffect(() => {
    //     if (validationPinEpired !== 200 && pinCodeAccount !== undefined) {
    //         navigate("/forgot-password/media-selection-pin", {
    //             state: {
    //                 textFieldValue: phone,
    //                 controllerNameStorage: controllerNameStorage,
    //             },
    //         });
    //     }
    // }, [pinCodeAccount, validationPinEpired, navigate, phone]);

    const onSubmit = () => {};

    return (
        <Container>
            <Grid container justifyContent="flex-end">
                <LoadingButton
                    color="primary"
                    onClick={onSubmit}
                    loading={loading}
                    loadingPosition="end"
                >
                    Reenviar
                </LoadingButton>
            </Grid>
            <Grid container justifyContent="flex-start">
                <Grid size={12}></Grid>
                <Grid size={12}></Grid>
            </Grid>
        </Container>
    );
};

// export default Modal;
const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewPin);
